import { all, fork } from "redux-saga/effects";

// Public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import SetPasswordSaga from "./auth/setnewpwd/saga"; // Import the Set Password Saga
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

import bybitApiDataSaga from "./exchange/saga";
import bitgetApiDataSaga from "./exchangeBitget/saga";
import binanceApiDataSaga from "./exchangeBinance/saga";
import blofinApiDataSaga from "./exchangeBloFin/saga";

import botSaga from "./bots/saga"; // Import the combined bot saga
import botSpecificSaga from "./bot/saga";
import dashSaga from "./dashboard/saga";
import notificationsSaga from "./notifications/saga";
import nftsSaga from "./nfts/saga";

import paymentSaga from "./payments/saga";

import BotsAdvanceSettingsSaga from "./botsAdvanceSettings/saga";

export default function* rootSaga() {
  yield all([
    // Public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(SetPasswordSaga), // Add the Set Password Saga
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(bybitApiDataSaga),
    fork(bitgetApiDataSaga),
    fork(botSaga), // Fork the combined bot saga
    fork(botSpecificSaga),
    fork(dashSaga),
    fork(binanceApiDataSaga),
    fork(blofinApiDataSaga),
    fork(notificationsSaga),
    fork(nftsSaga),
    fork(paymentSaga),
    fork(BotsAdvanceSettingsSaga),
  ]);
}
