import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_ALL_NOTIFICATIONS,
  UPDATE_ALL_NOTIFICATIONS_SUCCESS,
  UPDATE_ALL_NOTIFICATIONS_FAIL,
} from "./actionTypes"

export const fetchNotifications = () => ({
  type: FETCH_NOTIFICATIONS,
})

export const fetchNotificationsSuccess = notifications => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const fetchNotificationsFail = error => ({
  type: FETCH_NOTIFICATIONS_FAIL,
  payload: error,
})

export const updateNotification = notificationId => ({
  type: UPDATE_NOTIFICATION,
  payload: notificationId,
})

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})

export const updateAllNotifications = () => ({
  type: UPDATE_ALL_NOTIFICATIONS,
})

export const updateAllNotificationsSuccess = () => ({
  type: UPDATE_ALL_NOTIFICATIONS_SUCCESS,
})

export const updateAllNotificationsFail = error => ({
  type: UPDATE_ALL_NOTIFICATIONS_FAIL,
  payload: error,
})
