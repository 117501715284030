import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { useMemo } from "react"
import moment from "moment"
import {
  fetchNotifications,
  updateNotification,
  updateAllNotifications,
} from "../../../store/actions"
import BybitLogo from "../../../assets/images/bybit.svg"
import BinanceLogo from "../../../assets/images/binance.svg"
import BitgetLogo from "../../../assets/images/bitget.svg"
const NotificationDropdown = ({ t }) => {
  const dispatch = useDispatch()
  const rawNotifications = useSelector(
    state => state.notificationsReducer.notifications
  )

  const notifications = useMemo(
    () => rawNotifications[0]?.notifications || [],
    [rawNotifications]
  )

  const [menu, setMenu] = useState(false)

  // useEffect(() => {
  //   dispatch(fetchNotifications())
  // }, [dispatch])
  const intervalDuration = 1000 * 300 // 'x' is the number of seconds you want between each call

  useEffect(() => {
    // Function to fetch notifications
    const fetchNotificationsPeriodically = () => {
      dispatch(fetchNotifications())
    }

    // Immediately fetch notifications when the component is mounted
    fetchNotificationsPeriodically()

    // Set up the interval to fetch notifications every 'x' seconds
    const interval = setInterval(
      fetchNotificationsPeriodically,
      intervalDuration
    )

    // Clear the interval when the component is unmounted to prevent memory leaks
    return () => clearInterval(interval)
  }, [dispatch]) // dependencies array

  const toggleMenu = () => {
    // Check if the menu is about to be opened
    if (!menu) {
      dispatch(updateAllNotifications()) // Dispatch action to mark all notifications as read
    }
    setMenu(!menu) // Toggle menu state
  }

  const markAsRead = notificationId => {
    console.log("Marking as read:", notificationId) // Check if this log appears
    dispatch(updateNotification(notificationId))
  }

  const parseNotification = notificationString => {
    try {
      // console.log("Parsing notification message:", notificationString)
      return JSON.parse(notificationString)
    } catch (error) {
      console.error("Failed to parse notification message:", notificationString)
      return { header: "Invalid Notification", message: "Content unavailable" }
    }
  }

  const unreadCount = notifications.filter(
    notification => !notification.read
  ).length

  const getHumanReadableDifference = notificationTime => {
    const currentTime = moment() // current time
    const timeMoment = moment(notificationTime) // convert notification time to moment object
    const timeDifference = currentTime.diff(timeMoment) // calculate difference

    // Format the time difference as a human-readable string, e.g., '4 hours ago'
    return moment.duration(timeDifference).humanize() + " ago"
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggleMenu} // Use the toggleMenu function
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className={`btn header-item noti-icon position-relative ${
            unreadCount === 0 ? "" : "bx-tada"
          }`}
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`bx bx-bell ${unreadCount === 0 ? "" : "animate-icon"}`}
          />
          {unreadCount > 0 && (
            <span className="badge bg-danger rounded-pill">{unreadCount}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{t("Notifications")}</h6>
              </Col>
              <div className="col-auto">
                {/* <a href="#" className="small">
                  View All
                </a> */}
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.map(notification => {
              // Parse the notification message
              const parsedNotification = JSON.parse(notification.message)

              return (
                <div
                  className="text-reset notification-item"
                  key={notification.id}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="  rounded-circle font-size-16">
                        {notification.type_alert === "bybit" && (
                          <img
                            src={BybitLogo}
                            alt="Bybit"
                            style={{ width: "32px", height: "32px" }}
                          />
                        )}
                        {notification.type_alert === "binance" && (
                          <img
                            src={BinanceLogo}
                            alt="Binance"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                        {notification.type_alert === "bitget" && (
                          <img
                            src={BitgetLogo}
                            alt="Bitget"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                        {!["bybit", "binance", "bitget"].includes(
                          notification.type_alert
                        ) && (
                          <i
                            className="bx bx-bell"
                            style={{ fontSize: "24px" }}
                          ></i> // Adjust the icon size as well
                        )}
                        {/* Other icons or content */}
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{parsedNotification.header}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{parsedNotification.message}</p>
                        {/* <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i> {notification.time}
                        </p>{" "} */}
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {getHumanReadableDifference(notification.time)}
                        </p>
                        {/* Replace with actual time */}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">{t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.func.isRequired, // t is a function provided by withTranslation
}
