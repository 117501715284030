import { put, call, takeLatest } from "redux-saga/effects"
import {
  fetchDashboardSuccess,
  fetchDashboardFailure,
  fetchUserInfoDashboardSuccess,
  fetchUserInfoDashboardFailure,
} from "./actions"
import { ActionTypes } from "./actionTypes"
import {
  getDashboardData,
  getUserInfoDashboardData,
} from "../../helpers/backend_helper"
import { toast } from "react-toastify"

// Worker saga: Fetches the dashboard data and handles success or failure
function* fetchDashboardSaga(action) {
  try {
    // Attempt to call the API to fetch dashboard data
    const response = yield call(getDashboardData, action.payload)

    // If the response indicates success, dispatch a success action with the data
    if (response?.success) {
      yield put(fetchDashboardSuccess(response))
      // Pass the actual data you want in your state
    } else {
      // If the response indicates failure, dispatch a failure action with the message
      yield put(fetchDashboardFailure("Failed to fetch dashboard data"))
      toast.error("Failed to fetch dashboard data")
    }
  } catch (error) {
    // If an error occurs during the fetch, log it and dispatch a failure action with the error message
    yield put(fetchDashboardFailure(error.message || "Unknown error"))
    toast.error(error.message || "Unknown error occurred")
  }
}

// Worker saga: Fetches the dashboard data and handles success or failure
function* fetchUserInfoDashboardSaga(action) {
  try {
    // Attempt to call the API to fetch dashboard data
    const response = yield call(getUserInfoDashboardData, action.payload)

    // If the response indicates success, dispatch a success action with the data
    if (response?.success) {
      yield put(fetchUserInfoDashboardSuccess(response))
      // Pass the actual data you want in your state
    } else {
      // If the response indicates failure, dispatch a failure action with the message
      yield put(fetchUserInfoDashboardFailure("Failed to fetch dashboard data"))
      toast.error("Failed to fetch dashboard data")
    }
  } catch (error) {
    // If an error occurs during the fetch, log it and dispatch a failure action with the error message
    yield put(fetchUserInfoDashboardFailure(error.message || "Unknown error"))
    toast.error(error.message || "Unknown error occurred")
  }
}

// Watcher saga: Watches for the FETCH_DASHBOARD_REQUEST action and invokes fetchDashboardSaga
function* dashSaga() {
  yield takeLatest(ActionTypes.FETCH_DASHBOARD_REQUEST, fetchDashboardSaga)
  yield takeLatest(
    ActionTypes.FETCH_USER_INFO_DASHBOARD_REQUEST,
    fetchUserInfoDashboardSaga
  )
}

export default dashSaga
