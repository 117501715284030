import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Tooltip,
  DropdownItem,
  Badge,
} from "reactstrap"
//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"

const CardWelcome = ({ userInfo, loading }) => {
  const [tright, settright] = useState(false)

  let email = "Loading..." // Default message while loading
  let verified_supporter = false
  let total_bots = "0"
  let current_bots = "0"
  let membership_type = "free"

  // Update the email, verified_supporter, and total_bots once data is loaded
  if (!loading && userInfo) {
    if (userInfo.email) {
      email = userInfo.email
    }
    if (typeof userInfo.verified_supporter !== "undefined") {
      verified_supporter = userInfo.verified_supporter
    }
    if (userInfo.total_bots) {
      total_bots = userInfo.total_bots
    }
    if (userInfo.current_bots) {
      current_bots = userInfo.current_bots
    }
    if (userInfo.membership_type) {
      membership_type = userInfo.membership_type
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    {/* <div className="me-3">
                      <div>
                        {membership_type === "lifetime" && (
                          <img
                            src={avatar1}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        )}

                        {(membership_type === "monthly" ||
                          membership_type === "yearly" ||
                          membership_type === "trial" ||
                          membership_type === "free") && (
                          <img
                            src={avatar2}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        )}

                        {membership_type === "nft" && (
                          <img
                            src={avatar3}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        )}

                        {!membership_type && (
                          <img
                            src={avatar3}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        )}
                      </div>
                    </div> */}
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome Back</p>
                        <h5 className="mb-1">{email}</h5>
                        <Badge color="primary" className="me-1">
                          {membership_type.charAt(0).toUpperCase() +
                            membership_type.slice(1)}{" "}
                          Membership
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="6" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text mb-2">
                            Total Bots Created
                          </p>
                          <h5 className="mb-0">{total_bots}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text mb-2">Current Bots</p>
                          <h5 className="mb-0">{current_bots}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <Tooltip
                            placement="right"
                            isOpen={tright}
                            target="TooltipRight"
                            toggle={() => {
                              settright(!tright)
                            }}
                          >
                            Connect an Affiliated Exchange To Earn a Verified
                            Badge
                          </Tooltip>
                          <p className="text-muted text mb-2" id="TooltipRight">
                            Verified Supporter
                          </p>
                          {verified_supporter ? (
                            <h5
                              className="mb-0 bx bx-badge-check"
                              style={{ color: "#0095f6" }}
                            ></h5>
                          ) : (
                            <h5
                              className="mb-0 bx bx-confused"
                              style={{ color: "red" }}
                            ></h5>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardWelcome
