// Bitget
// Action Types
export const RESET_SUBMIT_BITGET_API_DATA = "RESET_SUBMIT_BITGET_API_DATA"
export const SUBMIT_BITGET_API_DATA = "SUBMIT_BITGET_API_DATA"
export const SUBMIT_BITGET_API_DATA_SUCCESS = "SUBMIT_BITGET_API_DATA_SUCCESS"
export const SUBMIT_BITGET_API_DATA_FAIL = "SUBMIT_BITGET_API_DATA_FAIL"
export const FETCH_API_DATA_BITGET = "FETCH_API_DATA_BITGET"
export const FETCH_API_DATA_SUCCESS_BITGET = "FETCH_API_DATA_SUCCESS_BITGET"
export const FETCH_API_DATA_FAIL_BITGET = "FETCH_API_DATA_FAIL_BITGET"
export const DELETE_API_DATA_BITGET = "DELETE_API_DATA_BITGET"
export const DELETE_API_DATA_SUCCESS_BITGET = "DELETE_API_DATA_SUCCESS_BITGET"
export const DELETE_API_DATA_FAIL_BITGET = "DELETE_API_DATA_FAIL_BITGET"

// Bitget

/**
 * Action to submit Bybit API data.
 * @param {Object} data - The API data to be submitted.
 */
export const submitBitgetApiData = data => ({
  type: SUBMIT_BITGET_API_DATA,
  payload: data,
})

/**
 * Action to be dispatched when Bybit API data submission succeeds.
 * @param {Object} response - The response data from the submission success.
 */
export const submitBitgetApiDataSuccess = response => ({
  type: SUBMIT_BITGET_API_DATA_SUCCESS,
  payload: response,
})

export const resetSubmitBitgetApiDataSuccess = () => ({
  type: RESET_SUBMIT_BITGET_API_DATA,
})

/**
 * Action to be dispatched when Bybit API data submission fails.
 * @param {string} error - The error message or object from the submission failure.
 */
export const submitBitgetApiDataFail = error => ({
  type: SUBMIT_BITGET_API_DATA_FAIL,
  payload: error,
})

// actions.js
export const fetchApiDataBitget = () => ({
  type: FETCH_API_DATA_BITGET,
})

export const fetchApiDataSuccessBitget = data => ({
  type: FETCH_API_DATA_SUCCESS_BITGET,
  payload: data,
})

export const fetchApiDataFailBitget = error => ({
  type: FETCH_API_DATA_FAIL_BITGET,
  payload: error,
})

// Handles successful deletion of the API data
export const deleteApiDataBitget = secToken => ({
  type: DELETE_API_DATA_BITGET,
  payload: secToken,
})

// Handles successful deletion of the API data
export const deleteApiDataSuccessBitget = apiData => ({
  type: DELETE_API_DATA_SUCCESS_BITGET,
  payload: apiData,
})

// Handles failure in deleting the API data with an error message or object
export const deleteApiDataFailBitget = error => ({
  type: DELETE_API_DATA_FAIL_BITGET,
  payload: error,
})
