import axios from "axios";

// Axios instance with potential headers included
const API_URL = "https://api.crodl.com";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// Function to handle logging out
const handleLogout = () => {
  localStorage.removeItem("authUser"); // Remove the user from localStorage
  window.location.href = "/login"; // Redirect to the login page
};

// Add an interceptor to include the token in the request headers if it exists
axiosApi.interceptors.request.use(
  (config) => {
    const authUserData = localStorage.getItem("authUser");
    const token = authUserData ? JSON.parse(authUserData).jwt : null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // If there's no token and you want to ensure they're logged in, redirect to login
      handleLogout();
      return Promise.reject(new Error("No authentication token found"));
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // If you get a specific response from your server indicating the session is invalid
    if (error.response && error.response.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
