import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import "./section1.css" // Make sure to create this CSS file
import webhookicon from "../../../assets/images/crypto/webhook-icon.svg" // Make sure to add your image to this folder

const Section1 = () => {
  const features = [
    {
      title: "Webhooks",
      description:
        "Enhance your webhooks with Crodl's trading syntax, easily setting up Webhooks and Alerts on platforms like TradingView.",
    },
    {
      title: "Crodl",
      description:
        "Link your exchange APIs to Crodl, and let it automate your trading by integrating connected webhooks and alerts.",
    },
    {
      title: "Exchange",
      description:
        "Crodl will transmit webhooks to exchanges using the API, prompting them to execute orders based on signals and webhooks.",
    },
  ]

  return (
    <Container className="feature-section">
      <Row>
        <Col xs="12" className="text-center">
          <h2 className="hero-title">Easy To Set Up</h2>
          <p className="hero-subtitle">
            Crodl simplifies trading by using Exchange APIs to automate trades
            efficiently.
          </p>
        </Col>
      </Row>
      <Row>
        {features.map((feature, index) => (
          <Col md="4" key={index}>
            <Card className="feature-card ">
              <CardBody>
                <h5 className="icons-api-card">{feature.title}</h5>
                <p>{feature.description}</p>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Section1
