import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { SET_PASSWORD } from "./actionTypes";
import { setUserNewPasswordSuccess, setUserNewPasswordError } from "./actions";
import { postresetPassword } from "../../../helpers/backend_helper";

function* setPassword({ payload: { uid, token, password } }) {
  try {
    const response = yield call(postresetPassword, {
      uid,
      token,
      password,
    });

    if (response) {
      yield put(setUserNewPasswordSuccess("Your password has been reset successfully"));
    }
  } catch (error) {
    yield put(setUserNewPasswordError(error));
  }
}

export function* watchUserPasswordSet() {
  yield takeEvery(SET_PASSWORD, setPassword);
}

function* setPasswordSaga() {
  yield all([fork(watchUserPasswordSet)]);
}

export default setPasswordSaga;
