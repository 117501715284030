import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes";

export const setUserNewPassword = (userData) => {
  return {
    type: SET_PASSWORD,
    payload: userData,
  }
}

export const setUserNewPasswordSuccess = message => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const setUserNewPasswordError = message => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: message,
  }
}
