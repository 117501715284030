// export default Dashboard
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CardWelcome from "./welcome"
import WalletBalance from "./wallet_balance"
import Banner from "./banner"
import {
  fetchDashboardRequest,
  fetchUserInfoDashboardRequest,
} from "../../store/dashboard/actions"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Dashboard = () => {
  const dispatch = useDispatch()

  const loading = useSelector(state => state.DashboardReducer?.loading)
  const dashboardData = useSelector(
    state => state.DashboardReducer?.dashboardData
  )
  const dashboardUserInfoData = useSelector(
    state => state.DashboardReducer?.dashboardDataUserInfo
  )
  const error = useSelector(state => state.dashboardReducer?.error)

  useEffect(() => {
    dispatch(fetchDashboardRequest())
    dispatch(fetchUserInfoDashboardRequest())
  }, [dispatch])

  useEffect(() => {
    if (error) {
      const errorMessage =
        typeof error === "string"
          ? error
          : error.message || JSON.stringify(error)
      toast.error(`Error: ${errorMessage}`)
    }

    if (dashboardData) {
    }
  }, [dashboardData, error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />
          <Row>
            <Banner />
            <CardWelcome userInfo={dashboardUserInfoData} />
            <WalletBalance
              walletBalanceData={dashboardData}
              loading={loading}
            />
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Dashboard
