import { call, put, takeEvery } from "redux-saga/effects"
import {
  SUBMIT_BITGET_API_DATA,
  FETCH_API_DATA_BITGET,
  DELETE_API_DATA_BITGET,
} from "./actionTypes"
import {
  submitBitgetApiDataSuccess,
  submitBitgetApiDataFail,
  fetchApiDataSuccessBitget,
  fetchApiDataFailBitget,
  deleteApiDataSuccessBitget,
  deleteApiDataFailBitget,
} from "./actions" // Update this path if the actions are defined elsewhere
import {
  postbitgetapidata,
  fetchApiDataBitget,
  deleteApiDataBitget,
} from "../../helpers/backend_helper" // Ensure these functions are exported from backend_helper
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
/**
 * Worker saga to handle the submission of Bybit API data.
 * @param {Object} action - The action object containing payload data.
 */
function* submitBitgetApiDataSaga({ payload: data }) {
  try {
    const response = yield call(postbitgetapidata, data)

    if (response && response.success) {
      yield put(submitBitgetApiDataSuccess(response.data))
    } else {
      const errorMessage =
        response.message || "Bitget API Data Submission Failed"
      yield put(submitBitgetApiDataFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(submitBitgetApiDataFail(errorText))
    toast.error(errorText || "Bitget API Data Submission Failed", {
      autoClose: 2000,
    })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* fetchApiDataBitgetSaga() {
  try {
    const response = yield call(fetchApiDataBitget) // Makes the API request
    if (response && response.success) {
      // Use response.api_data instead of response.data
      yield put(fetchApiDataSuccessBitget(response.api_data))
    } else {
      const errorMessage = response.message || "Failed to fetch API data"
      yield put(fetchApiDataFailBitget(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(fetchApiDataFailBitget(errorText))
    toast.error(errorText || "Failed to fetch API data", { autoClose: 2000 })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* deleteApiDataBitgetSaga({ payload: secToken }) {
  try {
    const response = yield call(deleteApiDataBitget, secToken)
    if (response && response.success) {
      yield put(deleteApiDataSuccessBitget(secToken))
    } else {
      const errorMessage = response.message || "Bot deletion failed"
      yield put(deleteApiDataFailBitget(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(deleteApiDataFailBitget(errorText))
    toast.error(errorText || "Bot deletion failed", { autoClose: 2000 })
  }
}

/**
 * Watcher saga that spawns new worker sagas for each action type.
 */
function* bitgetApiDataSaga() {
  yield takeEvery(SUBMIT_BITGET_API_DATA, submitBitgetApiDataSaga)
  yield takeEvery(FETCH_API_DATA_BITGET, fetchApiDataBitgetSaga)
  yield takeEvery(DELETE_API_DATA_BITGET, deleteApiDataBitgetSaga)
}

export default bitgetApiDataSaga
