import React from "react"
import { Container, Row, Col, Button } from "reactstrap" // Assuming Reactstrap
import "./hero.css" // Make sure to create this CSS file
import { Link, useNavigate } from "react-router-dom"

import heroImg from "../../../assets/images/crypto/heroimage.svg" // Make sure to add your image to this folder
const HeroSection = () => {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col xs="12" className="text-center">
            <h1 className="hero-title">
              CRODL{" "}
              <span className="new-line">Automated Trading Made Simple</span>
            </h1>

            <p className="hero-subtitle">
              Link Webhooks, Indicators, and Strategies to Trading Exchanges
              through Crodl using API connections.
            </p>
            <div className="button-group">
              <Link to="/login" className="btn btn-get-started">
                Get Started
              </Link>
              <Button outline className="btn-read-docs">
                Read Docs
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="mt-4">
            <img
              src={heroImg}
              alt="Graphic"
              className="hero-graphic mx-auto d-block"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col xs="12">
            <hr /> 
          </Col>
        </Row> */}
      </Container>
    </div>
  )
}
export default HeroSection
