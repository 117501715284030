import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { get } from "helpers/api_helper";

import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Col, Container, Row } from "reactstrap";
import { map } from "lodash";
import BloFinLogo from "../../assets/images/blofin.svg";
import BybitLogo from "../../assets/images/bybit.svg";
import BinanceLogo from "../../assets/images/binance.svg";
import BitgetLogo from "../../assets/images/bitget.svg";

// Import Card for Exchange
import CardExchange from "./card-exchange"; // Updated import

import Spinners from "components/Common/Spinner";

// const Exchanges = props => {
//   const [exchanges, setExchanges] = useState(null); // Renamed from data to exchanges

//   useEffect(() => {
//     get('/api/exchanges') // Updated API endpoint
//       .then(responseData => {
//         setExchanges(responseData); // Updated setData to setExchanges
//       })
//       .catch(error => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

const Exchanges = (props) => {
  const [exchanges, setExchanges] = useState(null);

  // Dummy data for testing
  const dummyData = {
    exchanges: [
      {
        id: 1,
        name: "BloFin",
        logo: BloFinLogo,
        type: "DEX",
        features: ["Beta - Futures"],
        color: "blue",
        description: "Bybit is a global cryptocurrency exchange...",
        url: "https://www.Blofin.com",
      },
      {
        id: 2,
        name: "Bybit",
        logo: BybitLogo,
        type: "DEX",
        features: ["Spot", "Futures"],
        color: "blue",
        description: "BloFin is a global cryptocurrency exchange...",
        url: "https://www.bybit.com",
      },
      {
        id: 3,
        name: "Bitget",
        logo: BitgetLogo,
        type: "DEX",
        features: ["Spot", "Futures"],
        color: "blue",
        description: "Bitget is a global cryptocurrency exchange...",
        url: "https://www.Bitget.com",
      },
      {
        id: 4,
        name: "Binance",
        logo: BinanceLogo,
        type: "DEX",
        features: ["Beta - Spot", "Beta - Futures"],
        color: "blue",
        description: "Binance is a global cryptocurrency exchange...",
        url: "https://www.binance.com",
      },
    ],
  };

  useEffect(() => {
    // Using dummy data
    setExchanges(dummyData.exchanges);
  }, []);

  document.title = "Exchanges | Crodl"; // Updated title

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Exchanges" breadcrumbItem="Connect Exchanges" />
          {exchanges ? (
            <Row>
              {map(exchanges, (exchange, key) => (
                <CardExchange exchange={exchange} key={"_exchange_" + key} />
              ))}
            </Row>
          ) : (
            NaN
          )}
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Exchanges;
