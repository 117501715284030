export const BotActionTypes = {
  FETCH_SPECIFIC_BOT_REQUEST: "FETCH_SPECIFIC_BOT_REQUEST",
  FETCH_SPECIFIC_BOT_SUCCESS: "FETCH_SPECIFIC_BOT_SUCCESS",
  FETCH_SPECIFIC_BOT_FAILURE: "FETCH_SPECIFIC_BOT_FAILURE",
  POST_PLACE_ORDER_REQUEST: "POST_PLACE_ORDER_REQUEST",
  POST_PLACE_ORDER_SUCCESS: "POST_PLACE_ORDER_SUCCESS",
  POST_PLACE_ORDER_FAILURE: "POST_PLACE_ORDER_FAILURE",
  POST_CLOSE_ORDER_REQUEST: "POST_CLOSE_ORDER_REQUEST", // New action type for closing orders
  POST_CLOSE_ORDER_SUCCESS: "POST_CLOSE_ORDER_SUCCESS", // New action type for closing orders success
  POST_CLOSE_ORDER_FAILURE: "POST_CLOSE_ORDER_FAILURE", // New action type for closing orders failure
}

// Export individual action types for easy import
export const FETCH_SPECIFIC_BOT_REQUEST =
  BotActionTypes.FETCH_SPECIFIC_BOT_REQUEST
export const FETCH_SPECIFIC_BOT_SUCCESS =
  BotActionTypes.FETCH_SPECIFIC_BOT_SUCCESS
export const FETCH_SPECIFIC_BOT_FAILURE =
  BotActionTypes.FETCH_SPECIFIC_BOT_FAILURE
export const POST_PLACE_ORDER_REQUEST = BotActionTypes.POST_PLACE_ORDER_REQUEST
export const POST_PLACE_ORDER_SUCCESS = BotActionTypes.POST_PLACE_ORDER_SUCCESS
export const POST_PLACE_ORDER_FAILURE = BotActionTypes.POST_PLACE_ORDER_FAILURE
export const POST_CLOSE_ORDER_REQUEST = BotActionTypes.POST_CLOSE_ORDER_REQUEST
export const POST_CLOSE_ORDER_SUCCESS = BotActionTypes.POST_CLOSE_ORDER_SUCCESS
export const POST_CLOSE_ORDER_FAILURE = BotActionTypes.POST_CLOSE_ORDER_FAILURE
