// 1. Imports
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { fetchSpecificBotRequest } from "../../store/bot/actions";
import { deleteBot, resetDeleteSuccess } from "../../store/bots/actions";
import {
  saveSettingsRequest,
  fetchSettingsRequest,
} from "../../store/botsAdvanceSettings/actions"; // Adjust the import path
import FuturesForm from "./futures";
import FuturesFormBybit from "./Bybit/futures";
import SpotForm from "./spot";

// Reactstrap components
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Table,
  CardFooter,
  CardText,
  Badge,
  InputGroup,
  Button,
  Label,
  Input,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  UncontrolledTooltip,
  TabPane,
} from "reactstrap";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

// Utilities and images
import classnames from "classnames";
import { Link } from "react-router-dom";
import BybitLogo from "../../assets/images/bybit.svg";
import BinanceLogo from "../../assets/images/binance.svg";
import BitgetLogo from "../../assets/images/bitget.svg";
import BloFinLogo from "../../assets/images/blofin.svg";

// 2. Component
const Bot = () => {
  // 2.1 Navigation and Redux hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { botId } = useParams();

  // 2.2 Redux state
  const botData = useSelector((state) => state.BotSpecificReducer.botData);
  const deleteSuccess = useSelector((state) => state.BotReducer.deleteSuccess);

  const loading = useSelector((state) => state.BotReducer.loading);
  const error = useSelector((state) => state.BotReducer.error);

  // 2.3 Local state
  const [deleteModal, setDeleteModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [deleteBotId, setDeleteBotId] = useState(null);

  // 2.4 Effect hooks
  useEffect(() => {
    if (botId) dispatch(fetchSpecificBotRequest(botId));
  }, [botId, dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Bot successfully deleted!", {
        onClose: () => {
          // Once the toast is closed, redirect to the bots overview page
          navigate("/bots-overview");
        },
        autoClose: 2000, // close after 2 seconds
      });

      // Dispatch reset action after a short delay to ensure it doesn't interfere with the toast
      setTimeout(() => {
        dispatch(resetDeleteSuccess());
      }, 2000);
    }
  }, [deleteSuccess, navigate, dispatch]);

  // 2.5 Handlers
  const handleDeleteBotClick = (botId) => {
    setDeleteBotId(botId);
    setDeleteModal(true);
  };

  const handleSettingsClick = (botId) => {
    // setDeleteBotId(botId)
    setSettingsModal(true);
  };

  const handleDeleteBot = (botId) => {
    dispatch(deleteBot(botId)); // Initiate deletion
    setDeleteModal(false); // Close the modal
  };

  // 2.6 Helper variables
  const botDetails = botData ? botData.fields : null;

  useEffect(() => {
    if (botId) {
      dispatch(fetchSettingsRequest(botId));
    }
  }, [botId, dispatch]);

  const botSettingsArray = useSelector(
    (state) => state.botAdvanceSettingsReducer.settings[botId]
  );
  const botSettings = botSettingsArray?.[0] || {};

  // State hooks for form fields
  const [botBuyName, setBotBuyName] = useState("Select Side");
  const [botSellName, setBotSellName] = useState("Select Side");
  const [botCloseBuyName, setBotCloseBuyName] = useState("Select Side");
  const [botCloseSellName, setBotCloseSellName] = useState("Select Side");
  const [botDelayValue, setBotDelayValue] = useState("0");
  const [botPyramidValue, setBotPyramidValue] = useState("0");

  // Update local state when botSettings changes
  useEffect(() => {
    setBotBuyName(botSettings.bot_buy_name || "long");
    setBotSellName(botSettings.bot_sell_name || "short");
    setBotCloseBuyName(botSettings.bot_close_buy_name || "close_long");
    setBotCloseSellName(botSettings.bot_close_sell_name || "close_short");
    setBotDelayValue(botSettings.bot_delay_value?.toString() || "0");
    setBotPyramidValue(botSettings.bot_pyramid_value?.toString() || "0");
  }, [botSettings]);
  // Adjust modal state management as needed

  const handleSettingsSubmit = () => {
    const settingsPayload = {
      bot_id: botId,
      bot_buy_name: botBuyName,
      bot_sell_name: botSellName,
      bot_close_buy_name: botCloseBuyName,
      bot_close_sell_name: botCloseSellName,
      bot_delay_value: parseInt(botDelayValue, 10), // Ensure numerical value
      bot_pyramid_value: parseInt(botPyramidValue, 10), // Ensure numerical value
    };

    dispatch(saveSettingsRequest(settingsPayload));
    setSettingsModal(false); // Close the modal after submit
  };
  return (
    <React.Fragment>
      {/* {botDetails?.bot_trading_mode === "future" && ( */}
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card className="border card border-primary">
              <CardBody>
                {botDetails ? (
                  <>
                    <Modal
                      className="modal-dialog modal-dialog-centered"
                      isOpen={settingsModal}
                      toggle={() => setSettingsModal(!settingsModal)}
                    >
                      <ModalHeader
                        toggle={() => setSettingsModal(!settingsModal)}
                      >
                        Advance Bot Settings
                      </ModalHeader>
                      {botDetails?.bot_trading_mode === "spot" ? (
                        <ModalBody>
                          <form onSubmit={(e) => e.preventDefault()}>
                            {" "}
                            {/* Prevent default form submission */}
                            <div className="form-group">
                              <label htmlFor="botId">Bot ID</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botId"
                                readOnly={true}
                                value={botDetails.bot_id} // Assuming botDetails is correctly populated
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botBuyName">Bot Buy Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botBuyName"
                                value={botBuyName}
                                onChange={(e) => setBotBuyName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botSellName">Bot Sell Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botSellName"
                                value={botSellName}
                                onChange={(e) => setBotSellName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botDelayValue">
                                Bot Delay Value
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botDelayValue"
                                value={botDelayValue}
                                onChange={(e) =>
                                  setBotDelayValue(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botPyramidValue">
                                Bot Pyramid Value
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botPyramidValue"
                                value={botPyramidValue}
                                onChange={(e) =>
                                  setBotPyramidValue(e.target.value)
                                }
                              />
                            </div>
                          </form>
                        </ModalBody>
                      ) : (
                        <ModalBody>
                          <form onSubmit={(e) => e.preventDefault()}>
                            {" "}
                            {/* Prevent default form submission */}
                            <div className="form-group">
                              <label htmlFor="botId">Bot ID</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botId"
                                readOnly={true}
                                value={botDetails.bot_id} // Assuming botDetails is correctly populated
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botBuyName">Bot Buy Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botBuyName"
                                value={botBuyName}
                                onChange={(e) => setBotBuyName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botSellName">Bot Sell Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="botSellName"
                                value={botSellName}
                                onChange={(e) => setBotSellName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botBuyName">
                                Bot Close Buy Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botCloseBuyName"
                                value={botCloseBuyName}
                                onChange={(e) =>
                                  setBotCloseBuyName(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botCloseSellName">
                                Bot Close Sell Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botCloseSellName"
                                value={botCloseSellName}
                                onChange={(e) =>
                                  setBotCloseSellName(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botDelayValue">
                                Bot Delay Value
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botDelayValue"
                                value={botDelayValue}
                                onChange={(e) =>
                                  setBotDelayValue(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="botPyramidValue">
                                Bot Pyramid Value
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="botPyramidValue"
                                value={botPyramidValue}
                                onChange={(e) =>
                                  setBotPyramidValue(e.target.value)
                                }
                              />
                            </div>
                          </form>
                        </ModalBody>
                      )}

                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={() => setSettingsModal(false)}
                        >
                          Cancel
                        </Button>
                        <Button color="info" onClick={handleSettingsSubmit}>
                          Save Settings
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Delete Model */}
                    <Modal
                      className="modal-dialog modal-dialog-centered"
                      isOpen={deleteModal}
                      toggle={() => setDeleteModal(!deleteModal)}
                    >
                      <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
                        Confirm Delete
                      </ModalHeader>
                      <ModalBody>
                        Deleting this bot will terminate all its active webhooks
                        and cannot be undone. Are you certain you wish to
                        proceed with the deletion of this bot?
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={() => setDeleteModal(false)}
                          className="external-event"
                        >
                          No
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => {
                            dispatch(deleteBot(deleteBotId));
                            setDeleteModal(false);
                          }}
                        >
                          Yes, Delete
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <div className="d-flex justify-content-end">
                      <Link
                        to="#"
                        id={"settings" + botDetails.bot_id}
                        onClick={() => handleSettingsClick(botDetails.bot_id)}
                        className="text-info me-2" // Optionally add a class for styling
                      >
                        <i className="bx bx-cog" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"settings" + botDetails.bot_id}
                        >
                          Advanced Settings
                        </UncontrolledTooltip>
                      </Link>

                      <Link
                        to="#"
                        id={"deleteBot" + botDetails.bot_id}
                        onClick={() => handleDeleteBotClick(botDetails.bot_id)}
                        className="text-danger" // Optionally add a class for styling
                      >
                        <i className="bx bx-trash" />
                        <UncontrolledTooltip
                          placement="top"
                          target={"deleteBot" + botDetails.bot_id}
                        >
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src={
                          (botDetails.bot_exchange === "bybit" && BybitLogo) ||
                          (botDetails.bot_exchange === "bitget" &&
                            BitgetLogo) ||
                          (botDetails.bot_exchange === "blofin" &&
                            BloFinLogo) ||
                          (botDetails.bot_exchange === "binance" &&
                            BinanceLogo) ||
                          null
                        }
                        alt={`${botDetails.bot_exchange || "Exchange"} Logo`}
                        className="me-3 rounded-circle"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                        }}
                      />
                      <div>
                        <h5 className="card-title">
                          {botDetails.bot_name || "Name not available"}
                        </h5>
                        <h6 className="text-muted">Bot ID: {botId}</h6>
                      </div>
                    </div>
                    <div>
                      <Badge color="primary" className="me-2">
                        Type:{" "}
                        {botDetails.bot_trading_type || "Not available on Spot"}
                      </Badge>
                      <Badge color="primary" className="me-2">
                        Exchange:{" "}
                        {botDetails.bot_exchange || "Exchange not available"}
                      </Badge>
                      <Badge color="primary">
                        Trading Type:{" "}
                        {botDetails.bot_trading_mode ||
                          "Trading Mode not available"}
                      </Badge>
                    </div>
                    <CardText className="mt-3">
                      Created At:{" "}
                      {botDetails.bot_start_time
                        ? new Date(botDetails.bot_start_time).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          )
                        : "Time not available"}
                    </CardText>

                    {/* Add more bot details as needed */}
                  </>
                ) : (
                  <p>No details available for this bot.</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {botDetails?.bot_trading_mode === "spot" ? (
          <SpotForm
            botDetails={botDetails}
            botBuyName={botBuyName}
            botSellName={botSellName}
            botCloseBuyName={botCloseBuyName}
            botCloseSellName={botCloseSellName}
            botData={botData}
            dispatch={dispatch}
          />
        ) : botDetails?.bot_exchange === "bybit" ? (
          <FuturesForm
            botDetails={botDetails}
            botBuyName={botBuyName}
            botSellName={botSellName}
            botCloseBuyName={botCloseBuyName}
            botCloseSellName={botCloseSellName}
            botData={botData}
            dispatch={dispatch}
          />
        ) : (
          <FuturesForm
            botDetails={botDetails}
            botBuyName={botBuyName}
            botSellName={botSellName}
            botCloseBuyName={botCloseBuyName}
            botCloseSellName={botCloseSellName}
            botData={botData}
            dispatch={dispatch}
          />
        )}

        {/* {botDetails?.bot_trading_mode === "spot" ? (
          <SpotForm
            botDetails={botDetails}
            botBuyName={botBuyName}
            botSellName={botSellName}
            botCloseBuyName={botCloseBuyName}
            botCloseSellName={botCloseSellName}
            botData={botData}
            dispatch={dispatch}
          />
        ) : (
          <FuturesForm
            botDetails={botDetails}
            botBuyName={botBuyName}
            botSellName={botSellName}
            botCloseBuyName={botCloseBuyName}
            botCloseSellName={botCloseSellName}
            botData={botData}
            dispatch={dispatch}
          />
        )} */}
      </div>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Bot;
