// BloFin

// Constants for submitting BloFin API data
export const RESET_SUBMIT_BLOFIN_API_DATA = "RESET_SUBMIT_BLOFIN_API_DATA";
export const SUBMIT_BLOFIN_API_DATA = "SUBMIT_BLOFIN_API_DATA";
export const SUBMIT_BLOFIN_API_DATA_SUCCESS = "SUBMIT_BLOFIN_API_DATA_SUCCESS";
export const SUBMIT_BLOFIN_API_DATA_FAIL = "SUBMIT_BLOFIN_API_DATA_FAIL";

// Constants for adding new BloFin API data
export const ADD_NEW_BLOFIN_API_DATA = "ADD_NEW_BLOFIN_API_DATA";
export const ADD_BLOFIN_API_DATA_SUCCESS = "ADD_BLOFIN_API_DATA_SUCCESS";
export const ADD_BLOFIN_API_DATA_FAIL = "ADD_BLOFIN_API_DATA_FAIL";

// API DATAS
export const FETCH_API_DATA_BLOFIN = "FETCH_API_DATA_BLOFIN";
export const FETCH_API_DATA_SUCCESS_BLOFIN = "FETCH_API_DATA_SUCCESS_BLOFIN";
export const FETCH_API_DATA_FAIL_BLOFIN = "FETCH_API_DATA_FAIL_BLOFIN";

// DELETE API DATAS
export const DELETE_API_DATA_BLOFIN = "DELETE_API_DATA_BLOFIN";
export const DELETE_API_DATA_SUCCESS_BLOFIN = "DELETE_API_DATA_SUCCESS_BLOFIN";
export const DELETE_API_DATA_FAIL_BLOFIN = "DELETE_API_DATA_FAIL_BLOFIN";
