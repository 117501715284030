import React, { useEffect, useState } from "react"
import NavbarHomepage from "./navbar/navbar"
import HeroHomepage from "./Hero/hero"
import Section1 from "./section1/section1"
import Section2 from "./section2/section2"
import Pricing from "./pricing/pricing"
import Footer from "./footer/footer"
import Indicators from "./indicators/indicators"
import NftsHomepage from "./nfts/nfts"
//Import Components

const Homepage = () => {
  //meta title
  document.title = "Crodl Trading"

  const [imglight, setimglight] = useState(true)
  const [navClass, setnavClass] = useState("")

  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  }, [])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  return (
    <React.Fragment>
      <NavbarHomepage />
      <HeroHomepage />
      <Section1 />
      <Section2 />
      <Pricing />
      <Indicators />
      <NftsHomepage />
      <Footer />
    </React.Fragment>
  )
}

export default Homepage
