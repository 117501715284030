// import React, { useEffect } from "react"
// import { useSelector, useDispatch } from "react-redux"
// import { useNavigate, Link } from "react-router-dom"
// import { useFormik } from "formik"
// import * as Yup from "yup"
// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Form,
//   Input,
//   FormFeedback,
//   Label,
// } from "reactstrap"
// import { loginUser } from "../../store/actions"
// import profile from "assets/images/profile-img.png"
// import logo from "assets/images/logo.svg"
// import { ToastContainer, toast } from "react-toastify"

// const Login = () => {
//   document.title = "Login | Crodl"
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const loginState = useSelector(state => state.Login)

//   const validation = useFormik({
//     initialValues: {
//       email: "",
//       password: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string()
//         .email("Invalid email format")
//         .required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),
//     onSubmit: values => {
//       dispatch(loginUser(values))
//     },
//   })

//   useEffect(() => {
//     if (loginState.isLoggedIn) {
//       navigate("/dashboard")
//     }
//   }, [loginState, navigate])

//   const { error } = loginState

//   return (
//     <React.Fragment>
//       <div className="account-pages my-5 pt-sm-5">
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={8} lg={6} xl={5}>
//               <Card className="overflow-hidden">
//                 <div className="bg-primary-subtle">
//                   <Row>
//                     <Col className="col-7">
//                       <div className="text-warning p-4">
//                         <h5 className="text-warning">Welcome Back!</h5>
//                         <p>Log in to continue using Crodl.</p>
//                       </div>
//                     </Col>
//                     <Col className="col-5 align-self-end">
//                       <img src={profile} alt="" className="img-fluid" />
//                     </Col>
//                   </Row>
//                 </div>
//                 <CardBody className="pt-0">
//                   <div>
//                     <Link to="/" className="logo-light-element">
//                       <div className="avatar-md profile-user-wid mb-4">
//                         <span className="avatar-title rounded-circle bg-light">
//                           <img
//                             src={logo}
//                             alt=""
//                             className="rounded-circle"
//                             height="34"
//                           />
//                         </span>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="p-2">
//                     <Form
//                       className="form-horizontal"
//                       onSubmit={e => {
//                         e.preventDefault()
//                         validation.handleSubmit()
//                         return false
//                       }}
//                     >
//                       <div className="mb-3">
//                         <Label className="form-label">Email</Label>
//                         <Input
//                           name="email"
//                           className="form-control"
//                           placeholder="Enter email"
//                           type="email"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.email || ""}
//                           invalid={
//                             validation.touched.email && validation.errors.email
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.email && validation.errors.email ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.email}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mb-3">
//                         <Label className="form-label">Password</Label>
//                         <Input
//                           name="password"
//                           value={validation.values.password || ""}
//                           type="password"
//                           placeholder="Enter Password"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           invalid={
//                             validation.touched.password &&
//                             validation.errors.password
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.password &&
//                         validation.errors.password ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.password}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mt-3 d-grid">
//                         <button
//                           className="btn btn-primary btn-block"
//                           type="submit"
//                         >
//                           Log In
//                         </button>
//                       </div>
//                       <div className="mt-4 text-center">
//                         <Link to="/forgot-password" className="text-muted">
//                           <i className="mdi mdi-lock me-1" />
//                           Forgot your password?
//                         </Link>
//                       </div>
//                     </Form>
//                   </div>
//                 </CardBody>
//               </Card>
//               <div className="mt-5 text-center">
//                 <p>
//                   Don&#39;t have an account ?{" "}
//                   <Link to="/register" className="fw-medium text-primary">
//                     {" "}
//                     Signup now{" "}
//                   </Link>{" "}
//                 </p>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//       <ToastContainer />
//     </React.Fragment>
//   )
// }

// export default Login
/////////////////////////////
// import React, { useEffect } from "react"
// import { useSelector, useDispatch } from "react-redux"
// import { useNavigate, Link } from "react-router-dom"
// import { useFormik } from "formik"
// import * as Yup from "yup"
// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Form,
//   Input,
//   FormFeedback,
//   Label,
// } from "reactstrap"
// import { loginUser } from "../../store/actions"
// import profile from "assets/images/profile-img.png"
// import logo from "assets/images/logo.svg"
// import { ToastContainer, toast } from "react-toastify"

// const Login = () => {
//   document.title = "Login | Crodl"
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const loginState = useSelector(state => state.Login)

//   const validation = useFormik({
//     initialValues: {
//       email: "",
//       password: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string()
//         .email("Invalid email format")
//         .required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),
//     onSubmit: values => {
//       dispatch(loginUser(values))
//     },
//   })

//   useEffect(() => {
//     if (loginState.isLoggedIn) {
//       navigate("/dashboard")
//     }
//   }, [loginState, navigate])

//   const { error } = loginState

//   return (
//     <React.Fragment>
//       <div className="account-pages my-5 pt-sm-5">
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={8} lg={6} xl={5}>
//               <Card className="overflow-hidden">
//                 <div className="bg-primary-subtle">
//                   <Row>
//                     <Col className="col-7">
//                       <div className="text-warning p-4">
//                         <h5 className="text-warning">Welcome Back!</h5>
//                         <p>Log in to continue using Crodl.</p>
//                       </div>
//                     </Col>
//                     <Col className="col-5 align-self-end">
//                       <img src={profile} alt="" className="img-fluid" />
//                     </Col>
//                   </Row>
//                 </div>
//                 <CardBody className="pt-0">
//                   <div>
//                     <Link to="/" className="logo-light-element">
//                       <div className="avatar-md profile-user-wid mb-4">
//                         <span className="avatar-title rounded-circle bg-light">
//                           <img
//                             src={logo}
//                             alt=""
//                             className="rounded-circle"
//                             height="34"
//                           />
//                         </span>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="p-2">
//                     <Form
//                       className="form-horizontal"
//                       onSubmit={e => {
//                         e.preventDefault()
//                         validation.handleSubmit()
//                         return false
//                       }}
//                     >
//                       <div className="mb-3">
//                         <Label className="form-label">Email</Label>
//                         <Input
//                           name="email"
//                           className="form-control"
//                           placeholder="Enter email"
//                           type="email"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.email || ""}
//                           invalid={
//                             validation.touched.email && validation.errors.email
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.email && validation.errors.email ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.email}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mb-3">
//                         <Label className="form-label">Password</Label>
//                         <Input
//                           name="password"
//                           value={validation.values.password || ""}
//                           type="password"
//                           placeholder="Enter Password"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           invalid={
//                             validation.touched.password &&
//                             validation.errors.password
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.password &&
//                         validation.errors.password ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.password}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mt-3 d-grid">
//                         <button
//                           className="btn btn-primary btn-block"
//                           type="submit"
//                         >
//                           Log In
//                         </button>
//                       </div>
//                       <div className="mt-4 text-center">
//                         <Link to="/forgot-password" className="text-muted">
//                           <i className="mdi mdi-lock me-1" />
//                           Forgot your password?
//                         </Link>
//                       </div>
//                     </Form>
//                   </div>
//                 </CardBody>
//               </Card>
//               <div className="mt-5 text-center">
//                 <p>
//                   Don&#39;t have an account ?{" "}
//                   <Link to="/register" className="fw-medium text-primary">
//                     {" "}
//                     Signup now{" "}
//                   </Link>{" "}
//                 </p>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//       <ToastContainer />
//     </React.Fragment>
//   )
// }

// export default Login

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { loginUser } from "../../store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  document.title = "Login | Crodl";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.Login);

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  useEffect(() => {
    if (loginState.isLoggedIn) {
      navigate("/dashboard");
    }
  }, [loginState, navigate]);

  const { error } = loginState;

  return (
    <React.Fragment>
      {/* Header Banner */}
      <Alert color="info" className="text-center mb-4">
        <strong>We Have Upgraded!</strong> Visit our new bot and get a 30-day
        free trial.{" "}
        <a
          href="https://crodltrading.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-dark"
        >
          Click here to learn more.
        </a>
      </Alert>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-warning p-4">
                        <h5 className="text-warning">Welcome Back!</h5>
                        <p>Log in to continue using Crodl.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    Signup now
                  </Link>
                </p>
              </div>
              <div className="mt-4 text-center">
                <h6 className="text-primary">We Have Upgraded!</h6>
                <p>Visit our new bot and get a 30-day free trial.</p>
                <a
                  href="https://crodltrading.com"
                  className="btn btn-success"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit New Bot
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Login;
