// Django
export const GET_DJANGO_DASHBOARD = "https://api.crodl.com";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.crodl.com";

// Register
export const REGISTER = `${API_BASE_URL}/register`;

// Login
export const LOGIN = `${API_BASE_URL}/login`;

// Password Management
export const PASSWORD_FORGET = `${API_BASE_URL}/request-password-reset`;
export const PASSWORD_RESET = `${API_BASE_URL}/reset-password`;

// Bybit API Data Submit
export const BYBITAPIDATASUBMIT = `${API_BASE_URL}/bybit-api-data-submit`;

// Bitget API Data Submit
export const BITGETAPIDATASUBMIT = `${API_BASE_URL}/bitget-api-data-submit`;

// Binance API Data Submit
export const BINANCEAPIDATASUBMIT = `${API_BASE_URL}/binance-api-data-submit`;

// BloFin API Data Submit
export const BLOFINAPIDATASUBMIT = `${API_BASE_URL}/blofin-api-data-submit`;

// Create Bot
export const BOTCREATION = `${API_BASE_URL}/bot-overview`;

// Get Bot
export const BOTDATA = `${API_BASE_URL}/bot-overview`;

// Get Specific Bot Data
export const BOTSPECIFICDATA = `${API_BASE_URL}/bots`;

// Get API Data
export const APIDATA = `${API_BASE_URL}/api-data`;

// Delete API Data
export const DELETEAPIDATA = `${API_BASE_URL}/api-data`;

// Available API Exchanges
export const AVAILABLEAPIEXCHANGES = `${API_BASE_URL}/user-connected-exchanges`;

// Place Bot
export const PLACEBOT = `${API_BASE_URL}/bots/place-order`;

// Close Bot
export const CLOSEBOT = `${API_BASE_URL}/bots/close-order`;

// Get Dashboard Data
export const DASHBOARD = `${API_BASE_URL}/`;
export const DASHBOARDUSERINFODATA = `${API_BASE_URL}/dashboard-user-info`;

// Get API Data Bitget
export const APIDATABITGET = `${API_BASE_URL}/api-data-bitget`;

// Delete API Data Bitget
export const DELETEAPIDATABITGET = `${API_BASE_URL}/api-data-bitget`;

// Get API Data Binance
export const APIDATABINANCE = `${API_BASE_URL}/api-data-binance`;

// Delete API Data Binance
export const DELETEAPIDATABINANCE = `${API_BASE_URL}/api-data-binance`;

// Get API Data BloFin
export const APIDATABLOFIN = `${API_BASE_URL}/api-data-blofin`;

// Delete API Data BloFin
export const DELETEAPIDATABLOFIN = `${API_BASE_URL}/api-data-blofin`;

// Notifications
export const NOTIFICATIONS = `${API_BASE_URL}/notifications`;
export const NOTIFICATIONSREAD = `${API_BASE_URL}/notifications-read`;
export const ALLNOTIFICATIONSREAD = `${API_BASE_URL}/notifications-read-all`;

// NFTS
export const NFTSGET = `${API_BASE_URL}/nft-handler`;
export const NFTSPOST = `${API_BASE_URL}/nft-handler`;
export const NFTSDELETE = `${API_BASE_URL}/nft-handler`;

// PAYMENTS
export const PAYMENT = `${API_BASE_URL}/payment`;

// Save Bot Advance Settings
export const SAVEBOTADVANCESETTINGS = `${API_BASE_URL}/bot-advance-settings`;
export const FETCHBOTADVANCESETTINGS = `${API_BASE_URL}/bot-advance-settings`;
