import { call, put, takeEvery } from "redux-saga/effects";
import {
  SUBMIT_BLOFIN_API_DATA,
  FETCH_API_DATA_BLOFIN,
  DELETE_API_DATA_BLOFIN,
} from "./actionTypes";
import {
  submitBloFinApiDataSuccess,
  submitBloFinApiDataFail,
  fetchApiDataSuccessBloFin,
  fetchApiDataFailBloFin,
  deleteApiDataSuccessBloFin,
  deleteApiDataFailBloFin,
} from "./actions"; // Update this path if the actions are defined elsewhere
import {
  postblofinapidata,
  fetchApiDataBloFin,
  deleteApiDataBloFin,
} from "../../helpers/backend_helper"; // Ensure these functions are exported from backend_helper
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**
 * Worker saga to handle the submission of Bybit API data.
 * @param {Object} action - The action object containing payload data.
 */
function* submitBloFinApiDataSaga({ payload: data }) {
  try {
    const response = yield call(postblofinapidata, data);

    if (response && response.success) {
      yield put(submitBloFinApiDataSuccess(response.data));
    } else {
      const errorMessage =
        response.message || "BloFin API Data Submission Failed";
      yield put(submitBloFinApiDataFail(errorMessage));
      toast.error(errorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    const errorText = error.toString();
    yield put(submitBloFinApiDataFail(errorText));
    toast.error(errorText || "BloFin API Data Submission Failed", {
      autoClose: 2000,
    });
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* fetchApiDataBloFinSaga() {
  try {
    const response = yield call(fetchApiDataBloFin); // Makes the API request
    if (response && response.success) {
      // Use response.api_data instead of response.data
      yield put(fetchApiDataSuccessBloFin(response.api_data));
    } else {
      const errorMessage = response.message || "Failed to fetch API data";
      yield put(fetchApiDataFailBloFin(errorMessage));
      toast.error(errorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    const errorText = error.toString();
    yield put(fetchApiDataFailBloFin(errorText));
    toast.error(errorText || "Failed to fetch API data", { autoClose: 2000 });
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* deleteApiDataBloFinSaga({ payload: secToken }) {
  try {
    const response = yield call(deleteApiDataBloFin, secToken);
    if (response && response.success) {
      yield put(deleteApiDataSuccessBloFin(secToken));
    } else {
      const errorMessage = response.message || "Bot deletion failed";
      yield put(deleteApiDataFailBloFin(errorMessage));
      toast.error(errorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    const errorText = error.toString();
    yield put(deleteApiDataFailBloFin(errorText));
    toast.error(errorText || "Bot deletion failed", { autoClose: 2000 });
  }
}

/**
 * Watcher saga that spawns new worker sagas for each action type.
 */
function* blofinApiDataSaga() {
  yield takeEvery(SUBMIT_BLOFIN_API_DATA, submitBloFinApiDataSaga);
  yield takeEvery(FETCH_API_DATA_BLOFIN, fetchApiDataBloFinSaga);
  yield takeEvery(DELETE_API_DATA_BLOFIN, deleteApiDataBloFinSaga);
}

export default blofinApiDataSaga;
