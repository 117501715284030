import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  TabContent,
  TabPane,
  Label,
  Button,
  Input,
  Form,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  createBot,
  fetchBotData,
  deleteBot,
  resetDeleteSuccess,
  fetchAvailableExchangeDataRequest,
} from "../../store/bots/actions"; // Add deleteBot here
import newBotImg from "../../assets/images/newbot.png";
import { Link } from "react-router-dom";
import { size, map, set } from "lodash";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import BybitLogo from "../../assets/images/bybit.svg";
import BinanceLogo from "../../assets/images/binance.svg";
import BitgetLogo from "../../assets/images/bitget.svg";
import BloFinLogo from "../../assets/images/blofin.svg";

const BotsOverview = () => {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [botName, setBotName] = useState("");
  const [botMode, setBotMode] = useState("");
  const [botType, setBotType] = useState("");
  const [botExchange, setBotExchange] = useState("");
  const [botExchangeToken, setBotExchangeToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if we are currently submitting
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteBotId, setDeleteBotId] = useState(null);

  const handleDeleteBotClick = (botId) => {
    setDeleteBotId(botId);
    setDeleteModal(true);
  };

  const dispatch = useDispatch();
  const { botData, loading, error, availableExchanges } = useSelector(
    (state) => state.BotReducer
  );

  useEffect(() => {
    dispatch(fetchBotData());
    dispatch(fetchAvailableExchangeDataRequest());
  }, [dispatch]);

  // Function to handle bot deletion
  // const handleDeleteBot = botId => {
  //   // Dispatch the deleteBot action with the bot's ID
  //   dispatch(deleteBot(botId))
  // }
  const navigate = useNavigate();

  const deleteSuccess = useSelector((state) => state.BotReducer.deleteSuccess);

  const handleDeleteBot = (botId) => {
    // Dispatch the deleteBot action with the bot's ID
    dispatch(deleteBot(botId));
  };

  // Place this useEffect in the same component or hook managing the deletion
  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Bot successfully deleted!", {
        onClose: () => {
          // Once the toast is closed, redirect to the bots overview page
          navigate("/bots-overview");
        },
        autoClose: 2000, // close after 2 seconds
      });

      // Dispatch reset action after a short delay to ensure it doesn't interfere with the toast
      setTimeout(() => {
        dispatch(resetDeleteSuccess());
      }, 2000);
    }
  }, [deleteSuccess, navigate, dispatch]);

  // Reset bot form fields and submission state
  const resetForm = () => {
    setBotName("");
    setBotMode("");
    setBotType("");
    setBotExchange("");
    setBotExchangeToken("");
    setIsSubmitting(false);
  };

  // Function to handle opening and closing the modal
  const toggleModal = () => {
    setModal(!modal);

    // If closing the modal, reset the active tab and form fields
    if (modal) {
      setActiveTab(1);
      resetForm();
    }
  };

  // Define the handleSubmit function
  const handleSubmit = async () => {
    // Implement your submit logic here. For example, dispatch an action:
    if (botName && botExchange && botExchangeToken && botMode) {
      // Ensure required fields are filled
      try {
        // Wait for the createBot action to complete
        await dispatch(
          createBot({
            name: botName,
            exchange: botExchange,
            exchangeToken: botExchangeToken,
            mode: botMode,
            type: botType,
          })
        );

        // Fetch the updated list of bots
        dispatch(fetchBotData());

        // Reset form fields and active tab
        resetForm();
        setActiveTab(1); // Reset to the first tab

        // Close the modal after submission
        setModal(false);
      } catch (error) {
        // Handle any errors from the bot creation process
        console.error("Error creating bot:", error);
      }
    } else {
      // Handle the case where required fields are missing
      console.error("Required fields are missing");
    }
  };

  // Handle switching tabs within the modal
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bots" breadcrumbItem="Bot Overview" />
          {loading && <div>Loading...</div>}
          {error && <div>Error: {String(error)}</div>}
          {!loading && !error && (
            <Row>
              <Col md={4} className="border card border-primary">
                <Card body onClick={toggleModal} className="external-event">
                  <CardBody
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      className="rounded-circle avatar-md mt-4"
                      src={newBotImg}
                      alt="New Bot"
                    />
                  </CardBody>
                </Card>
              </Col>

              {botData && botData.length > 0
                ? botData.map((bot, index) => {
                    if (!bot || !bot.fields) {
                      // Ensure bot and bot.fields are defined
                      return (
                        <div key={index}>
                          Bot data is incomplete or not yet loaded.
                        </div>
                      );
                    }

                    const exchangeLogo =
                      bot.fields.bot_exchange === "bybit"
                        ? BybitLogo
                        : bot.fields.bot_exchange === "binance"
                        ? BinanceLogo
                        : bot.fields.bot_exchange === "bitget"
                        ? BitgetLogo
                        : bot.fields.bot_exchange === "blofin"
                        ? BloFinLogo
                        : null; // Fallback value if no matches

                    return (
                      <Col xl="4" sm="6" key={index}>
                        <Card className="text-center">
                          <CardBody>
                            {null != null ? (
                              <div className="avatar-sm mx-auto mb-4">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-primary-subtle bg-" +
                                    "blue" +
                                    " text-" +
                                    "blue" +
                                    " font-size-16"
                                  }
                                ></span>
                              </div>
                            ) : (
                              <div className="mb-1">
                                <img
                                  className="rounded-circle avatar-md"
                                  src={
                                    bot.fields.bot_exchange === "bybit"
                                      ? BybitLogo
                                      : bot.fields.bot_exchange === "binance"
                                      ? BinanceLogo
                                      : bot.fields.bot_exchange === "bitget"
                                      ? BitgetLogo
                                      : bot.fields.bot_exchange === "blofin"
                                      ? BloFinLogo
                                      : null // Fallback value if no matches
                                  }
                                  alt={bot.fields.bot_exchange}
                                />
                              </div>
                            )}

                            <h5 className="font-size-15 mb-1">
                              <Link to="#" className="text-dark">
                                {bot.fields.bot_name}
                              </Link>
                            </h5>
                            <p className="text-muted">
                              {bot.fields.bot_exchange}
                            </p>

                            <div>
                              <Link
                                to="#"
                                className="badge-soft-success font-size-14 me-1 badge bg-secondary"
                              >
                                {bot.fields.bot_trading_mode}
                              </Link>
                            </div>
                          </CardBody>
                          <CardFooter className="bg-transparent border-top">
                            <div className="contact-links d-flex font-size-20">
                              <div className="flex-fill">
                                <Link
                                  to={`/bots/${bot.fields.bot_id}`}
                                  id={`message${bot.fields.bot_id}`}
                                >
                                  <i className="bx bx-pie-chart-alt" />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`message${bot.fields.bot_id}`}
                                  >
                                    View Bot
                                  </UncontrolledTooltip>
                                </Link>
                              </div>
                              <Modal
                                className="modal-dialog modal-dialog-centered"
                                isOpen={deleteModal}
                                toggle={() => setDeleteModal(!deleteModal)}
                              >
                                <ModalHeader
                                  toggle={() => setDeleteModal(!deleteModal)}
                                >
                                  Confirm Delete
                                </ModalHeader>
                                <ModalBody>
                                  Deleting this bot will terminate all its
                                  active webhooks and cannot be undone. Are you
                                  certain you wish to proceed with the deletion
                                  of this bot?
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="secondary"
                                    onClick={() => setDeleteModal(false)}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      dispatch(deleteBot(deleteBotId));
                                      setDeleteModal(false);
                                    }}
                                  >
                                    Yes, Delete
                                  </Button>
                                </ModalFooter>
                              </Modal>
                              <div className="flex-fill">
                                <Link
                                  to="#"
                                  id={"deleteBot" + bot.fields.bot_id}
                                  onClick={() =>
                                    handleDeleteBotClick(bot.fields.bot_id)
                                  }
                                >
                                  <i className="bx bx-trash" />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={"deleteBot" + bot.fields.bot_id}
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </Link>
                              </div>
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>
                    );
                  })
                : null}
            </Row>
          )}
          {/* Modal for bot configuration */}
          <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
            <ModalHeader toggle={toggleModal}>Set Up Your Bot</ModalHeader>
            <ModalBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Form>
                    <FormGroup>
                      <Label for="botName">Bot Name</Label>
                      <Input
                        type="text"
                        name="botName"
                        id="botName"
                        placeholder="Enter Bot Name"
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                      />
                    </FormGroup>
                  </Form>
                </TabPane>
                <TabPane tabId={2}>
                  <Form>
                    <FormGroup>
                      <Label for="botType">Bot Exchange</Label>
                      <Input
                        type="select"
                        name="botExchange"
                        id="botExchange"
                        value={botExchange}
                        onChange={(e) => setBotExchange(e.target.value)}
                      >
                        <option value="">Select Exchange</option>
                        {Array.from(
                          new Set(
                            availableExchanges.map(
                              (exchange) => exchange.split(": ")[0]
                            )
                          )
                        ).map((exchangeName) => {
                          return (
                            <option key={exchangeName} value={exchangeName}>
                              {exchangeName}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Form>
                </TabPane>
                <TabPane tabId={3}>
                  <Form>
                    <FormGroup>
                      <Label for="botExchangeToken">Bot Exchange Token</Label>
                      <Input
                        type="select"
                        name="botExchangeToken"
                        id="botExchangeToken"
                        value={botExchangeToken}
                        onChange={(e) => setBotExchangeToken(e.target.value)}
                      >
                        <option value="">Select Token</option>
                        {availableExchanges.map((exchange, index) => {
                          // Split the string at the last space to separate the token from the rest
                          const lastSpaceIndex = exchange.lastIndexOf(" ");
                          const exchangeNameDescriptor = exchange
                            .substring(0, lastSpaceIndex)
                            .trim();
                          const token = exchange
                            .substring(lastSpaceIndex + 1)
                            .trim();

                          return (
                            <option key={index} value={token}>
                              {`${exchangeNameDescriptor} ${token}`}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Form>
                </TabPane>
                {/* <TabPane tabId={4}>
                  <Form>
                    <FormGroup>
                      <Label for="botMode">Bot Mode</Label>
                      <Input
                        type="select"
                        name="botMode"
                        id="botMode"
                        value={botMode}
                        onChange={(e) => setBotMode(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        <option value="spot">Spot Trading</option>
                        <option value="futures">Futures Trading</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </TabPane> */}
                <TabPane tabId={4}>
                  <Form>
                    <FormGroup>
                      <Label for="botMode">Bot Mode</Label>
                      <Input
                        type="select"
                        name="botMode"
                        id="botMode"
                        value={botMode}
                        onChange={(e) => setBotMode(e.target.value)}
                      >
                        <option value="">Select Option</option>
                        {botExchange !== "blofin" && (
                          <option value="spot">Spot Trading</option>
                        )}
                        <option value="futures">Futures Trading</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </TabPane>
                {/* Conditionally render this TabPane based on botMode */}
                {botMode === "futures" && (
                  <TabPane tabId={5}>
                    <Form>
                      <FormGroup>
                        <Label for="botType">Bot Type</Label>
                        <Input
                          type="select"
                          name="botType"
                          id="botType"
                          value={botType}
                          onChange={(e) => setBotType(e.target.value)}
                        >
                          <option value="">Select Option</option>
                          <option value="hedge">Hedge Mode</option>
                          <option value="one-way">One-Way Mode</option>
                        </Input>
                      </FormGroup>
                    </Form>
                  </TabPane>
                )}

                <TabPane tabId={6}>
                  <h5 className="mb-4 text-center">Bot Overview</h5>
                  <Row>
                    {/* Bot Name */}
                    <Col md={4} className="mb-3">
                      <Card className="text-center h-100 shadow-sm">
                        <CardBody>
                          <h6 className="text-uppercase font-weight-bold">
                            Bot Name
                          </h6>
                          <h5 className="text-primary">
                            {botName || "Not specified"}
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* Bot Exchange */}
                    <Col md={4} className="mb-3">
                      <Card className="text-center h-100 shadow-sm">
                        <CardBody>
                          <h6 className="text-uppercase font-weight-bold">
                            Bot Exchange
                          </h6>
                          <h5 className="text-primary">
                            {botExchange || "Not specified"}
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* Bot Exchange Token*/}
                    <Col md={4} className="mb-3">
                      <Card className="text-center h-100 shadow-sm">
                        <CardBody>
                          <h6 className="text-uppercase font-weight-bold">
                            Bot Exchange Token
                          </h6>
                          <h5 className="text-primary">
                            {botExchangeToken || "Not specified"}
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* Bot Mode */}
                    <Col md={4} className="mb-3">
                      <Card className="text-center h-100 shadow-sm">
                        <CardBody>
                          <h6 className="text-uppercase font-weight-bold">
                            Bot Mode
                          </h6>
                          <h5 className="text-primary">
                            {botMode || "Not specified"}
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* Bot Type */}
                    <Col md={4} className="mb-3">
                      <Card className="text-center h-100 shadow-sm">
                        <CardBody>
                          <h6 className="text-uppercase font-weight-bold">
                            Bot Type
                          </h6>
                          <h5 className="text-primary">
                            {botType || "Not Available on Spot Mode"}
                          </h5>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              <div className="d-flex justify-content-between mt-3">
                {/* Back Button */}
                {activeTab !== 1 && (
                  <Button
                    onClick={() => {
                      // If we're on the Bot Overview tab and the mode is 'spot', go back two tabs to skip Bot Type tab
                      if (activeTab === 6 && botMode !== "futures") {
                        toggleTab(activeTab - 2);
                      } else {
                        toggleTab(activeTab - 1);
                      }
                    }}
                  >
                    Back
                  </Button>
                )}

                {/* Next/Submit Button */}
                {activeTab !== 6 ? (
                  <Button
                    onClick={() => {
                      // If we're on the Bot Mode tab and the mode is not 'futures', skip to the Bot Overview tab
                      if (activeTab === 4 && botMode !== "futures") {
                        toggleTab(6);
                      } else {
                        toggleTab(activeTab + 1);
                      }
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />{" "}
    </React.Fragment>
  );
};

export default BotsOverview;
