import axios from "axios";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

// Checks if user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postregisterUser = (data) => {
  return axios
    .post(url.REGISTER, data)
    .then((response) => response.data)
    .catch((err) => handleError(err));
};

// Login Method
const postloginUser = (data) => {
  return axios
    .post(url.LOGIN, data)
    .then((response) => response.data)
    .catch((err) => handleError(err));
};

// Password Forget Method
const postforgetPassword = (data) => {
  return axios
    .post(url.PASSWORD_FORGET, data)
    .then((response) => response.data)
    .catch((err) => handleError(err));
};

// Password Reset Method
const postresetPassword = (data) => {
  return axios
    .post(url.PASSWORD_RESET, data)
    .then((response) => response.data)
    .catch((err) => handleError(err));
};

// Submit Bybit Api Data
const postbybitapidata = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.BYBITAPIDATASUBMIT; // Assuming url.BYBITAPIDATASUBMIT is your API endpoint

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Submit Bitget Api Data
const postbitgetapidata = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.BITGETAPIDATASUBMIT;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Submit Binance Api Data
const postbinanceapidata = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.BINANCEAPIDATASUBMIT;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Submit BloFin Api Data
const postblofinapidata = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.BLOFINAPIDATASUBMIT;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Create Bot
const postCreateBot = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.BOTCREATION;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch Bot Data
const getBotData = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming the server expects a JSON response
    };

    // Define the API URL for fetching bot data
    const apiUrl = url.BOTDATA; // Replace with your actual API endpoint

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Delete Bot Data
const deleteBotData = async (botId) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.BOTDATA}/${botId}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Get Specific Bot Data
const getSpecificBotData = async (botId) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching specific bot data
    // Append the botId to target the specific bot
    const apiUrl = `${url.BOTSPECIFICDATA}/${botId}`; // Adjust the endpoint as needed
    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch API Data
const fetchApiData = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.APIDATA; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Delete API Data
const deleteApiData = async (secToken) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.DELETEAPIDATA}/${secToken}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch Connected API Exchange Data
const fetchAvailableApiData = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.AVAILABLEAPIEXCHANGES; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Place Order
const postPlaceOrder = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.PLACEBOT;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Place Order
const postCloseOrder = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.PLACEBOT;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch Bot Data
const getDashboardData = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming the server expects a JSON response
    };

    // Define the API URL for fetching bot data
    const apiUrl = url.DASHBOARD; // Replace with your actual API endpoint

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch User Info Bot Data
const getUserInfoDashboardData = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming the server expects a JSON response
    };

    // Define the API URL for fetching bot data
    const apiUrl = url.DASHBOARDUSERINFODATA; // Replace with your actual API endpoint

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch API Data
const fetchApiDataBitget = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.APIDATABITGET; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Delete API Data
const deleteApiDataBitget = async (secToken) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.DELETEAPIDATABITGET}/${secToken}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Binance
// Fetch API Data
const fetchApiDataBinance = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.APIDATABINANCE; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Delete API Data
const deleteApiDataBinance = async (secToken) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.DELETEAPIDATABINANCE}/${secToken}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// BloFin
// Fetch API Data
const fetchApiDataBloFin = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.APIDATABLOFIN; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Delete API Data
const deleteApiDataBloFin = async (secToken) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.DELETEAPIDATABLOFIN}/${secToken}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};
// Utility function to handle errors
const handleError = (err) => {
  let message = "An error occurred";
  if (err.response && err.response.status) {
    switch (err.response.status) {
      case 404:
        message = "Sorry! The page you are looking for could not be found";
        break;
      case 500:
        message =
          "Sorry! Something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1] || message;
        break;
    }
  }
  throw new Error(message);
};

// Update Notifications
const updateNotificationReadStatus = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Check if authUserData is available
    if (authUserData) {
      // Extract the JWT token from the "authUser" object
      const jwtToken = authUserData.jwt;

      // Create headers object with the "Authorization" header
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json", // Assuming you are sending JSON data
      };

      // Define the API URL
      const apiUrl = url.NOTIFICATIONSREAD;

      // Send the request with the updated data and headers
      const response = await axios.post(apiUrl, data, { headers });
      // Return the response data
      return response.data;
    } else {
      // Log an error message if authUserData is not available
      return null; // You can handle this case accordingly
    }
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Update Notifications
const updateAllNotificationReadStatus = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Check if authUserData is available
    if (authUserData) {
      // Extract the JWT token from the "authUser" object
      const jwtToken = authUserData.jwt;

      // Create headers object with the "Authorization" header
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json", // Assuming you are sending JSON data
      };

      // Define the API URL
      const apiUrl = url.ALLNOTIFICATIONSREAD;

      // Send the request with the updated data and headers
      const response = await axios.post(apiUrl, data, { headers });
      // Return the response data
      return response.data;
    } else {
      // Log an error message if authUserData is not available
      return null; // You can handle this case accordingly
    }
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

///////

// Create NFTS
const postNfts = async (data) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // const jwtToken = localStorage.getItem("authUser")

    // // Append the JWT token to the data
    data.jwt = authUserData;

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming you are sending JSON data
    };

    // Define the API URL
    const apiUrl = url.NFTSPOST;

    // Send the request with the updated data and headers
    const response = await axios.post(apiUrl, data, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error);
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch NFTS
const getNfts = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json", // Assuming the server expects a JSON response
    };

    // Define the API URL for fetching bot data
    const apiUrl = url.NFTSGET; // Replace with your actual API endpoint

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Delete NFTS
const deleteNfts = async (botId) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for deleting bot data
    // Append the botId to target the specific bot for deletion
    const apiUrl = `${url.NFTSDELETE}/${botId}`; // Replace with your actual API endpoint

    // Send the DELETE request with the headers
    const response = await axios.delete(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Fetch Notificatoins
const getNotifications = async () => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching the data
    const apiUrl = url.NOTIFICATIONS; // Ensure this is the correct endpoint for fetching API data

    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });
    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

// Payments
// const postPayment = async data => {
//   try {
//     // Get the "authUser" object from local storage
//     const authUserData = JSON.parse(localStorage.getItem("authUser"))
//     // // Append the JWT token to the data
//     console.log("aut", authUserData)
//     data.jwt = authUserData
//     console.log("s", authUserData)
//     // Extract the JWT token from the "authUser" object
//     const jwtToken = authUserData.jwt
//     console.log("apiUrl", jwtToken)
//     // Create headers object with the "Authorization" header
//     const headers = {
//       Authorization: `Bearer ${jwtToken}`,
//       "Content-Type": "application/json", // Assuming you are sending JSON data
//     }

//     // Define the API URL
//     const apiUrl = url.PAYMENT
//     console.log("apiUrl", apiUrl)
//     // Send the request with the updated data and headers
//     const response = await axios.post(apiUrl, data, { headers })

//     // Return the response data
//     return response.data
//   } catch (error) {
//     // Handle any errors here
//     handleError(error)
//     throw error // Rethrow the error for the caller to handle if needed
//   }
// }
const postPayment = async (data) => {
  try {
    const authUserDataString = localStorage.getItem("authUser");
    if (!authUserDataString) {
      throw new Error("No auth user data found in local storage.");
    }
    const authUserData = JSON.parse(authUserDataString);

    // Assuming you want to send the JWT token in the request headers rather than in the body
    const jwtToken = authUserData.jwt;
    if (!jwtToken) {
      throw new Error("JWT token is missing in auth user data.");
    }

    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Ensure url.PAYMENT is correctly defined and accessible
    const apiUrl = url.PAYMENT;

    // Make the POST request with Axios
    const response = await axios.post(apiUrl, JSON.stringify(data), {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error; // Ensure the error is rethrown only if you want calling code to handle it further
  }
};

const postBotAdvanceSettings = async (data) => {
  try {
    const authUserDataString = localStorage.getItem("authUser");
    if (!authUserDataString) {
      throw new Error("No auth user data found in local storage.");
    }
    const authUserData = JSON.parse(authUserDataString);

    // Assuming you want to send the JWT token in the request headers rather than in the body
    const jwtToken = authUserData.jwt;
    if (!jwtToken) {
      throw new Error("JWT token is missing in auth user data.");
    }

    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Ensure url.PAYMENT is correctly defined and accessible
    const apiUrl = url.SAVEBOTADVANCESETTINGS;

    // Make the POST request with Axios
    const response = await axios.post(apiUrl, JSON.stringify(data), {
      headers,
    });

    return response.data;
  } catch (error) {
    throw error; // Ensure the error is rethrown only if you want calling code to handle it further
  }
};

// Get Specific Bot Data
const getSpecificBotAdvanceSettingsData = async (botId) => {
  try {
    // Get the "authUser" object from local storage
    const authUserData = JSON.parse(localStorage.getItem("authUser"));

    // Extract the JWT token from the "authUser" object
    const jwtToken = authUserData.jwt;

    // Create headers object with the "Authorization" header
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Define the API URL for fetching specific bot data
    // Append the botId to target the specific bot
    const apiUrl = `${url.FETCHBOTADVANCESETTINGS}/${botId}`; // Adjust the endpoint as needed
    // Send the GET request with the headers
    const response = await axios.get(apiUrl, { headers });

    // Return the response data (or true/false/status based on your API's response structure)
    return response.data;
  } catch (error) {
    // Handle any errors here
    handleError(error); // Ensure handleError is defined to manage errors
    throw error; // Rethrow the error for the caller to handle if needed
  }
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postregisterUser,
  postloginUser,
  postforgetPassword,
  postresetPassword,
  postbybitapidata,
  postCreateBot,
  getBotData,
  deleteBotData,
  getSpecificBotData,
  fetchApiData,
  deleteApiData,
  fetchAvailableApiData,
  postPlaceOrder,
  postCloseOrder,
  getDashboardData,
  getUserInfoDashboardData,
  postbitgetapidata,
  fetchApiDataBitget,
  deleteApiDataBitget,
  postbinanceapidata,
  fetchApiDataBinance,
  deleteApiDataBinance,
  postblofinapidata,
  fetchApiDataBloFin,
  deleteApiDataBloFin,
  updateNotificationReadStatus,
  getNotifications,
  updateAllNotificationReadStatus,
  postNfts,
  getNfts,
  deleteNfts,
  postPayment,
  postBotAdvanceSettings,
  getSpecificBotAdvanceSettingsData,
};
