import {
  GET_NFTS_SUCCESS,
  GET_NFTS_FAIL,
  ADD_NFTS_SUCCESS,
  ADD_NFTS_FAIL,
  DELETE_NFTS_SUCCESS,
  DELETE_NFTS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  nfts: [],
  error: {},
  loading: true,
}

const nftsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NFTS_SUCCESS:
      return {
        ...state,
        nfts: action.payload.nfts, // Access the nfts array inside the payload
      }

    case GET_NFTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NFTS_SUCCESS:
      return {
        ...state,
        nfts: [...state.nfts, action.payload],
      }

    case ADD_NFTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NFTS_SUCCESS:
      return {
        ...state,
        nfts: state.nfts.filter(
          nfts => nfts.id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_NFTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default nftsReducer
