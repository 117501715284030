import {
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
} from "./actionTypes"

const INIT_STATE = {
  settings: {}, // Holds the current settings
  loading: false, // Tracks whether settings are being saved
  error: null, // Any error that occurred during saving
}

// Reducer
const botAdvanceSettingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_SETTINGS_REQUEST:
      return { ...state, loading: true, error: null }

    case FETCH_SETTINGS_REQUEST: // Handle loading state for fetching settings
      return { ...state, loading: true, error: null }

    case FETCH_SETTINGS_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
      const { botId, settings } = action.payload
      // Update or set the settings for the specific botId
      return {
        ...state,
        settings: { ...state.settings, [botId]: settings }, // Assuming multiple bots could have settings
        loading: false,
      }

    case SAVE_SETTINGS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case FETCH_SETTINGS_FAILURE: // Handle failure state for fetching settings
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default botAdvanceSettingsReducer
