// BloFin
// Action Types
export const RESET_SUBMIT_BLOFIN_API_DATA = "RESET_SUBMIT_BLOFIN_API_DATA";
export const SUBMIT_BLOFIN_API_DATA = "SUBMIT_BLOFIN_API_DATA";
export const SUBMIT_BLOFIN_API_DATA_SUCCESS = "SUBMIT_BLOFIN_API_DATA_SUCCESS";
export const SUBMIT_BLOFIN_API_DATA_FAIL = "SUBMIT_BLOFIN_API_DATA_FAIL";
export const FETCH_API_DATA_BLOFIN = "FETCH_API_DATA_BLOFIN";
export const FETCH_API_DATA_SUCCESS_BLOFIN = "FETCH_API_DATA_SUCCESS_BLOFIN";
export const FETCH_API_DATA_FAIL_BLOFIN = "FETCH_API_DATA_FAIL_BLOFIN";
export const DELETE_API_DATA_BLOFIN = "DELETE_API_DATA_BLOFIN";
export const DELETE_API_DATA_SUCCESS_BLOFIN = "DELETE_API_DATA_SUCCESS_BLOFIN";
export const DELETE_API_DATA_FAIL_BLOFIN = "DELETE_API_DATA_FAIL_BLOFIN";

// BloFin

/**
 * Action to submit Bybit API data.
 * @param {Object} data - The API data to be submitted.
 */
export const submitBloFinApiData = (data) => ({
  type: SUBMIT_BLOFIN_API_DATA,
  payload: data,
});

/**
 * Action to be dispatched when Bybit API data submission succeeds.
 * @param {Object} response - The response data from the submission success.
 */
export const submitBloFinApiDataSuccess = (response) => ({
  type: SUBMIT_BLOFIN_API_DATA_SUCCESS,
  payload: response,
});

export const resetSubmitBloFinApiDataSuccess = () => ({
  type: RESET_SUBMIT_BLOFIN_API_DATA,
});

/**
 * Action to be dispatched when Bybit API data submission fails.
 * @param {string} error - The error message or object from the submission failure.
 */
export const submitBloFinApiDataFail = (error) => ({
  type: SUBMIT_BLOFIN_API_DATA_FAIL,
  payload: error,
});

// actions.js
export const fetchApiDataBloFin = () => ({
  type: FETCH_API_DATA_BLOFIN,
});

export const fetchApiDataSuccessBloFin = (data) => ({
  type: FETCH_API_DATA_SUCCESS_BLOFIN,
  payload: data,
});

export const fetchApiDataFailBloFin = (error) => ({
  type: FETCH_API_DATA_FAIL_BLOFIN,
  payload: error,
});

// Handles successful deletion of the API data
export const deleteApiDataBloFin = (secToken) => ({
  type: DELETE_API_DATA_BLOFIN,
  payload: secToken,
});

// Handles successful deletion of the API data
export const deleteApiDataSuccessBloFin = (apiData) => ({
  type: DELETE_API_DATA_SUCCESS_BLOFIN,
  payload: apiData,
});

// Handles failure in deleting the API data with an error message or object
export const deleteApiDataFailBloFin = (error) => ({
  type: DELETE_API_DATA_FAIL_BLOFIN,
  payload: error,
});
