import { call, put, takeEvery } from "redux-saga/effects"
import {
  SUBMIT_BINANCE_API_DATA,
  FETCH_API_DATA_BINANCE,
  DELETE_API_DATA_BINANCE,
} from "./actionTypes"
import {
  submitBinanceApiDataSuccess,
  submitBinanceApiDataFail,
  fetchApiDataSuccessBinance,
  fetchApiDataFailBinance,
  deleteApiDataSuccessBinance,
  deleteApiDataFailBinance,
} from "./actions" // Update this path if the actions are defined elsewhere
import {
  postbinanceapidata,
  fetchApiDataBinance,
  deleteApiDataBinance,
} from "../../helpers/backend_helper" // Ensure these functions are exported from backend_helper
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
/**
 * Worker saga to handle the submission of Bybit API data.
 * @param {Object} action - The action object containing payload data.
 */
function* submitBinanceApiDataSaga({ payload: data }) {
  try {
    const response = yield call(postbinanceapidata, data)

    if (response && response.success) {
      yield put(submitBinanceApiDataSuccess(response.data))
    } else {
      const errorMessage =
        response.message || "Binance API Data Submission Failed"
      yield put(submitBinanceApiDataFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(submitBinanceApiDataFail(errorText))
    toast.error(errorText || "Binance API Data Submission Failed", {
      autoClose: 2000,
    })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* fetchApiDataBinanceSaga() {
  try {
    const response = yield call(fetchApiDataBinance) // Makes the API request
    if (response && response.success) {
      // Use response.api_data instead of response.data
      yield put(fetchApiDataSuccessBinance(response.api_data))
    } else {
      const errorMessage = response.message || "Failed to fetch API data"
      yield put(fetchApiDataFailBinance(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(fetchApiDataFailBinance(errorText))
    toast.error(errorText || "Failed to fetch API data", { autoClose: 2000 })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* deleteApiDataBinanceSaga({ payload: secToken }) {
  try {
    const response = yield call(deleteApiDataBinance, secToken)
    if (response && response.success) {
      yield put(deleteApiDataSuccessBinance(secToken))
    } else {
      const errorMessage = response.message || "Bot deletion failed"
      yield put(deleteApiDataFailBinance(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(deleteApiDataFailBinance(errorText))
    toast.error(errorText || "Bot deletion failed", { autoClose: 2000 })
  }
}

/**
 * Watcher saga that spawns new worker sagas for each action type.
 */
function* binanceApiDataSaga() {
  yield takeEvery(SUBMIT_BINANCE_API_DATA, submitBinanceApiDataSaga)
  yield takeEvery(FETCH_API_DATA_BINANCE, fetchApiDataBinanceSaga)
  yield takeEvery(DELETE_API_DATA_BINANCE, deleteApiDataBinanceSaga)
}

export default binanceApiDataSaga
