import {
  SUBMIT_BLOFIN_API_DATA,
  SUBMIT_BLOFIN_API_DATA_SUCCESS,
  SUBMIT_BLOFIN_API_DATA_FAIL,
  RESET_SUBMIT_BLOFIN_API_DATA,
  FETCH_API_DATA_BLOFIN,
  FETCH_API_DATA_SUCCESS_BLOFIN,
  FETCH_API_DATA_FAIL_BLOFIN,
  DELETE_API_DATA_BLOFIN,
  DELETE_API_DATA_SUCCESS_BLOFIN,
  DELETE_API_DATA_FAIL_BLOFIN,
} from "./actionTypes";

const INIT_STATE = {
  blofinApiData: [],
  fetchedApiData: null, // Holds the fetched data
  error: null,
  loading: false,
  success: false,
};

const blofinApiDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SUBMIT_BLOFIN_API_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
      };

    case SUBMIT_BLOFIN_API_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };

    case SUBMIT_BLOFIN_API_DATA_SUCCESS:
      return {
        ...state,
        blofinApiData: [...state.blofinApiData, action.payload],
        loading: false,
        error: null,
        success: true,
      };

    case SUBMIT_BLOFIN_API_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };

    case FETCH_API_DATA_BLOFIN:
      return {
        ...state,
        loading: true, // Set loading true when fetch starts
      };

    case FETCH_API_DATA_SUCCESS_BLOFIN:
      return {
        ...state,
        fetchedData: action.payload, // Ensure this matches the structure you're expecting
        loading: false,
        error: null,
      };

    case FETCH_API_DATA_FAIL_BLOFIN:
      return {
        ...state,
        fetchedData: null, // Clear the previous data when there's a failure
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      };

    case DELETE_API_DATA_BLOFIN:
      return {
        ...state,
        loading: true, // Set loading true when delete starts
      };

    case DELETE_API_DATA_SUCCESS_BLOFIN:
      const updatedBloFinApiData = state.fetchedData.filter(
        (item) => item && item.security_token !== action.payload
      );

      return {
        ...state,
        fetchedData: updatedBloFinApiData, // Update blofinApiData with the filtered array
        loading: false,
        error: null,
      };

    case DELETE_API_DATA_FAIL_BLOFIN:
      return {
        ...state,
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      };

    default:
      return state;
  }
};

export default blofinApiDataReducer;
