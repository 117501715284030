import React, { Fragment } from "react"
import { Navbar, Nav, Button, Container } from "reactstrap" // Assuming you're using Reactstrap
import logo from "../../../assets/images/logo-dark.png"
import { Link, useNavigate } from "react-router-dom"

import "./navbar.css"
const NavbarHomepage = () => {
  return (
    <Navbar expand="md">
      <Container>
        <Nav className="navbar">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="navbar-right">
            <Link className="sign-in" to="/dashboard">
              Sign In
            </Link>
            <Link className="get-started" to="/register">
              <span>Get Started</span>
              <i className="bx bx-right-arrow-alt"></i>
            </Link>
          </div>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default NavbarHomepage
