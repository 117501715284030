export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS"
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS"
export const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"
export const UPDATE_ALL_NOTIFICATIONS = "UPDATE_ALL_NOTIFICATIONS"
export const UPDATE_ALL_NOTIFICATIONS_SUCCESS =
  "UPDATE_ALL_NOTIFICATIONS_SUCCESS"
export const UPDATE_ALL_NOTIFICATIONS_FAIL = "UPDATE_ALL_NOTIFICATIONS_FAIL"
