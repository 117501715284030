import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_NFTS, ADD_NEW_NFTS, DELETE_NFTS } from "./actionTypes"
import {
  fetchUserInfoDashboardSuccess,
  fetchUserInfoDashboardFailure,
} from "../dashboard/actions"

import {
  getNftsSuccess,
  getNftsFail,
  addNftsFail,
  addNftsSuccess,
  deleteNftsSuccess,
  deleteNftsFail,
} from "./actions"

// Update these helper functions according to your NFTs logic
import {
  getNfts,
  postNfts,
  deleteNfts,
  getUserInfoDashboardData,
} from "../../helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchNfts() {
  try {
    const response = yield call(getNfts)
    console.log(response)
    yield put(getNftsSuccess(response))
  } catch (error) {
    yield put(getNftsFail(error))
  }
}

function* onDeleteNfts({ payload: nfts }) {
  try {
    const response = yield call(deleteNfts, nfts)
    yield put(deleteNftsSuccess(response))
    toast.success("NFTs Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteNftsFail(error))
    toast.error("NFTs Delete Failed", { autoClose: 2000 })
  }
}

function* onAddNewNfts({ payload: nfts }) {
  try {
    const response = yield call(postNfts, nfts)
    yield put(addNftsSuccess(response))
    toast.success("Wallet Connected Successfully", { autoClose: 2000 })

    // After adding a new NFT, fetch the updated list
    yield put({ type: GET_NFTS })
    const responseInfo = yield call(getUserInfoDashboardData, nfts)
    // If the response indicates success, dispatch a success action with the data
    if (responseInfo?.success) {
      yield put(fetchUserInfoDashboardSuccess(responseInfo))
      // Pass the actual data you want in your state
    } else {
      // If the response indicates failure, dispatch a failure action with the message
      yield put(fetchUserInfoDashboardFailure("Failed to fetch dashboard data"))
      toast.error("Failed to fetch dashboard data")
    }
  } catch (error) {
    yield put(addNftsFail(error))
    toast.error("Wallet Connection Failed", { autoClose: 2000 })
  }
}

function* nftsSaga() {
  yield takeEvery(GET_NFTS, fetchNfts)
  yield takeEvery(ADD_NEW_NFTS, onAddNewNfts)
  yield takeEvery(DELETE_NFTS, onDeleteNfts)
}

export default nftsSaga
