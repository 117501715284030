// actions.js

export const BotActionTypes = {
  RESET_CREATE: "RESET_CREATE_BOT",
  CREATE_REQUEST: "CREATE_BOT",
  CREATE_SUCCESS: "CREATE_BOT_SUCCESS",
  CREATE_FAILURE: "CREATE_BOT_FAIL",
  FETCH_REQUEST: "FETCH_BOT",
  FETCH_SUCCESS: "FETCH_BOT_SUCCESS",
  FETCH_FAILURE: "FETCH_BOT_FAIL",
  DELETE_REQUEST: "DELETE_BOT",
  DELETE_SUCCESS: "DELETE_BOT_SUCCESS",
  DELETE_FAILURE: "DELETE_BOT_FAIL",
  RESET_DELETE_SUCCESS: "RESET_DELETE_SUCCESS",
  FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST:
    "FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST",
  FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS:
    "FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS",
  FETCH_AVAILABLE_EXCHANGE_DATA_FAIL: "FETCH_AVAILABLE_EXCHANGE_DATA_FAIL",
}

// Resets the bot creation state to its initial state
export const resetCreateBot = () => ({ type: BotActionTypes.RESET_CREATE })

// Initiates the bot creation process with given data
export const createBot = data => ({
  type: BotActionTypes.CREATE_REQUEST,
  payload: data,
})

export const createBotSuccess = botData => ({
  type: BotActionTypes.CREATE_SUCCESS,
  payload: botData,
})

export const createBotFail = errorMessage => ({
  type: BotActionTypes.CREATE_FAILURE,
  payload: errorMessage,
})
// Initiates the process of fetching bot data
export const fetchBotData = () => ({ type: BotActionTypes.FETCH_REQUEST })

// Handles successful fetching of bot data
export const fetchBotDataSuccess = data => ({
  type: BotActionTypes.FETCH_SUCCESS,
  payload: data,
})

// Handles failure in fetching bot data with an error message or object
export const fetchBotDataFail = error => ({
  type: BotActionTypes.FETCH_FAILURE,
  payload: { error },
})

// Initiates the process of deleting a bot with a given ID
export const deleteBot = botId => ({
  type: BotActionTypes.DELETE_REQUEST,
  payload: botId,
})

// Handles successful deletion of the bot
export const deleteBotSuccess = botId => ({
  type: BotActionTypes.DELETE_SUCCESS,
  payload: botId,
})

// Handles failure in deleting the bot with an error message or object
export const deleteBotFail = error => ({
  type: BotActionTypes.DELETE_FAILURE,
  payload: { error },
})

export const resetDeleteSuccess = () => ({
  type: BotActionTypes.RESET_DELETE_SUCCESS,
})

// Action to initiate fetching available exchange data
export const fetchAvailableExchangeDataRequest = () => ({
  type: BotActionTypes.FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST,
})

// Action for successful fetching of available exchange data
export const fetchAvailableExchangeDataSuccess = data => ({
  type: BotActionTypes.FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS,
  payload: data,
})

// Action for failure in fetching available exchange data with an error message or object
export const fetchAvailableExchangeDataFail = error => ({
  type: BotActionTypes.FETCH_AVAILABLE_EXCHANGE_DATA_FAIL,
  payload: { error },
})
