import {
  SUBMIT_BINANCE_API_DATA,
  SUBMIT_BINANCE_API_DATA_SUCCESS,
  SUBMIT_BINANCE_API_DATA_FAIL,
  RESET_SUBMIT_BINANCE_API_DATA,
  FETCH_API_DATA_BINANCE,
  FETCH_API_DATA_SUCCESS_BINANCE,
  FETCH_API_DATA_FAIL_BINANCE,
  DELETE_API_DATA_BINANCE,
  DELETE_API_DATA_SUCCESS_BINANCE,
  DELETE_API_DATA_FAIL_BINANCE,
} from "./actionTypes"

const INIT_STATE = {
  binanceApiData: [],
  fetchedApiData: null, // Holds the fetched data
  error: null,
  loading: false,
  success: false,
}

const binanceApiDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SUBMIT_BINANCE_API_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
      }

    case SUBMIT_BINANCE_API_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      }

    case SUBMIT_BINANCE_API_DATA_SUCCESS:
      return {
        ...state,
        binanceApiData: [...state.binanceApiData, action.payload],
        loading: false,
        error: null,
        success: true,
      }

    case SUBMIT_BINANCE_API_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      }

    case FETCH_API_DATA_BINANCE:
      return {
        ...state,
        loading: true, // Set loading true when fetch starts
      }

    case FETCH_API_DATA_SUCCESS_BINANCE:
      return {
        ...state,
        fetchedData: action.payload, // Ensure this matches the structure you're expecting
        loading: false,
        error: null,
      }

    case FETCH_API_DATA_FAIL_BINANCE:
      return {
        ...state,
        fetchedData: null, // Clear the previous data when there's a failure
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      }

    case DELETE_API_DATA_BINANCE:
      return {
        ...state,
        loading: true, // Set loading true when delete starts
      }

    case DELETE_API_DATA_SUCCESS_BINANCE:
      const updatedBinanceApiData = state.fetchedData.filter(
        item => item && item.security_token !== action.payload
      )

      return {
        ...state,
        fetchedData: updatedBinanceApiData, // Update binanceApiData with the filtered array
        loading: false,
        error: null,
      }

    case DELETE_API_DATA_FAIL_BINANCE:
      return {
        ...state,
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      }

    default:
      return state
  }
}

export default binanceApiDataReducer
