// export default Indicators
import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./indicators.css"
import algoimg from "../../../assets/images/crypto/indicators/crodl-algo.svg"
import emasimg from "../../../assets/images/crypto/indicators/crodl-emas.svg"
import chartmasterimg from "../../../assets/images/crypto/indicators/crodl-chart-master.svg"
import algotraderimg from "../../../assets/images/crypto/indicators/crodl-alog-trader.svg"
import pumpdumpimg from "../../../assets/images/crypto/indicators/crodl-pump-dump.svg"
import autotraderimg from "../../../assets/images/crypto/indicators/crodl-auto-trader.svg"
import algoab from "../../../assets/images/crypto/indicators/crodl-alog-a-b.svg"
import trendcounterimg from "../../../assets/images/crypto/indicators/crodl-trend-counter.svg"
import oscillatorimg from "../../../assets/images/crypto/indicators/crodl-oscillator.svg"
import motherlodeimg from "../../../assets/images/crypto/indicators/crodl-motherlode.svg"
import ezimg from "../../../assets/images/crypto/indicators/crodl-ex-signal.svg"
import scalperv3 from "../../../assets/images/crypto/indicators/crodl-scalper-v3.svg"
import breakoutimg from "../../../assets/images/crypto/indicators/crodl-breakout-indciator.svg"
import botv2img from "../../../assets/images/crypto/indicators/crodl-bot-v2.svg"
import movingavgimg from "../../../assets/images/crypto/indicators/crodl-ma.svg"
import volumeimg from "../../../assets/images/crypto/indicators/crodl-volume-detector.svg"
import candyimg from "../../../assets/images/crypto/indicators/crodl-candy-mountain.svg"
import orcaimg from "../../../assets/images/crypto/indicators/crodl-orca-indicator.svg"
import trendimg from "../../../assets/images/crypto/indicators/crodl-trend-detector.svg"
import strengthimg from "../../../assets/images/crypto/indicators/crodl-strength-meter.svg"
import vpvrimg from "../../../assets/images/crypto/indicators/crodl-vpvr.svg"
import scalperv2 from "../../../assets/images/crypto/indicators/crodl-scalper-v2.svg"
import rsiimg from "../../../assets/images/crypto/indicators/crodl-rsi.svg"
import genesisimg from "../../../assets/images/crypto/indicators/crodl-genesis.svg"
import levelsimg from "../../../assets/images/crypto/indicators/crodl-levels.svg"

import { Container } from "reactstrap"

const crodlAlgoIndicators = [
  {
    title: "Crodl Algo",
    image: algoimg,
    description:
      "Crodl's Algo Indicator, a trading tool, offers two algorithms: Algo uses ribbon crosses for entry signals and Crodl Oscillator for volume. Algo 1, with EMA/MA ribbons, is more cautious. It features trend detection, pullback functions in beta, volume detection with color-coded candles, smart money analysis, support/resistance visualization, risk-reward management, and TradingView Supertrend integration. Suitable for various markets, it aids in efficient trading strategy enhancement.",
  },
  {
    title: "Crodl EMA's",
    image: emasimg,
    description:
      "Crodl EMA's indicator, a market analysis tool, integrates RSI and EMA of RSI for trend strength and entry points, with alerts for RSI/EMA crossings signaling market shifts. It employs ADX for trend analysis and MACD for momentum detection, indicating buy/sell signals based on MACD thresholds. Features include fixed and Trailing Stop Loss for risk management, EMA crossovers for additional buy/sell signals, and comprehensive alerts for entry/exit points. This tool enhances trading with insights into market trends.",
  },
  {
    title: "Crodl Chart Master",
    image: chartmasterimg,
    description:
      "he Crodl Chart Master is a versatile technical analysis tool combining RSI, EMA, ADX, and MACD indicators. It generates alerts on RSI-EMA crossovers, signaling trend shifts. ADX measures trend strength, while MACD identifies momentum changes for buy/sell signals. Traders can set fixed or trailing stop-loss levels for exit strategies. This tool maximizes trading opportunities and minimizes risks.",
  },
  {
    title: "Crodl Algo Trader",
    image: algotraderimg,
    description:
      "This indicator uses advanced algorithms to analyze market trends, historical prices, and factors like trend direction, candlestick patterns, and support/resistance levels. It offers dynamic trailing stops based on previous candle lows, enhancing profit protection. Trend filters prevent trades against the trend for higher success rates. It provides alerts for entry and exit points, aiding timely decision-making. This tool maximizes profits, manages trades effectively, reduces risk, and optimizes outcomes.",
  },
  {
    title: "Crodl Pump & Dump",
    image: pumpdumpimg,
    description:
      "The Pump and Dump indicator relies on volume for entries. In a bullish trend, ATR and price averages determine the entry. After hitting TP or stop loss, multiple long positions are possible. In a bearish trend, increased volume and a level break trigger short entry. Exit options include CrodlExit (ATR-based), Fixed SL (%), or reverse exits. It offers adjustable MA length and sensitivity. Backtesting panel displays statistics for Simple (PC), Mobile (mobile device), or Hide options, with 4 TP targets. It tracks win/loss streaks and trade details.",
  },
  {
    title: "Crodl Auto Trader",
    image: autotraderimg,
    description:
      "This indicator includes Buy and Sell Flags, RSI confirmation for signals, and SMA/EMA crosses for entry points. It offers four predefined Take Profit (TP) targets: 0.5%, 1%, 1.5%, and 2%. Exit strategies encompass Opposite, CrodlExit (ATR-based), or Fixed SL (%). Backtest Data options cover Long/Buy, Short/Sell, or both. Statistics display trade details, win/loss percentages, streaks, and TP achievements. Percentage values are color-coded: Green (>80%), Orange (50-80%), and Red (<50%). The Info panel shows asset, timeframe, total flags, wins/losses, and TP achievements. Alerts are available for TP targets, Buy/Sell flags, and exit strategies. The Info panel can be hidden.",
  },
  {
    title: "Crodl Algo A + B",
    image: algoab,
    description:
      "Crodl Algo B, in tandem with Algo A, offers actionable trade signals. It employs unique Stochastic calculations to identify buying and selling opportunities, analyzing price relationships over time. Algo B generates Buy/Sell signals using Stochastic crosses and trend movements, confirmed by a histogram. Integration with Algo A ensures a comprehensive approach, improving entry and exit timing. This reliable algorithm enhances trading performance and guides decision-making with tested effectiveness.",
  },
  {
    title: "Crodl Trend Counter",
    image: trendcounterimg,
    description:
      "This indicator tracks price/candle duration above/below a selected Moving Average (MA). It provides insights into market dynamics and trend shifts. For example, on a 1-hour timeframe, 5 consecutive candles above the MA indicate a 5-hour upward trend. Customizable MA settings adapt to trader preferences and market conditions. This tool aids trend strength assessment and informed trading decisions, enhancing adaptability for different trading styles and market scenarios",
  },
  {
    title: "Crodl Oscillator",
    image: oscillatorimg,
    description:
      "Crodl's Oscillator uses RMA for wave patterns with lines at 15 (bullish) and -15 (bearish). Crossing 15 signals market strength; dropping below -15 suggests weakness. Reversals can occur above -15 or below 15. Direction Length adjusts MA for speed and volatility. It supports multi-timeframe analysis, syncing data across timeframes by modifying the input (chart/timeframe).",
  },
  {
    title: "Crodl Motherlode",
    image: motherlodeimg,
    description:
      "Crodl's Motherlode Indicator, paired with the Crodl Oscillator, provides two algorithms. The first, employing ribbon crosses with oscillator and volume for flags, allows faster trades. The second, the default option, offers slower trades with ribbons (EMA's & MA's) for trend identification and Crodl Oscillator for confirmation.Trend Detection, an included feature, employs Crodl Oscillator differently in both algorithms, with adjustable inputs via direction length, and serves as a visual guide.",
  },
  {
    title: "Crodl EZ Signals",
    image: ezimg,
    description:
      "EZ Signals simplifies Buy/Sell signals and optimizes risk-reward ratios. It highlights the value of higher timeframes for trend analysis, offering a broader perspective. By incorporating this data, traders gain clarity on the prevailing trend and improve lower timeframe entries. It focuses on manageable risk-reward ratios, ensuring potential rewards outweigh risks. Customizable Moving Average settings align the indicator with trading strategies. EZ Signals also displays Support and Resistance Levels, aiding key area identification for trading decisions. In summary, EZ Signals streamlines trading processes, enhances risk management, and optimizes trading outcomes.",
  },
  {
    title: "Crodl Scalper V3",
    image: scalperv3,
    description:
      "This advanced Buy/Sell indicator offers traders a comprehensive toolset for informed decisions, enhancing signal accuracy and reliability. It features pre-signal candle alerts to reduce repainting risks and requires confirmation for buy/sell signals, minimizing false entries. Multiple reversal points serve as early indicators without immediate action, avoiding false alarms. Traders can use these points for partial exits, securing profits while staying exposed to potential price movements. In summary, this indicator empowers traders with robust tools to improve accuracy, reduce false signals, and make well-informed decisions, potentially increasing overall profitability.",
  },
  {
    title: "Crodl Breakout Indicator",
    image: breakoutimg,
    description:
      "This indicator uses Support and Resistance (S&R) zones to uncover potential market breakouts. Support and Resistance represent key levels with significant buying or selling pressure. By analyzing S&R zones, the indicator identifies critical price levels for potential breakouts. Breakouts occur when prices breach these levels, signaling a shift in buyer-seller balance. Above resistance suggests a bullish breakout; below support indicates a bearish one. This insight aids traders in making informed entry and exit decisions, anticipating trend reversals, and confirming trends. By incorporating S&R zones, the indicator helps traders identify significant market areas and potential price breakouts, enhancing trading opportunities and profitability.",
  },
  {
    title: "Crodl Crypto Bot V2",
    image: botv2img,
    description:
      "This buy/sell indicator enhances trade management with customizable Take Profit (TP) and Stop Loss (SL) parameters, aiding risk control. Traders can set TP to secure gains and SL to limit losses, ensuring a clear risk-reward ratio.Flexibility exists in selecting Long or Short signals to align with preferences and market conditions. Moreover, the Slow Mode option adapts the indicator from scalping to swing trading, catering to different trading styles and timeframes.In summary, this indicator empowers traders with versatile risk management tools, customizable parameters, Long/Short signals, and Slow Mode, optimizing trade setups for various strategies and market conditions.",
  },
  {
    title: "Crodl Moving Average",
    image: movingavgimg,
    description:
      "The Moving Average (MA) indicator simplifies analysis without chart clutter. It overlays MA onto existing indicators for clear trend understanding. A notable feature is its ability to fetch MA data from a specified timeframe, allowing insights into broader market trends. MA smooths price data, identifying overall direction and filtering fluctuations, aiding trend reversal and support/resistance identification. Overlaying MA on other indicators helps assess alignment with price action, facilitating informed decisions. In summary, the MA indicator offers clear trend representation, flexibility for various timeframes, and enhanced analysis, assisting traders in well-informed decisions.",
  },
  {
    title: "Crodl Volume Detector",
    image: volumeimg,
    description:
      "The volume indicator is a valuable tool for market analysis, color-coding bars to convey market sentiment. Green bars, especially large ones, indicate increased buying activity, suggesting a positive sentiment and potential price rise. Red bars signal higher selling activity, indicating a bearish sentiment. It also identifies breakout patterns based on support/resistance levels. Breakouts with high volume generate buy/sell signals, aiding traders in gauging market direction and making informed trading decisions.",
  },
  {
    title: "Crodl Candy Mountain",
    image: candyimg,
    description:
      "This indicator relies on Money Flow, tracking money volume in a security or market to gauge sentiment (bearish or bullish). It's versatile across time frames, with stronger signals on longer ones.It uses green X for potential long entry points (buy opportunities) and red X for potential short entry points (sell opportunities). By incorporating Money Flow, analyzing different time frames, and providing visual cues, this indicator assists traders in identifying favorable long or short opportunities, aiding informed decisions based on money flow dynamics and trend reversals.",
  },
  {
    title: "Crodl Orca",
    image: orcaimg,
    description:
      "This indicator adapts to price movement. In tight ranges, it shows a smaller curve, signaling low volatility. During breakout moves, it takes longer to plot a close, indicating strong momentum.This adaptability helps traders catch significant price swings, filter out minor fluctuations, and avoid false signals in low volatility periods. During strong trends, it keeps traders in the market, maximizing profits and preventing premature exits.In summary, this indicator optimizes entries and exits by adjusting its curve size based on market conditions. It aims to capture substantial price moves and enhance trading performance.",
  },
  {
    title: "Crodl Trend Detector",
    image: trendimg,
    description:
      "This versatile indicator is effective across timeframes, providing insights into buy or sell momentum. A red line crossing over green indicates seller control, signaling potential downtrends. Conversely, a green line over red signifies buyer dominance, suggesting uptrends and buy signals.These crossovers help traders gauge market sentiment and adjust strategies. Its adaptability across timeframes suits intraday, swing, or long-term trading, aiding analysis and informed decisions.In summary, this indicator, suitable for all timeframes, offers clear buy/sell signals through line crossovers, enhancing decision-making across trading styles and timeframes.",
  },
  {
    title: "Crodl Strength Meter",
    image: strengthimg,
    description:
      "The Crodl Strength Meter is a versatile tool providing insights into trend strength and exit points. It excels by gathering data from higher time frames, resulting in robust signals, ideal for multi-timeframe confirmation.Additionally, it integrates with Heikin Ashi, offering a unique perspective on market conditions through smoothed price data. The indicator seamlessly displays this analysis on the current chart, enhancing convenience for informed decisions.In summary, the Crodl Strength Meter streamlines decision-making by offering trend insights and optimal exit signals. Leveraging higher time frame data ensures signal accuracy, and integration with Heikin Ashi provides a distinct market perspective, aiding trading strategies and overall performance.",
  },
  {
    title: "Crodl VPVR",
    image: vpvrimg,
    description:
      "For intraday traders, this indicator spots vital support and resistance levels on price charts, aiding in short-term trading decisions. It focuses on identifying reversal points, especially when prices move away from the Point of Control (POC), which marks the highest trade volume price level. By incorporating this indicator, traders can improve their intraday strategies, anticipating price reversals towards the POC for profitable trades.",
  },
  {
    title: "Crodl Scalper V2",
    image: scalperv2,
    description:
      "Boost RSI's effectiveness with trend indicators like moving averages. They offer a broader market perspective, crucial in sideways markets where RSI alone can be misleading. Trend indicators reveal the prevailing trend's direction and strength.In sideways phases, they help identify the stronger side (bullish or bearish), crucial for informed decisions. Avoid trading against the trend or choose the stronger side to increase profitable trades and reduce risk.In summary, pairing RSI with trend indicators improves trading precision, filters unfavorable trades, and enhances decision-making accuracy.",
  },
  {
    title: "Crodl RSI",
    image: rsiimg,
    description:
      "This improved RSI enhances the original Tradingview RSI with added features.It identifies support and resistance levels within the RSI, aiding in spotting RSI-specific turning points. Additionally, it detects divergences between price and the RSI, providing signals for potential reversals or trend changes.In summary, this enhanced RSI enriches analysis by integrating support/resistance and divergence detection, empowering traders to make better-informed decisions.",
  },
  {
    title: "Crodl Genesis",
    image: genesisimg,
    description:
      "This indicator aids traders in deciding on long or short positions, utilizing algorithms and an Average True Range (ATR) trend filter. It allows for setting stop loss and take profit levels to manage risk. Automation and alerts are available for timely trade execution and updates on market conditions. Customizable flags in the Style Tab cater to individual preferences. Traders can also opt for no stop loss or take profit for alternative risk management or hands-on trade management.    ",
  },
  {
    title: "Crodl Levels",
    image: levelsimg,
    description:
      "Crodl Levels is a robust Support and Resistance indicator. It identifies key price levels where bounces are likely. It also incorporates Long and Short signals, best used together. Entering trades on a double bounce of these levels often yields favorable results.",
  },
]

const Indicators = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".indicator-card")
    let maxHeight = 0

    // Find the tallest card
    cards.forEach(card => {
      if (card.offsetHeight > maxHeight) {
        maxHeight = card.offsetHeight
      }
    })

    // Set all cards to the tallest height
    cards.forEach(card => {
      card.style.height = `${maxHeight}px`
    })
  }, [])
  const [modalInfo, setModalInfo] = useState({ isOpen: false, image: "" })

  const toggleImageModal = image => {
    setModalInfo({ isOpen: !modalInfo.isOpen, image: image })
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Container>
      <div className="indicators-container my-5">
        <div className="row">
          <div className="col">
            <h2 className="indicators-header">Indicators</h2>
            <p className="indicators-subtitle">
              Explore our Tradingview indicators.
            </p>
          </div>
        </div>

        <Slider {...settings}>
          {crodlAlgoIndicators.map((indicator, index) => (
            <div key={index} className="indicator-card">
              <div className="image-container text-center">
                <img
                  src={indicator.image}
                  alt={indicator.title}
                  className="algo-indicator-img"
                  onClick={() => toggleImageModal(indicator.image)}
                />
              </div>
              <div className="card-content">
                <h3 className="card-title">{indicator.title}</h3>
                <p className="card-text">{indicator.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Image Modal */}
      {modalInfo.isOpen && (
        <div className="custom-modal" onClick={() => toggleImageModal("")}>
          <div
            className="custom-modal-content"
            onClick={e => e.stopPropagation()}
          >
            <img
              src={modalInfo.image}
              alt="Indicator"
              className="custom-modal-image"
            />
          </div>
        </div>
      )}
    </Container>
  )
}

export default Indicators
