// actions.js
import {
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
} from "./actionTypes"

export const saveSettingsRequest = (botId, settings) => ({
  type: SAVE_SETTINGS_REQUEST,
  payload: { botId, settings },
})

export const saveSettingsSuccess = (botId, settings) => ({
  type: SAVE_SETTINGS_SUCCESS,
  payload: { botId, settings },
})

export const saveSettingsFailure = error => ({
  type: SAVE_SETTINGS_FAILURE,
  payload: { error },
})

// Fetch settings actions
export const fetchSettingsRequest = botId => ({
  type: FETCH_SETTINGS_REQUEST,
  payload: { botId },
})

export const fetchSettingsSuccess = (botId, settings) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: { botId, settings },
})

export const fetchSettingsFailure = (botId, error) => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: { botId, error },
})
