// actions.js
import { CHECK_TXID, CHECK_TXID_SUCCESS, CHECK_TXID_FAIL } from "./actionTypes"

export const checkTxid = txid => ({
  type: CHECK_TXID,
  payload: { txid: txid },
})

export const checkTxidSuccess = validationResult => ({
  type: CHECK_TXID_SUCCESS,
  payload: validationResult,
})

export const checkTxidFail = error => ({
  type: CHECK_TXID_FAIL,
  payload: error,
})
