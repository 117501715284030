import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Reactstrap components
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  CardText,
  Badge,
  InputGroup,
  Button,
  Label,
  Input,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  UncontrolledTooltip,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
// import { postPlaceOrderRequest } from "../../store/bot/actions"

const SpotForm = ({
  botDetails,
  botBuyName,
  botSellName,
  botData,
  dispatch,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabTrade, setActiveTabTrade] = useState("1");
  const isLoading = useSelector((state) => state.BotSpecificReducer.loading);

  // Local state for closing trades
  const [closeTrade, setCloseTrade] = useState({
    tokenName: "",
    tokenSymbol: "",
    orderType: "market", // default to 'market'
    side: "Select Side", // default to 'close_long'
    quantityType: "dollar", // default to 'dollar'
    quantityValue: "",
  });

  // Refs
  const jsonRefSpot = useRef(null);

  const [copiedUrl, setCopiedUrl] = useState("");

  const copyOpenOrderUrl = () => {
    const button = document.getElementById("copyOrderUrlButton");
    const openOrderUrl = button.getAttribute("data-url");

    navigator.clipboard.writeText(openOrderUrl).then(
      () => {
        // If the text was successfully copied
        toast.success("URL copied to clipboard", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      },
      (err) => {
        // If there was an error copying the text
        toast.error("Failed to copy URL.", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      }
    );
  };

  const copyToClipboard = (ref) => {
    const text = ref.current ? ref.current.innerText : "";
    navigator.clipboard.writeText(text).then(
      () => {
        // If the text was successfully copied
        toast.success("Syntax copied to clipboard", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      },
      (err) => {
        // If there was an error copying the text
        toast.error("Failed to copy syntax.", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      }
    );
  };

  // Handlers for trade form
  const toggleTabTrade = (tab) => {
    if (activeTabTrade !== tab) setActiveTabTrade(tab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCloseTrade({
      ...closeTrade,
      [name]: value,
    });
  };

  // Trade Modal State and Functions
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmitTrade = () => {
    toggleModal();
  };

  const handleConfirmTrade = () => {
    // Close the confirmation modal
    toggleModal();

    // Place the order logic goes here
    const tradeData = {
      bot_details: {
        bot_id: botData.fields.bot_id,
        exchange: botData.fields.bot_exchange,
        trading_type: "spot",
      },
      details: {
        token: botData.fields.bot_exchange_token,
        name,
        symbol,
        orderType,
        side,
        quantity: {
          type: quantityType,
          amount: quantity,
        },
      },
    };

    // Dispatch an action to your saga with the tradeData
    dispatch({
      type: "POST_PLACE_ORDER_REQUEST", // Correct action type
      payload: tradeData,
    });
  };

  // Confirmation Modal State and Functions
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleSubmitCloseTrade = () => {
    // Prepare close trade data
    const closeTradeData = {
      bot_details: {
        bot_id: botData.fields.bot_id,
        exchange: botData.fields.bot_exchange,
        trading_type: "spot",
      },
      details: {
        token: botData.fields.bot_exchange_token,
        name: closeTrade.tokenName,
        symbol: closeTrade.tokenSymbol,
        orderType: closeTrade.orderType,
        side: closeTrade.side,
        quantity: {
          type: closeTrade.quantityType,
          amount: closeTrade.quantityValue,
        },
      },
    };

    // Dispatch an action to your saga with the closeTradeData
    dispatch({
      type: "POST_CLOSE_ORDER_REQUEST", // Correct action type for closing trades
      payload: closeTradeData,
    });
    // Close the confirmation modal
    setConfirmationModalOpen(false);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalOpen(!isConfirmationModalOpen);
  };

  const generateJSONSpot = () => {
    if (activeTabTrade === "1" && botDetails) {
      // For opening trades
      return JSON.stringify(
        {
          bot_details: {
            bot_id: botData.fields.bot_id,
            exchange: botData.fields.bot_exchange,
            trading_type: "spot",
          },
          details: {
            token: botData.fields.bot_exchange_token,
            name,
            symbol,
            orderType,
            side,
            quantity: {
              type: quantityType,
              amount: quantity,
            },
          },
        },
        null,
        2
      );
    } else if (activeTabTrade === "2" && botDetails) {
      // For closing trades
      return JSON.stringify(
        {
          bot_details: {
            bot_id: botData.fields.bot_id,
            exchange: botData.fields.bot_exchange,
            trading_type: "spot",
          },
          details: {
            token: botData.fields.bot_exchange_token,
            name: closeTrade.tokenName,
            symbol: closeTrade.tokenSymbol,
            orderType: closeTrade.orderType,
            side: closeTrade.side,
            quantity: {
              type: closeTrade.quantityType,
              amount: closeTrade.quantityValue,
            },
          },
        },
        null,
        2
      );
    }
    return "{}"; // Default empty JSON if neither tab is selected
  };

  const [tokenSpot, setTokenSpot] = useState("");
  const [name, setNameSpot] = useState("");
  const [symbol, setSymbolSpot] = useState("");
  const [orderType, setOrderTypeSpot] = useState("market");
  const [side, setSideSpot] = useState("Select Side");
  const [quantityType, setQuantityTypeSpot] = useState("dollar");
  const [quantity, setQuantitySpot] = useState("");

  return (
    <React.Fragment>
      <Row>
        <Col xl="6">
          <Card>
            <CardBody>
              <Nav pills className="bg-light rounded" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTabTrade === "1",
                    })}
                    onClick={() => toggleTabTrade("1")}
                  >
                    Place Spot Order
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTabTrade === "2",
                    })}
                    onClick={() => toggleTabTrade("2")}
                  >
                    Close
                  </NavLink>
                </NavItem> */}
              </Nav>

              <TabContent activeTab={activeTabTrade} className="mt-4">
                <TabPane tabId="1">
                  {botDetails ? (
                    <>
                      {botDetails.bot_exchange_token ? (
                        <FormGroup>
                          <Label for="tokenSpot">Token</Label>
                          <Input
                            type="text"
                            id="tokenSpot"
                            value={botDetails.bot_exchange_token}
                            readOnly
                          />
                        </FormGroup>
                      ) : null}
                      {/* Other bot details */}
                    </>
                  ) : (
                    <p>No details available for this bot.</p>
                  )}

                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setNameSpot(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="symbol">Symbol</Label>
                    <Input
                      type="text"
                      id="symbol"
                      value={symbol}
                      onChange={(e) => setSymbolSpot(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="me-2" for="orderType">
                      Order Type
                    </Label>

                    <Input
                      type="select"
                      id="orderType"
                      value={orderType}
                      onChange={(e) => setOrderTypeSpot(e.target.value)}
                    >
                      <option value="market">Market</option>
                      {/* Add other order types as needed */}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="me-2" for="side">
                      Side
                    </Label>
                    <span className="badge-soft-warning  badge bg-secondary">
                      This can be customised in advance settings
                    </span>
                    <Input
                      type="select"
                      id="side"
                      value={side}
                      onChange={(e) => setSideSpot(e.target.value)}
                    >
                      <option value="Select Side">Select Side</option>
                      <option value={botBuyName}>{botBuyName}</option>
                      <option value={botSellName}>{botSellName}</option>
                    </Input>
                  </FormGroup>
                  <div>
                    <Label className="me-2" for="quantity">
                      Quantity
                    </Label>
                    <span className="badge-soft-warning  badge bg-primary">
                      Quantity Based On Selection Dollar/Asset/Percentage
                    </span>
                    <InputGroup className="mb-3">
                      <Input
                        type="select"
                        value={quantityType}
                        onChange={(e) => setQuantityTypeSpot(e.target.value)}
                      >
                        <option value="dollar">Dollar</option>
                        <option value="asset">Asset</option>
                        <option value="percentage">Percentage</option>
                      </Input>
                      <Input
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantitySpot(e.target.value)}
                        placeholder="Quantity"
                      />
                    </InputGroup>
                  </div>

                  <div className="text-center">
                    {/* <Button color="success" onClick={handleSubmitTrade}>
                      Open Trade
                    </Button> */}
                    <Button
                      color="success"
                      onClick={handleSubmitTrade}
                      disabled={isLoading}
                    >
                      {isLoading ? "Opening..." : "Open Trade"}
                    </Button>

                    {/* Confirmation Modal */}
                    <Modal isOpen={isModalOpen} centered toggle={toggleModal}>
                      <ModalHeader>Confirm Trade</ModalHeader>
                      <ModalBody>
                        Are you sure you want to place an order?
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>{" "}
                        <Button color="primary" onClick={handleConfirmTrade}>
                          Open Order
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  <form onSubmit={handleSubmitCloseTrade}>
                    {botDetails ? (
                      <>
                        {botDetails.bot_exchange_token ? (
                          <FormGroup>
                            <Label for="tokenName">Token</Label>
                            <Input
                              type="text"
                              id="tokenName"
                              value={botDetails.bot_exchange_token}
                              readOnly
                            />
                          </FormGroup>
                        ) : null}
                        {/* Other bot details */}
                      </>
                    ) : (
                      <p>No details available for this bot.</p>
                    )}
                    <FormGroup>
                      <Label for="tokenName">Name</Label>
                      <Input
                        type="text"
                        name="tokenName"
                        value={closeTrade.tokenName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="tokenSymbol">Symbol</Label>
                      <Input
                        type="text"
                        name="tokenSymbol"
                        value={closeTrade.tokenSymbol}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="orderType">Order Type</Label>
                      <Input
                        type="select"
                        name="orderType"
                        value={closeTrade.orderType}
                        onChange={handleInputChange}
                      >
                        <option value="market">Market</option>
                        {/* Add other order types as needed */}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="side">Side</Label>
                      <Input
                        type="select"
                        name="side"
                        value={closeTrade.side}
                        onChange={handleInputChange}
                      >
                        <option value="close_long">Close Long</option>
                        <option value="close_short">Close Short</option>
                      </Input>
                    </FormGroup>
                    <div>
                      <Label for="quantity">Quantity</Label>
                      <InputGroup className="mb-3">
                        <Input
                          type="select"
                          name="quantityType"
                          value={closeTrade.quantityType}
                          onChange={handleInputChange}
                        >
                          <option value="dollar">Dollar</option>
                          <option value="asset">Asset</option>
                          <option value="percentage">Percentage</option>
                        </Input>
                        <Input
                          type="text"
                          name="quantityValue"
                          value={closeTrade.quantityValue}
                          onChange={handleInputChange}
                          placeholder="Enter quantity"
                        />
                      </InputGroup>
                    </div>
                    <div className="text-center">
                      {/* <Button color="danger" onClick={toggleConfirmationModal}>
                        Close Trade
                      </Button> */}
                      <Button
                        color="danger"
                        onClick={toggleConfirmationModal}
                        disabled={isLoading}
                      >
                        {isLoading ? "Closing..." : "Close Trade"}
                      </Button>

                      {/* Confirmation Modal */}
                      <Modal
                        isOpen={isConfirmationModalOpen}
                        toggle={toggleConfirmationModal}
                        centered
                      >
                        <ModalHeader toggle={toggleConfirmationModal}>
                          Confirm Close Trade
                        </ModalHeader>
                        <ModalBody>
                          Are you sure you want to close this trade?
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="secondary"
                            onClick={toggleConfirmationModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="danger"
                            onClick={handleSubmitCloseTrade}
                          >
                            Confirm Close
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </form>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>

        <Col xl="6">
          <Card>
            <CardBody>
              {activeTabTrade === "1" && (
                <button
                  type="button"
                  className="btn btn-light btn-label mb-4"
                  id="copyOrderUrlButton"
                  data-url="https://api.crodl.com/bots/place-order"
                  onClick={copyOpenOrderUrl}
                >
                  <i className="bx bx-copy-alt label-icon"></i> Copy Open Order
                  URL
                </button>
              )}

              {activeTabTrade === "2" && (
                <button
                  type="button"
                  className="btn btn-light btn-label mb-4"
                  id="copyOrderUrlButton"
                  data-url="https://api.crodl.com/bots/place-order"
                  onClick={copyOpenOrderUrl}
                >
                  <i className="bx bx-copy-alt label-icon"></i> Copy Close Order
                  URL
                </button>
              )}

              <pre ref={jsonRefSpot}>{generateJSONSpot()}</pre>
            </CardBody>
            <button
              className="btn btn-primary waves-effect waves-light btn btn-primary external-event"
              onClick={() => copyToClipboard(jsonRefSpot)}
            >
              Copy Syntax
            </button>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SpotForm;
