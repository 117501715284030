import {
  SUBMIT_BITGET_API_DATA,
  SUBMIT_BITGET_API_DATA_SUCCESS,
  SUBMIT_BITGET_API_DATA_FAIL,
  RESET_SUBMIT_BITGET_API_DATA,
  FETCH_API_DATA_BITGET,
  FETCH_API_DATA_SUCCESS_BITGET,
  FETCH_API_DATA_FAIL_BITGET,
  DELETE_API_DATA_BITGET,
  DELETE_API_DATA_SUCCESS_BITGET,
  DELETE_API_DATA_FAIL_BITGET,
} from "./actionTypes"

const INIT_STATE = {
  bitgetApiData: [],
  fetchedApiData: null, // Holds the fetched data
  error: null,
  loading: false,
  success: false,
}

const bitgetApiDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SUBMIT_BITGET_API_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
      }

    case SUBMIT_BITGET_API_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      }

    case SUBMIT_BITGET_API_DATA_SUCCESS:
      return {
        ...state,
        bitgetApiData: [...state.bitgetApiData, action.payload],
        loading: false,
        error: null,
        success: true,
      }

    case SUBMIT_BITGET_API_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      }

    case FETCH_API_DATA_BITGET:
      return {
        ...state,
        loading: true, // Set loading true when fetch starts
      }

    case FETCH_API_DATA_SUCCESS_BITGET:
      return {
        ...state,
        fetchedData: action.payload, // Ensure this matches the structure you're expecting
        loading: false,
        error: null,
      }

    case FETCH_API_DATA_FAIL_BITGET:
      return {
        ...state,
        fetchedData: null, // Clear the previous data when there's a failure
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      }

    case DELETE_API_DATA_BITGET:
      return {
        ...state,
        loading: true, // Set loading true when delete starts
      }

    case DELETE_API_DATA_SUCCESS_BITGET:
      const updatedBitgetApiData = state.fetchedData.filter(
        item => item && item.security_token !== action.payload
      )

      return {
        ...state,
        fetchedData: updatedBitgetApiData, // Update bitgetApiData with the filtered array
        loading: false,
        error: null,
      }

    case DELETE_API_DATA_FAIL_BITGET:
      return {
        ...state,
        loading: false,
        error: action.payload, // Make sure this contains a meaningful error message
      }

    default:
      return state
  }
}

export default bitgetApiDataReducer
