import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap"

import { call, put, takeEvery } from "redux-saga/effects"
import {
  BotActionTypes,
  createBotSuccess,
  createBotFail,
  fetchBotDataSuccess,
  fetchBotDataFail,
  deleteBotSuccess,
  deleteBotFail,
  fetchBotData,
  fetchAvailableExchangeDataRequest,
  fetchAvailableExchangeDataSuccess,
  fetchAvailableExchangeDataFail,
} from "./actions" // Ensure this path is correct
import {
  postCreateBot,
  getBotData,
  deleteBotData,
  fetchAvailableApiData,
} from "../../helpers/backend_helper" // Adjust with actual backend helpers

import { toast } from "react-toastify"

function* createBotSaga({ payload: data }) {
  try {
    // Ensure the postCreateBot function is available
    if (typeof postCreateBot !== "function") {
      yield put(
        createBotFail("Bot creation failed: Backend function not available")
      )
      return
    }

    const response = yield call(postCreateBot, data)

    if (response?.success && response.bot) {
      let botData = response.bot // Directly accessing bot object

      // If botData is a string, attempt to parse it
      if (typeof botData === "string") {
        try {
          botData = JSON.parse(botData)
        } catch (parseError) {
          yield put(createBotFail("Failed to parse bot data"))
          return
        }
      }

      // Check and access fields if bot data is nested
      if (botData.fields) {
        botData = botData.fields
      }

      // Dispatch success action with the new bot's data
      yield put(createBotSuccess(botData))

      // Dispatch action to refresh the list of bots
      yield put(fetchBotData()) // Correctly dispatch fetchBotData action
      toast.success("Bot created successfully", { autoClose: 2000 })
    } else {
      // Handle failure in bot creation
      yield put(createBotFail("No bot data received or bot creation failed"))
      toast.error("No bot data received or bot creation failed", {
        autoClose: 2000,
      })
    }
  } catch (error) {
    // Handle errors such as parsing errors or API call failures
    yield put(
      createBotFail(
        "Bot creation failed: " + (error.message || error.toString())
      )
    )
    toast.error("Bot creation failed: " + (error.message || error.toString()), {
      autoClose: 2000,
    })
  }
}

// Saga for fetching bot data
function* fetchBotSaga() {
  try {
    const response = yield call(getBotData)
    // Check if the response has a 'bots' property and is successful
    if (response && response.success && Array.isArray(response.bots)) {
      yield put(fetchBotDataSuccess(response.bots))
    } else {
      // Handle cases where the response might not be as expected
      const errorMessage = response.message || "Failed to fetch bot data"
      yield put(fetchBotDataFail(errorMessage))
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(fetchBotDataFail(errorText))
    toast.error(errorText || "Failed to fetch bot data", { autoClose: 2000 })
  }
}

// Saga for deleting a bot
function* deleteBotSaga({ payload: botId }) {
  try {
    const response = yield call(deleteBotData, botId)
    if (response && response.success) {
      yield put(deleteBotSuccess(botId))
    } else {
      const errorMessage = response.message || "Bot deletion failed"
      yield put(deleteBotFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(deleteBotFail(errorText))
    toast.error(errorText || "Bot deletion failed", { autoClose: 2000 })
  }
}

function* fetchAvailableExchangeDataSaga() {
  try {
    const response = yield call(fetchAvailableApiData)
    // Check if the response is successful
    if (response) {
      yield put(fetchAvailableExchangeDataSuccess(response.exchanges))
    } else {
      // Handle cases where the response might not be as expected
      const errorMessage =
        response.message || "Failed to fetch available exchange data"
      yield put(fetchAvailableExchangeDataFail(errorMessage))
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(fetchAvailableExchangeDataFail(errorText))
    toast.error(errorText || "Failed to fetch available exchange data", {
      autoClose: 2000,
    })
  }
}

// Combined bot saga to watch actions
export default function* botSaga() {
  yield takeEvery(BotActionTypes.CREATE_REQUEST, createBotSaga)
  yield takeEvery(BotActionTypes.FETCH_REQUEST, fetchBotSaga)
  yield takeEvery(BotActionTypes.DELETE_REQUEST, deleteBotSaga) // Watching for delete requests
  yield takeEvery(
    BotActionTypes.FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST,
    fetchAvailableExchangeDataSaga
  )
}
