import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

// Dashboard
import Homepage from "../pages/Homepage/index";
import Dashboard from "../pages/Dashboard/index";
import Exchanges from "../pages/Exchanges/exchanges";
import BybitConnection from "../pages/Exchange/BybitConnection";
import BitgetConnection from "../pages/Exchange/BitgetConnection";
import BinanceConnection from "../pages/Exchange/BinanceConnection";
import BloFinConnection from "../pages/Exchange/BloFinConnection";
import Disclaimer from "../pages/Disclaimers/disclaimer";

import BotsOverview from "../pages/Bots/BotsOverview";
import Bot from "../pages/BotSpecific/Bot";
import Subscriptions from "../pages/Subscriptions/subscriptions";

import Notifications from "../pages/Notifications/notifications";
import TelegramConnect from "pages/TelegramConnect/TelegramConnect";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // Profile
  { path: "/profile", component: <UserProfile /> },

  // Redirect to /dashboard by default
  {
    path: "/",
    exact: true,
    component: <Navigate to="/homepage" />,
  },
  { path: "/exchanges", component: <Exchanges /> },
  { path: "/exchanges/bybit-connection", component: <BybitConnection /> },
  { path: "/exchanges/bitget-connection", component: <BitgetConnection /> },
  { path: "/exchanges/binance-connection", component: <BinanceConnection /> },
  { path: "/exchanges/blofin-connection", component: <BloFinConnection /> },
  { path: "/bots-overview", component: <BotsOverview /> },
  { path: "/bots/:botId", component: <Bot /> },
  { path: "/subscriptions", component: <Subscriptions /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "*", component: <Navigate to="/homepage" replace /> },
  { path: "/telegram-connect", component: <TelegramConnect /> },
];

const publicRoutes = [
  { path: "/homepage", component: <Homepage /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> }, // Updated path and component
  { path: "/disclaimer", component: <Disclaimer /> },
  { path: "*", component: <Navigate to="/homepage" replace /> },
];

export { authProtectedRoutes, publicRoutes };
