import React from "react"
import { Container, Nav, Navbar } from "reactstrap"
import { Link } from "react-router-dom" // Assuming you're using react-router for navigation
import logo from "../../assets/images/logo-dark.png"

const Disclaimer = () => {
  return (
    <>
      {/* Navbar: Uncomment and adjust if you need a navbar on this page */}

      <Navbar expand="md">
        <Container>
          <Nav className="navbar">
            <div className="logo">
              <a href="/homepage">
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <div className="navbar-right">
              <Link className="sign-in" to="/">
                Sign In
              </Link>
              <Link className="get-started" to="/register">
                <span>Get Started</span>
                <i className="bx bx-right-arrow-alt"></i>
              </Link>
            </div>
          </Nav>
        </Container>
      </Navbar>

      <Container style={{ marginTop: "80px" }}>
        {" "}
        <h1>Disclaimer</h1>
        <p>
          CRODL IS NOT A REGISTERED COMPANY , WE ARE A DISCORD COMMUNITY THAT
          SHARES TRADING TOOLS. CRODL OR ANY OF THE ADMINS/OWNERS/MEMBERS ARE
          NOT FINANCIAL ADVISORS. PLEASE USE CRODL OR ANY SERVICE OR PRODUCTS OR
          APPLICABLE PROVIDED BY CRODL AT YOUR OWN RISK! RISKS ASSOCIATED WITH
          FOREX, CRYTPOCURRENCY AND MARGIN TRADING TRADING CURRENCIES CAN BE A
          CHALLENGING AND POTENTIALLY PROFITABLE OPPORTUNITY FOR INVESTORS.
          HOWEVER, BEFORE DECIDING TO PARTICIPATE IN THE FOREX AND/OR CRYPTO
          MARKET, YOU SHOULD CAREFULLY CONSIDER YOUR INVESTMENT OBJECTIVES,
          LEVEL OF EXPERIENCE, AND RISK APPETITE. MOST IMPORTANTLY, DO NOT
          INVEST MONEY YOU CANNOT AFFORD TO LOSE. THERE IS CONSIDERABLE EXPOSURE
          TO RISK IN ANY FOREIGN EXCHANGE TRANSACTION. ANY TRANSACTION INVOLVING
          TRADITIONAL CURRENCIES OR CRYPTOCURRENCIES INVOLVES RISKS INCLUDING,
          BUT NOT LIMITED TO, THE POTENTIAL FOR CHANGING POLITICAL AND/OR
          ECONOMIC CONDITIONS THAT MAY SUBSTANTIALLY AFFECT THE PRICE OR
          LIQUIDITY OF A CURRENCY. INVESTMENTS IN FOREIGN EXCHANGE OR
          CRYPTOCURRENCY SPECULATION MAY ALSO BE SUSCEPTIBLE TO SHARP RISES AND
          FALLS AS THE RELEVANT MARKET VALUES FLUCTUATE. THE LEVERAGED NATURE OF
          FOREX AND CRYPTO TRADING MEANS THAT ANY MARKET MOVEMENT WILL HAVE AN
          EQUALLY PROPORTIONAL EFFECT ON YOUR DEPOSITED FUNDS. THIS MAY WORK
          AGAINST YOU AS WELL AS FOR YOU. NOT ONLY MAY INVESTORS GET BACK LESS
          THAN THEY INVESTED, BUT IN THE CASE OF HIGHER RISK STRATEGIES,
          INVESTORS MAY LOSE THE ENTIRETY OF THEIR INVESTMENT. IT IS FOR THIS
          REASON THAT WHEN SPECULATING IN SUCH MARKETS IT IS ADVISABLE TO USE
          ONLY RISK CAPITAL. RISK DISCLAIMER FOR MARGIN TRADING TRADING
          CRYPTOCURRENCY OR FOREIGN EXCHANGE ON MARGIN CARRIES A HIGH LEVEL OF
          RISK, AND MAY NOT BE SUITABLE FOR ALL INVESTORS. PAST PERFORMANCE IS
          NOT INDICATIVE OF FUTURE RESULTS. THE HIGH DEGREE OF LEVERAGE CAN WORK
          AGAINST YOU AS WELL AS FOR YOU. BEFORE DECIDING TO INVEST IN FOREIGN
          EXCHANGE OR CRYPTOCURRENCY MARGIN TRADING YOU SHOULD CAREFULLY
          CONSIDER YOUR INVESTMENT OBJECTIVES, LEVEL OF EXPERIENCE, AND RISK
          APPETITE. THE POSSIBILITY EXISTS THAT YOU COULD SUSTAIN A LOSS OF SOME
          OR ALL OF YOUR INITIAL INVESTMENT AND THEREFORE YOU SHOULD NOT INVEST
          MONEY THAT YOU CANNOT AFFORD TO LOSE. YOU SHOULD BE AWARE OF ALL THE
          RISKS ASSOCIATED WITH FOREIGN EXCHANGE AND CRYPTOCURRENCY TRADING, AND
          SEEK ADVICE FROM AN INDEPENDENT FINANCIAL ADVISOR IF YOU HAVE ANY
          DOUBTS. BENEFITS AND RISKS OF LEVERAGE LEVERAGE ALLOWS TRADERS THE
          ABILITY TO ENTER INTO A POSITION WORTH MANY TIMES THE ACCOUNT VALUE
          WITH A RELATIVELY SMALL AMOUNT OF MONEY. THIS LEVERAGE CAN WORK WITH
          YOU AS WELL AS AGAINST YOU. EVEN THOUGH BOTH CRYPTO AND FOREX MARKETS
          OFFERS TRADERS THE ABILITY TO USE A HIGH DEGREE OF LEVERAGE, TRADING
          WITH HIGH LEVERAGE MAY INCREASE THE LOSSES SUFFERED. PLEASE USE
          CAUTION WHEN USING LEVERAGE IN TRADING OR INVESTING. HYPOTHETICAL
          RESULTS DISCLAIMER THE RESULTS FOUND ON THIS WEBSITE ARE BASED ON
          SIMULATED OR HYPOTHETICAL PERFORMANCE RESULTS THAT HAVE CERTAIN
          INHERENT LIMITATIONS. UNLIKE THE RESULTS SHOWN IN AN ACTUAL
          PERFORMANCE RECORD, THESE RESULTS DO NOT REPRESENT ACTUAL TRADING.
          ALSO, BECAUSE THESE TRADES HAVE NOT ACTUALLY BEEN EXECUTED, THESE
          RESULTS MAY HAVE UNDER-OR OVER-COMPENSATED FOR THE IMPACT, IF ANY, OF
          CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED OR
          HYPOTHETICAL TRADING PROGRAMS IN GENERAL ARE ALSO SUBJECT TO THE FACT
          THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO
          REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO
          ACHIEVE PROFITS OR LOSSES SIMILAR TO THESE BEING SHOWN. THE
          INFORMATION THAT MAY BE PRESENTED IS BASED ON SIMULATED TRADING USING
          SYSTEMS AND EDUCATION DEVELOPED EXCLUSIVELY BY THE CRODL DEVELOPMENT
          TEAM AND ITS ASSOCIATES. SIMULATED RESULTS DO NOT REPRESENT ACTUAL
          TRADING. PLEASE NOTE THAT SIMULATED TRADING RESULTS MAY OR MAY NOT
          HAVE BEEN BACK-TESTED FOR ACCURACY AND THAT SPREADS/COMMISSIONS ARE
          NOT TAKEN INTO ACCOUNT WHEN PREPARING HYPOTHETICAL RESULTS. NO
          REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO
          ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE THAT MAY BE SHOWN. PAST
          PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS. INDIVIDUAL RESULTS
          VARY AND NO REPRESENTATION IS MADE THAT CLIENTS WILL OR ARE LIKELY TO
          ACHIEVE PROFITS OR INCUR LOSSES COMPARABLE TO THOSE THAT MAY BE SHOWN.
          ELECTRONIC TRADING RISKS BEFORE YOU ENGAGE IN TRANSACTIONS USING AN
          ELECTRONIC SYSTEM, YOU SHOULD CAREFULLY REVIEW THE RULES AND
          REGULATIONS OF THE EXCHANGES OFFERING THE SYSTEM AND/OR LISTING THE
          INSTRUMENTS YOU INTEND TO TRADE. ONLINE TRADING HAS INHERENT RISK DUE
          TO SYSTEM RESPONSE AND ACCESS TIMES THAT MAY VARY DUE TO MARKET
          CONDITIONS, SYSTEM PERFORMANCE, AND OTHER FACTORS. YOU SHOULD
          UNDERSTAND THESE AND ADDITIONAL RISKS BEFORE TRADING. ANY OPINIONS,
          NEWS, RESEARCH, ANALYSES, PRICES, OR OTHER INFORMATION OFFERED BY THE
          CRODL WEBSITE AND ACCOMPANYING VIDEOS BY ITS FOUNDERS AND ASSOCIATES
          ARE PROVIDED AS GENERAL MARKET COMMENTARY, AND DOES NOT CONSTITUTE
          INVESTMENT ADVICE. THE OWNER AND OPERATOR OF THE CRODL WEBSITE AND
          INDICATOR SCRIPT WILL NOT ACCEPT LIABILITY FOR ANY LOSS OR DAMAGE,
          INCLUDING WITHOUT LIMITATION TO, ANY LOSS OF PROFIT, WHICH MAY ARISE
          DIRECTLY OR INDIRECTLY FROM USE OF OR RELIANCE ON SUCH INFORMATION.
          AUTOMATED TRADING CRODL OFFER AUTOMATED TRADING SOLUTIONS SUCH AS OUR
          CRODL AUTOMATED TRADING BOT TELEGRAM BOT FOUND AT
          HTTP://T.ME/CRODLAUTOMATEDTRADINGBOT. THIS IS EXPERIMENTAL SOFTWARE
          THAT CARRIES NO GUARANTEES OF PROFITABILITY AND MAY CAUSE FINANCIAL
          LOSS. CERTAIN FACTORS ARE OUT OF OUR CONTROL, INCLUDING BUT NOT
          LIMITED EXCHANGE OUTAGES, SERVER DOWNTIME, API HIJACKING AND INDICATOR
          SETTINGS THAT CAN CAUSE YOU TO LOSE SOME OR ALL OF YOUR ACCOUNT
          BALANCE, BY PROCEEDING TO USE CRODL TRADING BOT OR APPLICATION
          ACKNOWLEDGE THAT YOU UNDERSTAND THESE RISKS , AND YOU WILL NOT HOLD
          CRODL ITS ASSOCIATES, EMPLOYEES, OR SOCIAL MEDIA REPRESENTATIVES
          ACCOUNTABLE IN THE EVENT OF LOSSES OR ACCOUNT LIQUIDATION. BACK TEST
          RESULTS CRODL HAS A BACKTEST FEATURE ON TRADINGVIEW.COM HOWEVER PLEASE
          NOTE THAT BACKTEST RESULTS ARE NOT AN ACCURATE PRESENTATION OF HOW
          BOTH CRODL AND THE TRADING BOT OR APPLICATION WOULD HAVE PERFORMED,
          NEITHER IS IT A GUARANTEE OF FUTURE PERFORMANCE. RISKS ASSOCIATED WITH
          TRADING THE STOCK MARKET ALL INVESTMENTS INVOLVE RISK, AND THE PAST
          PERFORMANCE OF A SECURITY, INDUSTRY, SECTOR, MARKET, FINANCIAL
          PRODUCT, TRADING STRATEGY, OR INDIVIDUAL’S TRADING DOES NOT GUARANTEE
          FUTURE RESULTS OR RETURNS. INVESTORS ARE FULLY RESPONSIBLE FOR ANY
          INVESTMENT DECISIONS THEY MAKE. SUCH DECISIONS SHOULD BE BASED SOLELY
          ON AN EVALUATION OF THEIR FINANCIAL CIRCUMSTANCES, INVESTMENT
          OBJECTIVES, RISK TOLERANCE, AND LIQUIDITY NEEDS. ANY OPINIONS, NEWS,
          RESEARCH, ANALYSES, PRICES, OR OTHER INFORMATION OFFERED BY THE CRODL
          WEBSITE AND ACCOMPANYING VIDEOS BY ITS FOUNDERS AND ASSOCIATES ARE
          PROVIDED AS GENERAL MARKET COMMENTARY, AND DOES NOT CONSTITUTE
          INVESTMENT ADVICE. THE OWNER AND OPERATOR OF THE CRODL WEBSITE AND
          INDICATOR SCRIPT WILL NOT ACCEPT LIABILITY FOR ANY LOSS OR DAMAGE,
          INCLUDING WITHOUT LIMITATION TO, ANY LOSS OF PROFIT, WHICH MAY ARISE
          DIRECTLY OR INDIRECTLY FROM USE OF OR RELIANCE ON SUCH INFORMATION.
          CRYPTOCURRENCY TRADING CRYPTOCURRENCIES ON MARGIN CARRIES A HIGH LEVEL
          OF RISK, AND MAY NOT BE SUITABLE FOR EVERYONE. PAST PERFORMANCE IS NOT
          INDICATIVE OF FUTURE RESULTS. THE HIGH DEGREE OF LEVERAGE CAN WORK
          AGAINST YOU AS WELL AS FOR YOU. BEFORE GETTING INVOLVED IN
          CRYPTOCURRENCY TRADING YOU SHOULD CAREFULLY CONSIDER YOUR PERSONAL
          VENTURE OBJECTIVES, LEVEL OF EXPERIENCE, AND RISK APPETITE. THE
          POSSIBILITY EXISTS THAT YOU COULD SUSTAIN A LOSS OF SOME OR ALL OF
          YOUR INITIAL DEPOSIT AND THEREFORE YOU SHOULD NOT PLACE FUNDS THAT YOU
          CANNOT AFFORD TO LOSE. YOU SHOULD BE AWARE OF ALL THE RISKS ASSOCIATED
          WITH CRYPTOCURRENCY TRADING, AND SEEK ADVICE FROM AN INDEPENDENT
          FINANCIAL ADVISOR IF YOU HAVE ANY DOUBTS. THE INFORMATION CONTAINED IN
          THIS WEB PAGE DOES NOT CONSTITUTE FINANCIAL ADVICE OR A SOLICITATION
          TO BUY OR SELL ANY CRYPTOCURRENCY CONTRACT OR SECURITIES OF ANY TYPE.
          CRODL WILL NOT ACCEPT LIABILITY FOR ANY LOSS OR DAMAGE, INCLUDING
          WITHOUT LIMITATION ANY LOSS OF PROFIT, WHICH MAY ARISE DIRECTLY OR
          INDIRECTLY FROM USE OF OR RELIANCE ON SUCH INFORMATION. THE
          INFORMATION CONTAINED IN THIS WEB PAGE DOES NOT CONSTITUTE FINANCIAL
          ADVICE OR A SOLICITATION TO BUY OR SELL ANY SECURITIES OF ANY TYPE.
          CRODL WILL NOT ACCEPT LIABILITY FOR ANY LOSS OR DAMAGE, INCLUDING
          WITHOUT LIMITATION ANY LOSS OF PROFIT, WHICH MAY ARISE DIRECTLY OR
          INDIRECTLY FROM USE OF OR RELIANCE ON SUCH INFORMATION. ANY LINKS FROM
          CRODL TO THIRD PARTY CFD BROKERAGES AND/OR CRYPTOCURRENCY EXCHANGES
          MAY OR MAY NOT BE AFFILIATE LINKS WHEREBY WE RECEIVE COMMISSIONS FOR
          REFERRING CUSTOMERS TO THESE SITES. CRODL, AND ITS FOUNDERS WILL NOT
          ACCEPT LIABILITY FOR ANY LOSS OR DAMAGE, INCLUDING WITHOUT LIMITATION
          ANY LOSS OF PROFIT, WHICH MAY ARISE DIRECTLY OR INDIRECTLY FROM USE OF
          OR RELIANCE ON SUCH INFORMATION. CLIENT TERMS OF USE THESE TERMS OF
          USE ARE EFFECTIVE AS OF JANUARY 1, 2021. INTRODUCTION THE SERVICE IS
          MADE AVAILABLE TO YOU BY CRODL (“CRODL”, “WE”, “US” OR “OUR”) THROUGH
          THE WEBSITE LOCATED AT HTTPS://WWW.CRODL.COM CRODL & CRODL TRADING BOT
          OR APPLICATION MOBILE APPLICATION(S), APPLICATION PROGRAM
          INTERFACE(S), ALL TOGETHER OR EACH SEPARATELY ARE REFERRED TO AS
          (COLLECTIVELY, THE “SOFTWARE”). THE FOLLOWING TERMS AND CONDITIONS
          (THE “TERMS OF USE”) ARE THE TERMS AND CONDITIONS FOR THE USE OF THE
          SOFTWARE BY END USER CLIENTS OR VISITORS (“CLIENT”, “YOU” OR “YOUR”).
          THESE TERMS OF USE ALSO APPLY TO THE PURCHASE AND SALE OF PRODUCTS AND
          SERVICES BY CLIENTS THROUGH THE SOFTWARE. THIS DOCUMENT CONTAINS VERY
          IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL
          AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS. PLEASE READ THESE TERMS OF
          USE CAREFULLY BEFORE ACCESSING OR USING THE SOFTWARE. BY ACCEPTING
          THESE TERMS YOU AGREE AND CONFIRM THAT: YOU HAVE READ, UNDERSTOOD, AND
          AGREED TO BE BOUND BY THESE TERMS OF USE; YOU ASSUME ALL THE
          OBLIGATIONS SET FORTH HEREIN; YOU ARE OF SUFFICIENT LEGAL AGE AND
          CAPACITY TO USE THE SOFTWARE; YOU ARE NOT UNDER THE CONTROL OF
          JURISDICTION THAT EXPLICITLY PROHIBITS THE USE OF SIMILAR SOFTWARE;
          YOU USE THE SOFTWARE AT YOUR DISCRETION AND UNDER YOUR OWN
          RESPONSIBILITY. THESE TERMS OF USE CONSTITUTE A LEGALLY BINDING
          AGREEMENT BETWEEN YOU AND CRODL & CRODL TRADING BOT OR APPLICATION
          REGARDING YOUR ACCESS TO AND USE OF THE SOFTWARE, WHICH INCLUDES,
          WITHOUT LIMITATION, REGISTERING FOR A CLIENT ACCOUNT (“CLIENT
          ACCOUNT”) ON THE SOFTWARE. BY ACCESSING OR USING THE SOFTWARE, YOU
          AGREE TO BE BOUND BY THESE TERMS OF USE. YOU SHOULD ALSO READ OUR
          PRIVACY POLICY AT HTTPS://WWW.CRODL.COM/TERMS-CONDITIONS/ , WHICH IS
          INCORPORATED BY REFERENCE INTO THE TERMS OF USE. IF YOU DO NOT WISH TO
          BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT ACCESS OR USE THE
          SOFTWARE. IF YOU ARE ENTERING INTO THESE TERMS OF USE ON BEHALF OF A
          COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
          AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS OF
          USE, IN WHICH CASE THE TERMS “YOU” AND “YOUR” SHALL REFER TO SUCH
          ENTITY AND ITS AFFILIATES AND NOT TO YOU PERSONALLY. IF YOU DO NOT
          HAVE SUCH AUTHORITY YOU MUST NOT ACCEPT THESE TERMS OF USE AND MAY NOT
          USE THE SOFTWARE. CRODL MAY UPDATE OR REVISE THESE TERMS OF USE FROM
          TIME TO TIME BY PROVIDING NOTICE OF SUCH UPDATES OR REVISIONS ON THE
          SOFTWARE. YOU AGREE THAT YOU WILL REVIEW THESE TERMS OF USE
          PERIODICALLY. IF YOU DO NOT AGREE TO THE TERMS OF THESE TERMS OF USE
          OR ANY MODIFIED VERSION OF THESE TERMS OF USE, YOUR SOLE RECOURSE IS
          TO TERMINATE YOUR USE OF THE SOFTWARE. IF YOU BREACH ANY OF THESE
          TERMS OF USE, YOUR AUTHORIZATION TO USE THE SOFTWARE AUTOMATICALLY
          TERMINATES AND YOU MUST DISCONTINUE ALL USE OF THE SOFTWARE. THE
          SOFTWARE THE SOFTWARE PROVIDES YOU WITH TOOLS THAT ALLOW YOU TO MANAGE
          YOUR CRYPTOCURRENCY HOLDINGS, INCLUDING BUT NOT LIMITED TO TOOLS THAT
          ALLOW YOU TO: (I) PLACE BUY/SELL ORDERS ON ONE OR SEVERAL THIRD PARTY
          CRYPTOCURRENCY EXCHANGES USING THE CAPABILITIES OF THIRD-PARTY PUBLIC
          APIS AND “TRADING TERMINAL” FEATURES OF CRODL & CRODL TRADING BOT OR
          APPLICATION; (II) CONFIGURE THE PARAMETERS OF “TRADER BOT,” WHICH WILL
          EXECUTE CRYPTOCURRENCY TRADE ORDERS AUTOMATICALLY ON THIRD PARTY
          CRYPTOCURRENCY EXCHANGES WITHIN THOSE ESTABLISHED PARAMETERS AND
          WITHOUT THE NEED FOR ADDITIONAL HUMAN INTERACTION OR INTERVENTION;
          (III) FOR AN ADDITIONAL FEE, ELECT TO FOLLOW AND RECEIVE ADDITIONAL
          INFORMATION FROM THIRD PARTIES WHO USE THE SOFTWARE AS A PLATFORM TO
          DISTRIBUTE THEIR OWN INFORMATION TO CLIENTS WHO HAVE SUBSCRIBED TO
          THEIR FEED OR DISTRIBUTION LIST (“ENTERPRISE PARTNERS”); (IV) USE
          CRODL & CRODL TRADING BOT OR APPLICATION APPLICATION PROGRAMMING
          INTERFACES AND API-RELATED SPECIFICATION DOCUMENTS (“CRODL & CRODL
          TRADING BOT OR APPLICATION API” OR “API”) TO ESTABLISH AN ELECTRONIC
          CONNECTION TO THIRD-PARTY SERVICES. DISCLAIMER CRODL & CRODL TRADING
          BOT OR APPLICATION PROVIDES THE SOFTWARE. CRODL & CRODL TRADING BOT OR
          APPLICATION DOES NOT PROVIDE FINANCIAL, INVESTMENT, LEGAL, TAX OR ANY
          OTHER PROFESSIONAL ADVICE. CRODL & CRODL TRADING BOT OR APPLICATION IS
          NOT A BROKER, FINANCIAL ADVISOR, INVESTMENT ADVISOR, PORTFOLIO MANAGER
          OR TAX ADVISOR. YOU ACKNOWLEDGE AND AGREE THAT CRODL & CRODL TRADING
          BOT OR APPLICATION IS NOT RESPONSIBLE FOR YOUR USE OF ANY INFORMATION
          THAT YOU OBTAIN ON THE SOFTWARE. YOUR DECISIONS MADE IN RELIANCE ON
          THE PRODUCTS OR SERVICES OR YOUR INTERPRETATIONS OF THE DATA ARE YOUR
          OWN FOR WHICH YOU HAVE FULL RESPONSIBILITY. YOU EXPRESSLY AGREE THAT
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. YOU ACKNOWLEDGE AND
          AGREE THAT YOU MAY LOSE SOME OR ALL OF YOUR FUNDS AND THE FUNDS OF
          YOUR CLIENTS IF APPLICABLE. CRYPTOCURRENCIES ARE A NEW AND
          INSUFFICIENTLY TESTED TECHNOLOGY. IN ADDITION TO THE RISKS INCLUDED
          HEREIN, THERE ARE OTHER RISKS ASSOCIATED WITH YOUR USE OF THE
          SOFTWARE, AND THE PURCHASE, HOLDING AND USE OF CRYPTOCURRENCIES,
          INCLUDING THOSE THAT CRODL & CRODL TRADING BOT OR APPLICATION CANNOT
          ANTICIPATE. SUCH RISKS MAY FURTHER MATERIALIZE AS UNANTICIPATED
          VARIATIONS OR COMBINATIONS OF THE RISKS DISCUSSED HEREIN. 3.1.
          MARKETPLACE SIGNALS SIGNALS PROVIDER DOES NOT PUBLISH ADVICE ABOUT
          USING CRYPTOCURRENCY OR TRADING CRYPTOCURRENCY. WHILE THE WEBSITE
          CONTAINS INFORMATION ON AN ALGORITHMIC INDICATOR CREATED BY SIGNALS
          PROVIDER, IT IS NOT GIVING ITS READER ANY FINANCIAL ADVICE. WE ARE NOT
          LIABLE FOR ANY DATA THE INDICATOR PUBLISHES AS THEY ARE FOR
          INFORMATIONAL PURPOSES ONLY. WE WILL NOT BE LIABLE, WHETHER IN
          CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, IN RESPECT OF ANY
          DAMAGE, EXPENSE OR OTHER LOSS YOU MAY SUFFER ARISING OUT OF SUCH
          INFORMATION OR ANY RELIANCE YOU MAY PLACE UPON SUCH INFORMATION. OUR
          CONTENT IS INTENDED TO BE USED AND MUST BE USED FOR INFORMATIONAL
          PURPOSES ONLY. IT IS VERY IMPORTANT TO DO YOUR OWN ANALYSIS BEFORE
          MAKING ANY INVESTMENT BASED ON YOUR OWN PERSONAL CIRCUMSTANCES. YOU
          SHOULD TAKE INDEPENDENT FINANCIAL ADVICE FROM A PROFESSIONAL IN
          CONNECTION WITH, OR INDEPENDENTLY RESEARCH AND VERIFY, ANY INFORMATION
          THAT YOU FIND ON SIGNALS PROVIDER AND WISH TO RELY UPON, WHETHER FOR
          THE PURPOSE OF MAKING AN INVESTMENT DECISION OR OTHERWISE. PAST
          PERFORMANCES BY THE ALGORITHMIC INDICATOR IS NOT A GUIDE TO THE
          FUTURE. FOR THE AVOIDANCE OF ANY DOUBT, SIGNALS PROVIDER AND ANY
          ASSOCIATED COMPANIES, OR EMPLOYEES, DO NOT HOLD THEMSELVES OUT AS
          COMMODITY TRADING ADVISORS (“CTAS”) OR AUTHORIZED FINANCIAL ADVISORS
          (“AFAS”). GIVEN THIS REPRESENTATION, ALL INFORMATION, DATA AND
          MATERIAL PROVIDED BY SIGNALS PROVIDER AND ANY ASSOCIATED COMPANIES, OR
          EMPLOYEES, IS FOR EDUCATIONAL PURPOSES ONLY AND SHOULD NOT BE
          CONSIDERED SPECIFIC INVESTMENT ADVICE. PROVISION OF THE SOFTWARE YOU
          AGREE THAT YOUR ORDER IS AN OFFER TO BUY, UNDER THESE TERMS OF USE,
          ALL PRODUCTS AND SERVICES LISTED IN YOUR ORDER. ALL ORDERS MUST BE
          ACCEPTED BY US OR WE WILL NOT BE OBLIGATED TO SELL THE PRODUCTS OR
          SERVICES TO YOU. WE MAY CHOOSE NOT TO ACCEPT ORDERS AT OUR SOLE
          DISCRETION, EVEN AFTER WE SEND YOU A CONFIRMATION EMAIL WITH YOUR
          ORDER NUMBER AND DETAILS OF THE ITEMS YOU HAVE ORDERED. UPON ACCEPTING
          YOUR ORDER, CRODL & CRODL TRADING BOT OR APPLICATION GRANTS YOU A
          NON-EXCLUSIVE, NON-TRANSFERABLE, NON-SUBLICENSABLE, REVOCABLE, LIMITED
          LICENSE TO USE THE SOFTWARE WHEN YOU COMPLETE AND SUBMIT THE SIGN-UP
          FORM ON THE SOFTWARE AND ONLY AS PERMITTED BY THESE TERMS OF USE. YOU
          ACKNOWLEDGE AND AGREE THAT THE FORM AND NATURE OF THE SOFTWARE (OR ANY
          FEATURES WITHIN THE SOFTWARE) MAY CHANGE FROM TIME TO TIME WITHOUT
          PRIOR NOTICE TO YOU. YOU ACKNOWLEDGE AND AGREE THAT WE MAY STOP
          (PERMANENTLY OR TEMPORARILY) PROVIDING THE SOFTWARE (OR ANY FEATURES
          WITHIN THE SOFTWARE) TO YOU OR TO USERS GENERALLY, AT OUR SOLE
          DISCRETION, WITHOUT PRIOR NOTICE TO YOU. YOU MAY STOP USING THE
          SOFTWARE AT ANY TIME IN ACCORDANCE WITH THE TERMS OF THESE TERMS OF
          USE. YOU ACKNOWLEDGE THAT CRODL & CRODL TRADING BOT OR APPLICATION API
          IS AN INTEGRAL PART OF THE SOFTWARE AND IS SUBJECTED TO ALL THE
          PROVISIONS OF THESE TERMS OF USE INCLUDING THE LIABILITY FOR ILLEGAL
          USE OF COMPUTER SOFTWARE. PLEASE BE INFORMED THAT AN ACCESS TO REGULAR
          AND DOCUMENTED FEATURES OF API MAY BECOME AVAILABLE AS AUTOMATICALLY
          AFTER REGISTERING YOUR CLIENT ACCOUNT AS UPON YOUR REQUEST TO CRODL &
          CRODL TRADING BOT OR APPLICATION SUPPORT SERVICE. YOU AGREE TO USE THE
          API SOLELY FOR DEVELOPING AND ESTABLISHING AN ELECTRONIC CONNECTION TO
          THIRD-PARTY SERVICES THAT: (I) HAVE PROVIDED SUCH FUNCTIONALITY AS THE
          REGULAR FEATURE OF THEIR SOFTWARE OR INFORMATION SYSTEM; (II) HAVE
          GRANTED AND AUTHORIZED YOU WITH SUCH ACCESS; (III) HAVE REGULATED
          COOPERATION AND/OR HAVE CONCLUDED AGREEMENTS WITH YOU IN ACCORDANCE
          WITH THE LAWS OF YOUR RESPECTIVE JURISDICTION. PRICES, PAYMENT TERMS
          AND REFUNDS ALL PRICES, DISCOUNTS, AND PROMOTIONS POSTED ON THE
          SOFTWARE ARE SUBJECT TO CHANGE WITHOUT NOTICE. THE PRICE CHARGED FOR A
          PRODUCT OR SERVICE WILL BE THE PRICE ADVERTISED ON THE SOFTWARE AT THE
          TIME THE ORDER IS PLACED, SUBJECT TO THE TERMS OF ANY PROMOTIONS OR
          DISCOUNTS, YOUR GEOGRAPHICAL LOCATION OR RESIDENCE, CHOSEN PAYMENT
          METHOD. YOU WILL BE CHARGED THE PRICE ADVERTISED AT THE TIME THE ORDER
          IS PLACED, IN ACCORDANCE WITH THE CHOSEN FREQUENCY OF PAYMENT
          (MONTHLY, QUARTERLY, SEMIANNUALLY, YEARLY ETC.) UNTIL TERMINATED AS
          OUTLINED UNDER THESE TERMS OF USE. OUR PRICE CHARGED WILL BE CLEARLY
          STATED IN THE TRANSACTIONS HISTORY SECTION OF THE SOFTWARE AFTER
          COMPLETION AND CONFIRMATION OF EACH TRANSACTION BY THE THIRD PARTY
          PAYMENT SERVICE PROVIDER. IF WE INCREASE OUR PRICES, THAT INCREASE
          WILL ONLY APPLY TO PURCHASES MADE AFTER THE DATE THE INCREASE COMES
          INTO EFFECT. THE PRICES POSTED IN THE SOFTWARE MAY NOT INCLUDE
          APPLICABLE DISCOUNTS OR TAXES UNTIL THE PROFILE DATA IN YOUR CLIENT
          ACCOUNT IS FULLY COMPLETED BY YOU. WE STRIVE TO DISPLAY ACCURATE PRICE
          INFORMATION, HOWEVER, WE MAY, ON OCCASION, MAKE INADVERTENT
          TYPOGRAPHICAL ERRORS, INACCURACIES OR OMISSIONS RELATED TO PRICING AND
          AVAILABILITY. WE RESERVE THE RIGHT TO CORRECT ANY ERRORS,
          INACCURACIES, OR OMISSIONS AT ANY TIME AND TO CANCEL ANY ORDERS
          ARISING FROM SUCH OCCURRENCES. ADDITIONAL CHARGES OR TAXES MAY BE
          APPLIED BY YOUR PAYMENT SERVICE PROVIDER, ISSUER BANK OR INTERMEDIARY
          WHICH ARE BEYOND OUR CONTROL. YOU MUST INITIATE PAYMENT OR
          UNCONDITIONALLY AGREE TO ADVANCE DEBIT FOR THE CHARGES RELATED TO THE
          SOFTWARE OR OTHER PRODUCTS OR SERVICES WHEN SUBMITTING THE ORDER. IF
          YOU DO NOT COMPLETE THIS PAYMENT TO OUR SATISFACTION, WE WILL CANCEL
          YOUR ORDER. YOU CAN USE ANY AVAILABLE AND THE MOST CONVENIENT PAYMENT
          METHOD CURRENTLY AVAILABLE IN THE SOFTWARE FOR ALL PURCHASES. HOWEVER,
          CRODL & CRODL TRADING BOT OR APPLICATION DOES NOT GUARANTEE THE
          AVAILABILITY OF ANY PAYMENT METHOD AT ANY MOMENT. CRODL & CRODL
          TRADING BOT OR APPLICATION MAY ADD, REMOVE OR SUSPEND ANY PAYMENT
          METHOD TEMPORARILY OR PERMANENTLY BY ITS OWN DISCRETION. ANY PAYMENTS
          YOU MAKE IN THE SOFTWARE AND FOR THE SOFTWARE MAY BE SUBJECTED TO VAT
          (VALUE ADDED TAX) WITH APPROPRIATE RATE AND IN ACCORDANCE WITH THE LAW
          OF JURISDICTION YOU ESTABLISHED. CRODL & CRODL TRADING BOT OR
          APPLICATION ACCRUES AND CHARGES VAT FOR YOUR PAYMENTS BASING ON PLACE
          YOU ESTABLISHED, THAT IS AUTOMATICALLY DETERMINED BY IP ADDRESS OF
          YOUR DEVICE AND/OR MANUALLY PROVIDED BY YOU TO CRODL & CRODL TRADING
          BOT OR APPLICATION WHEN ENTERING BILLING ADDRESS. IF YOU DISAGREE WITH
          THE INFORMATION THAT WAS DETERMINED AUTOMATICALLY, YOU ARE OBLIGED TO
          PROVIDE US YOUR BILLING ADDRESS, GIVEN THAT THE SOFTWARE WILL BE USED
          AT THIS LOCATION: BY ENTERING THE ADDRESS DATA IN THE SOFTWARE WHEN
          PROCEEDING WITH THE PAYMENT; AND BY SENDING US A VALID PROOF OF THIS
          RESIDENTIAL ADDRESS AFTERWARDS. YOU REPRESENT AND WARRANT THAT: (I)
          THE PAYMENT INFORMATION YOU SUPPLY TO US AND ANY PAYMENT SERVICE
          PROVIDER (“PSP”) WE MAY USE IS TRUE, CORRECT AND COMPLETE, (II) YOU
          ARE DULY AUTHORIZED TO USE SUCH PAYMENT METHOD FOR THE PURCHASE, (III)
          CHARGES INCURRED BY YOU WILL BE HONOURED BY THE ISSUER OF YOUR PAYMENT
          METHOD, AND (IV) YOU WILL PAY CHARGES INCURRED BY YOU AT THE POSTED
          PRICES, INCLUDING ALL APPLICABLE TAXES, IF ANY, REGARDLESS OF THE
          AMOUNT QUOTED ON THE SOFTWARE AT THE TIME OF YOUR ORDER. UNLESS
          OTHERWISE REQUIRED BY LAW, WE HAVE NO OBLIGATION TO PROVIDE A REFUND
          OR A CREDIT. DUE TO THE NATURE OF THE SOFTWARE AS A DIGITAL PRODUCT,
          NO REFUNDS ARE GRANTED WITHOUT CLEAR, JUSTIFIED AND LEGITIMATE
          REASONS. WE WILL ASSESS ANY REFUND REQUEST FOR FEES PAYABLE IN ADVANCE
          ON ITS MERITS AND IN THE MANNER SET FORTH IN THESE TERMS OF USE AND
          OUR REFUND POLICY AT HTTPS://CRODL.COM YOU UNDERSTAND THAT YOU ARE
          PURCHASING THE SOFTWARE FROM CRODL & CRODL TRADING BOT OR APPLICATION
          VIA A PSP. UNLESS OTHERWISE REQUIRED BY LAW, YOU ARE OBLIGATED TO
          CONTACT CRODL & CRODL TRADING BOT OR APPLICATION SUPPORT SERVICE FOR
          ANY ISSUES RELATED TO PAYMENT TRANSACTIONS BEFORE CONTACTING THE PSP
          OR FINANCIAL INSTITUTION. YOU ARE NOT TO OPEN MULTIPLE DISPUTES OR
          SUBMIT MULTIPLE APPEALS TO CRODL & CRODL TRADING BOT OR APPLICATION OR
          ELSEWHERE AT THE SAME TIME. ACCOUNTS TO USE CERTAIN FEATURES ON THE
          SOFTWARE, YOU MUST REGISTER FOR A CLIENT ACCOUNT AND COMPLETE A
          PROFILE. YOU MUST BE OVER THE AGE OF EIGHTEEN (18) TO REGISTER FOR A
          CLIENT ACCOUNT. YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT THE USE
          OF THE SOFTWARE IN ACCORDANCE WITH THESE TERMS OF USE IN YOUR
          JURISDICTION OF RESIDENCE IS PERMITTED BY LAW OR REGULATION. IF SUCH
          USE IS NOT PERMITTED BY LAW, WE PROHIBIT ALL ACCESS TO AND USE OF THE
          SOFTWARE. AS PART OF THE REGISTRATION PROCESS, YOU WILL BE REQUIRED TO
          PROVIDE US WITH CERTAIN INFORMATION, SUCH AS YOUR EMAIL ADDRESS AND A
          PASSWORD. FOR MORE INFORMATION ABOUT THE INFORMATION THAT WE COLLECT,
          PLEASE SEE OUR PRIVACY POLICY AT HTTPS://CRODL.COM/TERMS-CONDITIONS/
          YOU AGREE THAT YOU WILL PROVIDE ACCURATE, CURRENT AND COMPLETE
          INFORMATION ABOUT YOURSELF AND PROMPTLY UPDATE ALL INFORMATION IN YOUR
          CLIENT ACCOUNT TO ENSURE THAT YOUR CLIENT ACCOUNT IS ACCURATE, CURRENT
          AND COMPLETE. YOU MAY UPDATE OR CHANGE YOUR CLIENT ACCOUNT SETTINGS AT
          ANY TIME. YOU ARE NOT PERMITTED TO PROVIDE ANY UNAUTHORIZED PERSON
          WITH ACCESS TO YOUR CLIENT ACCOUNT. IF YOU ARE CREATING A CLIENT
          ACCOUNT ON BEHALF OF A COMPANY, YOU REPRESENT AND WARRANT THAT YOU
          HAVE BEEN AUTHORIZED AND HAVE THE NECESSARY AUTHORITY TO CREATE THE
          CLIENT ACCOUNT AND THAT YOU HAVE SHARED ALL EMAIL ADDRESSES, PASSWORDS
          AND ACCESS CREDENTIALS WITH OTHER AUTHORIZED REPRESENTATIVES OF THE
          COMPANY. 6.1 ACCOUNT CONFIDENTIALITY ANY USER NAME, PASSWORD, OR ANY
          OTHER PIECE OF INFORMATION CHOSEN BY YOU OR PROVIDED TO YOU AS PART OF
          OUR SECURITY PROCEDURES, MUST BE TREATED AS CONFIDENTIAL, AND YOU MUST
          NOT DISCLOSE IT TO ANY OTHER PERSON OR ENTITY. YOU MUST EXERCISE
          CAUTION WHEN ACCESSING YOUR ACCOUNT FROM A PUBLIC OR SHARED COMPUTER
          SO THAT OTHERS ARE NOT ABLE TO VIEW OR RECORD YOUR PASSWORD OR OTHER
          PERSONAL INFORMATION. YOU UNDERSTAND AND AGREE THAT, SHOULD YOU BE
          PROVIDED A CLIENT ACCOUNT, YOUR CLIENT ACCOUNT IS PERSONAL TO YOU AND
          YOU AGREE NOT TO PROVIDE ANY OTHER PERSON WITH ACCESS TO THE SOFTWARE
          OR PORTIONS OF IT USING YOUR USER NAME, PASSWORD, OR OTHER SECURITY
          INFORMATION. YOU AGREE TO NOTIFY US IMMEDIATELY OF ANY UNAUTHORIZED
          ACCESS TO OR USE OF YOUR USER NAME OR PASSWORD OR ANY OTHER BREACH OF
          SECURITY. YOU ALSO AGREE TO ENSURE THAT YOU LOGOUT FROM YOUR ACCOUNT
          AT THE END OF EACH SESSION. YOU ARE RESPONSIBLE FOR ANY PASSWORD
          MISUSE OR ANY UNAUTHORIZED ACCESS. YOU ACKNOWLEDGE AND AGREE THAT WE
          SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
          DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY, OR IN CONNECTION
          WITH THE UNAUTHORIZED USE OF YOUR CLIENT ACCOUNT ARISING FROM A
          FAILURE ON YOUR PART TO MAINTAIN THE CONFIDENTIALITY OF YOUR PASSWORD.
          6.2 TRIAL OR PREVIEW ACCOUNT IF YOU HAVE ELECTED TO USE A FREE TRIAL,
          WE WILL MAKE THE SOFTWARE AVAILABLE TO YOU ON A PREVIEW OR TRIAL BASIS
          FREE OF CHARGE (“TRIAL”). BY PARTICIPATING IN THIS TRIAL, YOU AGREE TO
          BE BOUND BY OUR TERMS OF USE AND THE FOLLOWING ADDITIONAL TERMS IN
          THIS SUBSECTION 6.2 (“TRIAL TERMS”), PROVIDED THAT IN THE EVENT OF A
          CONFLICT THE TRIAL TERMS SHALL GOVERN. THE TRIAL PERIOD WILL BE UNTIL
          THE EARLIER OF (A) THE END OF THE PREVIEW OR FREE TRIAL PERIOD FOR
          WHICH YOU REGISTERED, OR (B) THE START DATE OF ANY PURCHASED SOFTWARE
          SUBSCRIPTION, OR (C) TERMINATION AT ANY TIME AT THE SOLE DISCRETION OF
          CRODL & CRODL TRADING BOT OR APPLICATION. AT THE END OF THE TRIAL, ANY
          TRADES YOU INITIATED THROUGH THE SOFTWARE DURING THE TRIAL WILL
          CONTINUE BUT MAY TAKE LONGER TO COMPLETE AND YOU WILL NO LONGER HAVE
          ACCESS TO ANY DATA YOU ENTERED INTO YOUR CLIENT ACCOUNT AND SUCH DATA
          WILL BE LOST UNLESS YOU UPGRADE TO A PAID SUBSCRIPTION PRIOR TO THE
          EXPIRATION OF THE TRIAL. WE RESERVE THE RIGHT, IN OUR ABSOLUTE
          DISCRETION, TO DETERMINE YOUR ELIGIBILITY FOR A TRIAL, AND, SUBJECT TO
          APPLICABLE LAWS, TO WITHDRAW OR TO MODIFY A TRIAL AT ANY TIME WITHOUT
          PRIOR NOTICE AND WITH NO LIABILITY, TO THE GREATEST EXTENT PERMITTED
          UNDER THE LAW. 6.3 CLIENT ACCOUNT FUNCTIONALITIES THE SPECIFIC CLIENT
          ACCOUNT FUNCTIONALITIES WILL BE THOSE ADVERTISED ON THE SOFTWARE AT
          THE TIME THE ORDER IS PLACED. 6.4 ACCOUNT TERMINATION AND CANCELLATION
          YOU HAVE THE RIGHT TO CLOSE YOUR CLIENT ACCOUNT AT ANY TIME ONCE ALL
          OBLIGATIONS ASSOCIATED WITH THE CLIENT ACCOUNT HAVE BEEN COMPLETED.
          PLEASE SEE SECTION 17 FOR MORE DETAILS. YOU ACKNOWLEDGE AND AGREE THAT
          IF WE DISABLE ACCESS TO YOUR CLIENT ACCOUNT, YOU MAY BE PREVENTED FROM
          ACCESSING THE SOFTWARE. USE OF THE SOFTWARE BY YOU YOUR USE OF THE
          SOFTWARE IS SUBJECT TO ALL APPLICABLE LOCAL, PROVINCIAL AND FEDERAL
          LAWS AND REGULATIONS. UNLESS YOU HAVE BEEN SPECIFICALLY PERMITTED TO
          DO SO IN A SEPARATE AGREEMENT WITH US, YOU AGREE THAT YOU WILL NOT
          REPRODUCE, DUPLICATE, COPY, SELL, TRADE OR RESELL THE CONTENTS
          (DEFINED BELOW IN SECTION 12) OR ANY OF OUR PRODUCTS AND SERVICES FOR
          ANY PURPOSE. YOU AGREE THAT YOU WILL NOT USE THE SOFTWARE TO: ACCESS
          CONTENT AND DATA THAT IS NOT INTENDED FOR YOU; RESTRICT DISRUPT OR
          DISABLE SERVICE TO USERS, HOSTS, SERVERS OR NETWORKS OR OTHERWISE
          ATTEMPT TO INTERFERE WITH THE PROPER WORKING OF THE SOFTWARE; PROMOTE
          OR ENGAGE IN ANY UNLAWFUL OR ILLEGAL ACTIVITIES OR INTERNET ABUSE,
          INCLUDING UNSOLICITED ADVERTISING AND SPAM; PROMOTE, UPLOAD, POST, OR
          OTHERWISE MAKE AVAILABLE ANY MATERIAL THAT IS ABUSIVE, HARASSING,
          OBSCENE, VULGAR, HATEFUL, SEXUALLY EXPLICIT, INVASIVE OF ANOTHER’S
          PRIVACY, DEFAMATORY OR OTHERWISE OBJECTIONABLE OR ILLEGAL, OR THAT
          DISCRIMINATES BASED ON RACE, GENDER, RELIGION, NATIONALITY,
          DISABILITY, SEXUAL ORIENTATION, AGE, FAMILY STATUS OR ANY OTHER
          LEGALLY PROTECTED CLASS; UPLOAD, POST OR OTHERWISE MAKE AVAILABLE ANY
          MATERIAL THAT CONTAINS VIRUSES, WORMS, MALWARE OR OTHER MALICIOUS
          SOFTWARE; UPLOAD, POST, OR OTHERWISE MAKE AVAILABLE ANY MATERIAL THAT
          YOU DO NOT HAVE A RIGHT TO MAKE AVAILABLE OR THAT INFRINGES ANY
          PATENT, TRADE-MARK, TRADE SECRET, COPYRIGHT OR OTHER PROPRIETARY
          RIGHTS OF ANY PERSON. YOU SHALL BE SOLELY LIABLE FOR ANY DAMAGE
          RESULTING FROM ANY INFRINGEMENT OF THE INTELLECTUAL PROPERTY OF ANY
          THIRD-PARTY; CREATE A FALSE OR CLEARLY FICTIONAL PROFILE; ENGAGE IN
          ANY ACTIVITY THAT INTERFERES WITH OR DISRUPTS THE USE OF THE SOFTWARE,
          INCLUDING BUT NOT LIMITED TO HACKING OR BYPASSING ANY MEASURES WE MAY
          USE TO PREVENT UNAUTHORIZED ACCESS TO THE SOFTWARE; ENGAGE IN ANY
          ACTIVITY THAT ATTEMPTS TO REVERSE ENGINEER, DISASSEMBLE, DECOMPILE,
          HACK OR EXTRACT ANY PROPRIETARY SOFTWARE USED TO MAINTAIN THE
          SOFTWARE; TRACK, TRACE OR HARVEST ANY INFORMATION ON THE SOFTWARE OR
          ANY OTHER PERSON WHO VISITS THE SOFTWARE; OR SELL, DISTRIBUTE, COPY,
          SUB-LICENSE, LOAN, TRANSFER, DUPLICATE, REPRODUCE, TRADE, RESELL, OR
          OTHERWISE MONETIZE THE SOFTWARE WITHOUT OUR CONSENT. YOU AGREE THAT
          YOU ARE SOLELY RESPONSIBLE FOR (AND THAT WE HAVE NO RESPONSIBILITY TO
          YOU OR TO ANY THIRD PARTY FOR) ANY BREACH OF YOUR OBLIGATIONS UNDER
          THESE TERMS OF USE AND FOR THE CONSEQUENCES (INCLUDING ANY LOSS OR
          DAMAGE WHICH WE MAY SUFFER) OF ANY SUCH BREACH. USE OF THE SOFTWARE
          THROUGH MOBILE DEVICES YOU MAY ACCESS AND USE THE SOFTWARE THROUGH A
          MOBILE DEVICE, INCLUDING THROUGH A MOBILE APPLICATION. YOU ARE SOLELY
          RESPONSIBLE FOR ANY PREREQUISITE SOFTWARE AND HARDWARE REQUIREMENTS
          AND FOR ANY DATA CHARGES AND FEES ASSOCIATED WITH ACCESSING AND USING
          THE SOFTWARE THROUGH A MOBILE DEVICE. CLIENT DATA 9.1 OWNERSHIP OF
          CLIENT DATA AS BETWEEN CRODL & CRODL TRADING BOT OR APPLICATION AND
          CLIENT, CLIENT EXCLUSIVELY OWNS ALL RIGHTS, TITLE AND INTEREST IN AND
          TO ALL DATA SUBMITTED BY OR FOR CLIENT TO THE SOFTWARE OR COLLECTED
          AND PROCESSED BY OR FOR CLIENT USING THE SOFTWARE (“CLIENT DATA”).
          CRODL & CRODL TRADING BOT OR APPLICATION DOES NOT ACQUIRE ANY RIGHTS,
          TITLE OR OWNERSHIP INTEREST OF ANY KIND WHATSOEVER, EXPRESS OR
          IMPLIED, IN ANY OF THE CLIENT DATA. 9.2 AGGREGATED DATA CLIENT GRANTS
          CRODL & CRODL TRADING BOT OR APPLICATION A NON-EXCLUSIVE,
          TRANSFERABLE, ASSIGNABLE, IRREVOCABLE, ROYALTY-FREE, WORLDWIDE,
          PERPETUAL LICENSE TO CREATE AGGREGATED, ANONYMIZED AND DE-IDENTIFIED
          CLIENT DATA (“AGGREGATED DATA”) AND TO USE SUCH CLIENT DATA, AND ALL
          MODIFICATIONS THERETO AND DERIVATIVES THEREOF, FOR ANY PURPOSE,
          INCLUDING, WITHOUT LIMITATION, TO IMPROVE THE SOFTWARE, CONDUCT
          RESEARCH, DEVELOP NEW PRODUCTS AND SERVICES AND UNDERSTAND USAGE AND
          FOR PREDICTIVE ANALYTICS AND INSIGHTS. CRODL & CRODL TRADING BOT OR
          APPLICATION SHALL OWN ALL AGGREGATED DATA AND MAY TRANSFER OR ASSIGN
          ANY OF ITS RIGHTS IN THE AGGREGATED DATA TO ANY THIRD PARTY. CRODL &
          CRODL TRADING BOT OR APPLICATION WARRANTS AND REPRESENTS THAT IT IS
          USING METHODS AND PROCESSES THAT ARE MEANT TO ENSURE THE
          IRREVERSIBILITY OF THE AGGREGATED DATA. LINKS TO THIRD-PARTY PLATFORMS
          AND TRADER BOT INFORMATION USE OF CERTAIN LINKS ON THE SOFTWARE WILL
          DIRECT YOU TO THIRD PARTY (INCLUDING BUT NOT LIMITED TO ENTERPRISE
          PARTNERS) FEEDS, SOFTWARE, WEBSITES OR MOBILE APPLICATIONS
          (COLLECTIVELY, “THIRD PARTY PLATFORMS”). SUCH THIRD PARTY PLATFORMS
          ARE NOT UNDER THE CONTROL OF CRODL & CRODL TRADING BOT OR APPLICATION,
          AND CRODL & CRODL TRADING BOT OR APPLICATION IS NOT RESPONSIBLE FOR
          THE CONTENTS OF ANY SUCH THIRD PARTY PLATFORMS OR ANY LINK CONTAINED
          IN SUCH THIRD PARTY PLATFORM. LINKS TO THIRD PARTY PLATFORMS INCLUDED
          ON THE SOFTWARE ARE PROVIDED FOR YOUR CONVENIENCE, AND THE INCLUSION
          OF SUCH LINKS DOES NOT IMPLY A RECOMMENDATION OR ENDORSEMENT BY US OF
          ANY SUCH THIRD PARTY PLATFORM OR THE PRODUCTS OR SERVICES OR
          INFORMATION OFFERED THEREIN. IF YOU DECIDE TO ACCESS ANY THIRD PARTY
          PLATFORM INFORMATION LINKED TO THE SOFTWARE, YOU DO SO ENTIRELY AT
          YOUR OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT CRODL & CRODL TRADING
          BOT OR APPLICATION SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
          INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY,
          OR IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY SUCH THIRD PARTY
          PLATFORM OR INFORMATION. PRIVACY AND PERSONAL INFORMATION BY ACCESSING
          OR USING THE SOFTWARE, YOU UNDERSTAND THAT WE WILL COLLECT AND USE
          CERTAIN INFORMATION ABOUT YOU, INCLUDING PERSONAL INFORMATION. FOR
          MORE INFORMATION ABOUT OUR COLLECTION, USE, DISCLOSURE AND PROTECTION
          OF YOUR PERSONAL INFORMATION, PLEASE READ OUR PRIVACY POLICY AT
          HTTPS://CRODL.COM/TERMS-CONDITIONS/ . QUESTIONS OR REQUESTS WITH
          RESPECT TO YOUR PERSONAL INFORMATION MAY BE SENT VIA EMAIL TO
          CRODLTRADING@GMAIL.COM. OWNERSHIP OF INTELLECTUAL PROPERTY RIGHTS THE
          CONTENTS OF THE SOFTWARE INCLUDE, WITHOUT LIMITATION, ALL INFORMATION,
          DATA, PRODUCTS, MATERIALS, SERVICES, SOFTWARE APPLICATIONS AND TOOLS,
          APIS, DESIGN ELEMENTS, TEXT, IMAGES, PHOTOGRAPHS, ILLUSTRATIONS, AUDIO
          AND VIDEO CONTENTS, ARTWORK AND GRAPHICS CONTAINED THEREIN OR
          OTHERWISE MADE AVAILABLE TO YOU IN CONNECTION THEREWITH (COLLECTIVELY
          THE “CONTENTS”) AND, UNLESS THE CONTEXT CLEARLY REQUIRES OTHERWISE, OR
          WE EXPLICITLY SAY SO IN WRITING, THE TERM SOFTWARE INCLUDES ALL OF THE
          CONTENTS. THE SOFTWARE TOGETHER WITH ALL TRADE-MARKS AND OTHER
          INTELLECTUAL PROPERTY DISPLAYED, DISTRIBUTED, OR OTHERWISE MADE
          AVAILABLE VIA THE SOFTWARE, IS THE EXCLUSIVE PROPERTY OF CRODL & CRODL
          TRADING BOT OR APPLICATION, AND ITS SUCCESSORS, ASSIGNS, LICENSORS,
          AND/OR SUPPLIERS. UNLESS YOU HAVE AGREED OTHERWISE IN WRITING WITH
          CRODL & CRODL TRADING BOT OR APPLICATION, NOTHING IN THESE TERMS OF
          USE GIVES YOU A RIGHT TO USE ANY OF THE CONTENTS, CRODL & CRODL
          TRADING BOT OR APPLICATION’ TRADE-MARKS OR OTHER INTELLECTUAL PROPERTY
          OF CRODL & CRODL TRADING BOT OR APPLICATION. YOU MAY NOT ASSIGN OR
          TRANSFER ANY OF THE CONTENTS AND YOU MAY NOT GRANT A LICENSE TO USE OR
          ACCESS THE SOFTWARE TO ANY PARTY. NO INFORMATION OR STATEMENT
          CONTAINED IN THESE TERMS OF USE OR THE SOFTWARE SHALL BE CONSTRUED AS
          CONFERRING, DIRECTLY OR BY IMPLICATION, ESTOPPEL OR OTHERWISE, ANY
          LICENSE OR RIGHT UNDER ANY PATENT, COPYRIGHT, TRADE-MARK, OR OTHER
          INTELLECTUAL PROPERTY RIGHT OF CRODL & CRODL TRADING BOT OR
          APPLICATION OR ANY THIRD PARTY. YOU MUST NOT ALTER, DELETE, OR CONCEAL
          ANY COPYRIGHT OR OTHER NOTICES CONTAINED ON THE SOFTWARE, INCLUDING
          NOTICES ON ANY OF THE CONTENTS THAT YOU ARE PERMITTED TO DOWNLOAD,
          TRANSMIT, DISPLAY, PRINT, OR REPRODUCE FROM THE SOFTWARE. ANY
          UNAUTHORIZED OR PROHIBITED USE OF ANY CONTENTS MAY SUBJECT YOU TO
          CIVIL LIABILITY, CRIMINAL PROSECUTION, OR BOTH, UNDER APPLICABLE
          FEDERAL, PROVINCIAL, STATE AND LOCAL LAWS. THE LIMITED RIGHTS GRANTED
          TO YOU UNDER THESE TERMS OF USE MAY BE REVOKED BY US AT ANY TIME FOR
          ANY REASON WHATSOEVER. THIRD-PARTY INFORMATION ANY THIRD-PARTY
          CONTENT, DATA, INFORMATION, OR PUBLICATIONS MADE AVAILABLE THROUGH THE
          SOFTWARE ARE FURNISHED BY US ON AN AS-IS BASIS FOR YOUR CONVENIENCE
          AND INFORMATION. ANY OPINIONS, ADVICE, STATEMENTS, SERVICES, OFFERS,
          OR OTHER INFORMATION MADE AVAILABLE BY THIRD PARTIES ARE THOSE OF THE
          RESPECTIVE AUTHOR(S) OR PUBLISHER(S), ENTERPRISE PARTNER(S), AND NOT
          OF CRODL & CRODL TRADING BOT OR APPLICATION. CRODL & CRODL TRADING BOT
          OR APPLICATION DISCLAIMS ANY WARRANTY OR REPRESENTATION, EITHER
          EXPRESS OR IMPLIED, THAT THE INFORMATION IN SUCH PUBLICRODL TRADING
          BOT OR APPLICATIONIONS IS ACCURATE OR COMPLETE. WARRANTY DISCLAIMER TO
          THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY
          PROVIDED HEREIN, YOUR USE OF THE SOFTWARE IS PROVIDED TO YOU “AS IS”
          AND “AS AVAILABLE”. CRODL & CRODL TRADING BOT OR APPLICATION, ITS
          RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS EXPRESSLY
          DISCLAIM ALL OTHER REPRESENTATIONS, ENDORSEMENTS, WARRANTIES, AND
          CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
          REPRESENTATION, WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, COMPLETENESS,
          SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY OR
          AVAILABILITY, ERROR-FREE, UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT OUR SOFTWARE OR THE SERVER THAT MAKES IT AVAILABLE ARE
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR ANY WARRANTY ARISING
          FROM A COURSE OF DEALING, PERFORMANCE, OR TRADE USAGE. YOUR SOLE AND
          EXCLUSIVE REMEDY, AND OUR SOLE OBLIGATION TO YOU OR ANY THIRD PARTY
          FOR ANY CLAIM ARISING OUT OF YOUR USE OF THE SOFTWARE, IS THAT YOU ARE
          FREE TO DISCONTINUE YOUR USE OF THE SOFTWARE AT ANY TIME. CRODL &
          CRODL TRADING BOT OR APPLICATION PROVIDES NO WARRANTIES OR
          REPRESENTATIONS REGARDING THE SOFTWARE INCLUDING BUT NOT LIMITED TO
          THAT (I) THE SOFTWARE WILL MEET YOUR REQUIREMENTS; (II) THE SOFTWARE
          WILL BE UNINTERRUPTED, TIMELY, SECURE OR DEFECTS-FREE; (III) THE
          RESULTS OBTAINED FROM USE OF THE SOFTWARE WILL BE ACCURATE OR
          RELIABLE; OR (IV) THAT ANY AS WELL-KNOW AS STILL NOT DETECTED DEFECTS
          WILL BE CORRECTED. WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT
          FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE
          SOFTWARE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE
          SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE SOFTWARE AND YOUR
          COMPUTER, INTERNET AND DATA SECURITY. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, CRODL & CRODL TRADING BOT OR APPLICATION, ITS
          RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS WILL NOT BE
          LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
          MAILBOMBING OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS,
          OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE SOFTWARE OR ANY SERVICES OR ITEMS
          FOUND OR ATTAINED THROUGH THE SOFTWARE OR TO YOUR DOWNLOADING OF ANY
          MATERIAL POSTED ON IT, OR ON ANY THIRD PARTY PLATFORM LINKED TO IT.
          LIMITATION OF LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
          LAW YOU EXPRESSLY UNDERSTAND AND AGREE THAT CRODL & CRODL TRADING BOT
          OR APPLICATION AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES OR
          AGENTS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE
          INCURRED BY YOU IN CONNECTION WITH YOUR USE OF THE SOFTWARE, HOWEVER
          CAUSED AND UNDER ANY THEORY OF LIABILITY INCLUDING, BUT NOT LIMITED
          TO, ANY LOSS OF PROFIT, LOST OPPORTUNITIES, LOSS OF DATA SUFFERED, OR
          OTHER INTANGIBLE LOSS. CRODL & CRODL TRADING BOT OR APPLICATION
          MAXIMUM AGGREGATE LIABILITY TO YOU SHALL BE LIMITED TO $100.00. THE
          LIMITATIONS ON OUR LIABILITY TO YOU IN THIS SECTION SHALL APPLY
          WHETHER OR NOT WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF
          THE POSSIBILITY OF ANY SUCH LOSSES ARISING. INDEMNIFICATION TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO DEFEND,
          INDEMNIFY, AND HOLD HARMLESS CRODL & CRODL TRADING BOT OR APPLICATION,
          ITS AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
          AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, SUPPLIERS,
          SUCCESSORS, AND ASSIGNS FROM AND AGAINST ANY CLAIMS, LIABILITIES,
          DAMAGES, JUDGMENTS, AWARDS, LOSSES, COSTS, EXPENSES, OR FEES
          (INCLUDING REASONABLE ATTORNEYS’ FEES) ARISING OUT OF OR RELATING TO
          YOUR BREACH OF THESE TERMS OF USE OR YOUR USE OF THE SOFTWARE,
          INCLUDING, BUT NOT LIMITED TO, YOUR SUBMISSIONS, THIRD PARTY
          PLATFORMS, ANY USE OF THE CONTENT, SERVICES, AND PRODUCTS OTHER THAN
          AS EXPRESSLY AUTHORIZED IN THESE TERMS OF USE. TERM, TERMINATION AND
          CANCELLATION THE TERM OF YOUR PAID SUBSCRIPTION AND THE LICENSE
          GRANTED HEREUNDER WILL REMAIN IN EFFECT UNTIL TERMINATED BY EITHER YOU
          OR CRODL & CRODL TRADING BOT OR APPLICATION. WE MAY TERMINATE YOUR USE
          OF THE SOFTWARE AND/OR ACCESS TO THE CONTENTS, APIS, FEATURES,
          FUNCTIONALITY, PRODUCTS AND SERVICES MADE AVAILABLE IN CONNECTION
          THEREWITH, AT ANY TIME AND FOR ANY REASON, WITH OR WITHOUT CAUSE,
          WITHOUT PRIOR NOTICE TO YOU AND WITHOUT ANY LIABILITY OR FURTHER
          OBLIGATION OF ANY KIND WHATSOEVER TO YOU OR ANY OTHER PARTY. USE OF
          CRODL TRADING BOT OR APPLICATION IS DEPENDANT ON BEING LINKED TO THE
          COMPANIES AFFILIATED EXCHANGE OR BROKERAGE ACCOUNTS. IF CRODL & CRODL
          TRADING BOT OR APPLICATION DETECTS UNAUTHORIZED OR SUSPICIOUS ACTIONS
          WITH YOUR CLIENT ACCOUNT, IT MAY BE TEMPORARILY BLOCKED UNTIL ALL THE
          CIRCUMSTANCES HAVE BEEN CLARIFIED AND, IF NECESSARY, KNOW YOUR CLIENT
          PROCEDURES HAVE BEEN CARRIED OUT. A CLIENT ACCOUNT CAN ALSO BE
          TERMINATED IF YOU DO NOT PROVIDE THE REQUIRED CLARIFICATIONS WITHIN
          THE TIME REQUESTED. IF YOU WANT TO TERMINATE YOUR CLIENT ACCOUNT, YOU
          MAY DO SO BY CLOSING YOUR CLIENT ACCOUNT, WHERE WE HAVE MADE THIS
          OPTION AVAILABLE TO YOU. YOUR CLIENT ACCOUNT WILL BE CLOSED WITHIN
          THIRTY (30) DAYS PROVIDED THAT: (I) ANY DISPUTES IN WHICH YOU HAVE
          BEEN INVOLVED HAVE BEEN SATISFACTORILY RESOLVED; AND (II) YOU HAVE
          COMPLETED ANY OTHER OBLIGATION(S) ASSOCIATED WITH YOUR USE OF THE
          SOFTWARE. TO US. TO GIVE US NOTICE UNDER THESE TERMS OF USE, YOU MUST
          CONTACT US BY EMAIL AT CRODLTRADING@GMAIL.COM TO REQUEST THE CONSENT
          OF CRODL & CRODL TRADING BOT OR APPLICATION FOR ANY OF THE ACTIONS FOR
          WHICH SUCH CONSENT IS REQUIRED UNDER THESE TERMS OF USE, PLEASE SEND
          AN EMAIL TO CRODLTRADING@GMAIL.COM. CRODL & CRODL TRADING BOT OR
          APPLICATION RESERVES THE RIGHT TO REFUSE ANY SUCH REQUESTS IN ITS SOLE
          DISCRETION. CRODL CRODLTRADING@GMAIL.COM WHEN THESE TERMS OF USE COME
          TO AN END, ALL OF THE LEGAL RIGHTS, OBLIGATIONS AND LIABILITIES THAT
          YOU AND CRODL & CRODL TRADING BOT OR APPLICATION HAVE BENEFITED FROM,
          BEEN SUBJECT TO (OR WHICH HAVE ACCRUED OVER TIME WHILE THE TERMS OF
          USE HAVE BEEN IN FORCE) OR WHICH ARE EXPRESSED TO CONTINUE
          INDEFINITELY, SHALL BE UNAFFECTED BY SUCH TERMINATION INCLUDING BUT
          NOT LIMITED TO SECTIONS 1, 3, 5, 6.1, 9.1, 9.2, 10 TO 18. SUPPORT AND
          REPORTING WE ONLY PROVIDE SUPPORT SERVICES FOR THE OPERATION OF THE
          SOFTWARE. SHOULD YOU BECOME AWARE OF MISUSE OF THE SOFTWARE INCLUDING
          LIBELOUS OR DEFAMATORY CONDUCT, YOU MUST REPORT IT TO CRODL & CRODL
          TRADING BOT OR APPLICATION. WE RECOMMEND CONTACTING US FOR ASSISTANCE
          IF YOU EXPERIENCE ANY ISSUES REGARDING THE SOFTWARE IN THE FOLLOWING
          WAYS: BY SENDING EMAIL TO CRODLTRADING@GMAIL.COM. GENERAL THESE TERMS
          OF USE, INCLUDING THE PRIVACY POLICY AND ANY OTHER URL INCORPORATED BY
          REFERENCE IN THESE TERMS OF USE CONSTITUTE THE ENTIRE AGREEMENT
          BETWEEN YOU AND CRODL & CRODL TRADING BOT OR APPLICATION RELATING TO
          YOUR USE AND OUR PROVISION OF THE SOFTWARE. THE PARTIES AGREE THAT IF
          A PARTY DOES NOT EXERCISE OR ENFORCE ANY LEGAL RIGHT OR REMEDY WHICH
          IS CONTAINED IN THESE TERMS OF USE (OR WHICH IT HAS THE BENEFIT OF
          UNDER ANY APPLICABLE LAW), THIS WILL NOT BE TAKEN TO BE A FORMAL
          WAIVER OF ITS RIGHTS AND THAT THOSE RIGHTS OR REMEDIES WILL STILL BE
          AVAILABLE TO THE PARTY. IF ANY PROVISION OF THESE TERMS OF USE IS HELD
          TO BE ILLEGAL, INVALID OR UNENFORCEABLE, THIS WILL NOT AFFECT ANY
          OTHER PROVISION OF THESE TERMS OF USE AND THE AGREEMENT BETWEEN YOU
          AND US WILL BE DEEMED AMENDED TO THE EXTENT NECESSARY TO MAKE IT
          LEGAL, VALID, AND ENFORCEABLE. THE PARTIES HERETO CONFIRM THAT THEY
          HAVE REQUESTED THAT THESE TERMS OF USE AND ALL RELATED DOCUMENTS BE
          DRAFTED IN ENGLISH. ANY TRANSLATION HEREOF HAS BEEN PROVIDED FOR
          INFORMATION PURPOSES ONLY AND DOES NOT HAVE ANY LEGAL VALUE NOR CREATE
          ANY CONTRACTUAL RELATIONSHIP BETWEEN THE PARTIES. NO E-MAIL ADDRESS
          FOUND ON THE SOFTWARE MAY BE HARVESTED OR OTHERWISE USED FOR PURPOSES
          OF SOLICITATION. THE RELATIONSHIP BETWEEN THE PARTIES IS THAT OF
          INDEPENDENT CONTRACTORS. NOTHING CONTAINED IN THESE TERMS OF USE SHALL
          BE CONSTRUED AS CREATING ANY AGENCY, PARTNERSHIP, JOINT VENTURE OR
          OTHER FORM OF JOINT ENTERPRISE, EMPLOYMENT OR FIDUCIARY RELATIONSHIP
          BETWEEN THE PARTIES, AND NEITHER PARTY SHALL HAVE AUTHORITY TO
          CONTRACT FOR OR BIND THE OTHER PARTY IN ANY MANNER WHATSOEVER. THESE
          TERMS OF USE WILL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE
          LAWS OF HONG KONG APPLICABLE THEREIN, WITHOUT GIVING EFFECT TO ANY
          CHOICE OR CONFLICT OF LAW PROVISION, PRINCIPLE OR RULE (WHETHER OF THE
          LAWS OF ONTARIO OR ANY OTHER JURISDICTION) AND NOTWITHSTANDING YOUR
          DOMICILE, RESIDENCE OR PHYSICAL LOCATION. ANY ACTION OR PROCEEDING
          ARISING OUT OF OR RELATING TO THE SOFTWARE OR THESE TERMS OF USE WILL
          BE INSTITUTED IN THE COURTS OF HONG KONG AND EACH PARTY IRREVOCABLY
          SUBMITS TO THE EXCLUSIVE JURISDICTION OF SUCH COURTS IN ANY SUCH
          ACTION OR PROCEEDING. YOU WAIVE ANY AND ALL OBJECTIONS TO THE EXERCISE
          OF JURISDICTION OVER YOU BY SUCH COURTS AND TO THE VENUE OF SUCH
          COURTS. YOU WILL NOT ASSIGN ANY OF YOUR RIGHTS OR DELEGATE ANY OF YOUR
          OBLIGATIONS UNDER THESE TERMS OF USE WITHOUT OUR PRIOR WRITTEN
          CONSENT. ANY PURPORTED ASSIGNMENT OR DELEGATION IN VIOLATION OF THIS
          SUBSECTION H) IS NULL AND VOID. NO ASSIGNMENT OR DELEGATION RELIEVES
          YOU OF ANY OF YOUR OBLIGATIONS UNDER THESE TERMS OF USE. NOTICES. TO
          YOU. WE MAY PROVIDE ANY NOTICE TO YOU UNDER THESE TERMS OF USE BY: (I)
          SENDING A MESSAGE TO THE EMAIL ADDRESS YOU PROVIDE TO US AND CONSENT
          TO US USING; OR (II) BY POSTING TO THE SOFTWARE. NOTICES SENT BY EMAIL
          WILL BE EFFECTIVE WHEN WE SEND THE EMAIL AND NOTICES WE PROVIDE BY
          POSTING WILL BE EFFECTIVE UPON POSTING. IT IS YOUR RESPONSIBILITY TO
          KEEP YOUR EMAIL ADDRESS CURRENT AND CHECK FOR INCOMING MESSAGES
          REGULARLY. SOME OF THE INFORMATION AND RESOURCES WAS FOUND ON THE
          INTERNET ON PUBLIC SHARED PLACES. WE DO NOT CLAIM TO OWN ALL THE
          CONTENT THAT WE SHARE AND IT IS AVAILABLE ON THE INTERNET IF YOU LOOK
          FOR IT, WHAT WE HAVE DONE IS MAKE IT SO THAT IT IS EASIER TO LEARN AND
          READ FOR PEOPLE IN OUR GROUP (WE DO NOT CLAIM THAT WE OWN ANY CONTENT
          SHARED ON OUR WEBSITE OR DISCORD GROUP)! PLEASE USE AT OWN RISK AND NO
          REFUNDS!
        </p>
        {/* You can add more HTML and styling as needed */}
      </Container>
    </>
  )
}

export default Disclaimer
