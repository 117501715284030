import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  setPasswordSuccessMsg: null,
  setPasswordError: null,
}

const setPassword = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      return {
        ...state,
        setPasswordSuccessMsg: null,
        setPasswordError: null,
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordSuccessMsg: action.payload,
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        setPasswordError: action.payload,
      };
    default:
      return state;
  }
}

export default setPassword;
