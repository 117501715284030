// import React, { useEffect, useState } from "react"
// //Import Components

// const Notifications = () => {
//   //meta title
//   document.title = "Notifications | Crodl"

//   return (
//     <React.Fragment>
//       <p> Test </p>
//     </React.Fragment>
//   )
// }

// export default Notifications
// import React from "react"
// import { Card, CardBody } from "reactstrap"

// // Dummy version of the TableContainer for demonstration
// const TableContainer = ({ data }) => {
//   return (
//     <table>
//       <thead>
//         <tr>
//           <th>ID</th>
//           <th>Date</th>
//           <th>Amount</th>
//           <th>Status</th>
//         </tr>
//       </thead>
//       <tbody>
//         {data.map((transaction, index) => (
//           <tr key={index}>
//             <td>{transaction.id}</td>
//             <td>{transaction.date}</td>
//             <td>{transaction.amount}</td>
//             <td>{transaction.status}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   )
// }

// const Notifications = () => {
//   // Dummy data for the purpose of demonstration
//   const dummyData = [
//     { id: 1, date: "2024-02-14", amount: "$100", status: "Completed" },
//     { id: 2, date: "2024-02-13", amount: "$200", status: "Pending" },
//     { id: 3, date: "2024-02-12", amount: "$300", status: "Failed" },
//     { id: 4, date: "2024-02-11", amount: "$400", status: "Completed" },
//   ]

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Card>
//           <CardBody>
//             <div className="mb-4 h4 card-title">Latest Transactions</div>
//             <TableContainer data={dummyData} />
//           </CardBody>
//         </Card>
//       </div>
//     </React.Fragment>
//   )
// }

// export default Notifications

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { useMemo } from "react"
import moment from "moment"
import {
  fetchNotifications,
  updateNotification,
  updateAllNotifications,
} from "../../store/actions"
import BybitLogo from "../../assets/images/bybit.svg"
import BinanceLogo from "../../assets/images/binance.svg"
import BitgetLogo from "../../assets/images/bitget.svg"

const Notifications = ({}) => {
  const dispatch = useDispatch()
  const rawNotifications = useSelector(
    state => state.notificationsReducer.notifications
  )

  const notifications = useMemo(
    () => rawNotifications[0]?.notifications || [],
    [rawNotifications]
  )

  const [menu, setMenu] = useState(false)

  // useEffect(() => {
  //   dispatch(fetchNotifications())
  // }, [dispatch])
  const intervalDuration = 1000 * 300 // 'x' is the number of seconds you want between each call

  useEffect(() => {
    // Function to fetch notifications
    const fetchNotificationsPeriodically = () => {
      dispatch(fetchNotifications())
    }

    // Immediately fetch notifications when the component is mounted
    fetchNotificationsPeriodically()

    // Set up the interval to fetch notifications every 'x' seconds
    const interval = setInterval(
      fetchNotificationsPeriodically,
      intervalDuration
    )

    // Clear the interval when the component is unmounted to prevent memory leaks
    return () => clearInterval(interval)
  }, [dispatch]) // dependencies array

  const toggleMenu = () => {
    // Check if the menu is about to be opened
    if (!menu) {
      dispatch(updateAllNotifications()) // Dispatch action to mark all notifications as read
    }
    setMenu(!menu) // Toggle menu state
  }

  const markAsRead = notificationId => {
    console.log("Marking as read:", notificationId) // Check if this log appears
    dispatch(updateNotification(notificationId))
  }

  const parseNotification = notificationString => {
    try {
      // console.log("Parsing notification message:", notificationString)
      return JSON.parse(notificationString)
    } catch (error) {
      console.error("Failed to parse notification message:", notificationString)
      return { header: "Invalid Notification", message: "Content unavailable" }
    }
  }

  const unreadCount = notifications.filter(
    notification => !notification.read
  ).length

  const getHumanReadableDifference = notificationTime => {
    const currentTime = moment() // current time
    const timeMoment = moment(notificationTime) // convert notification time to moment object
    const timeDifference = currentTime.diff(timeMoment) // calculate difference

    // Format the time difference as a human-readable string, e.g., '4 hours ago'
    return moment.duration(timeDifference).humanize() + " ago"
  }
  return (
    <React.Fragment>
      <div className="page-content container-fluid">
        {" "}
        {/* Adjusted class here */}
        {notifications.map(notification => {
          const parsedNotification = JSON.parse(notification.message)

          return (
            <div
              className="notification-item text-reset w-100 p-2"
              key={notification.id}
            >
              {" "}
              {/* Adjusted class here */}
              <div className="d-flex align-items-start">
                <div className="avatar-xs me-3">
                  <span className="rounded-circle">
                    {notification.type_alert === "bybit" ? (
                      <img
                        src={BybitLogo}
                        alt="Bybit"
                        className="img-fluid" // Use Bootstrap's img-fluid for responsive images
                      />
                    ) : notification.type_alert === "binance" ? (
                      <img
                        src={BinanceLogo}
                        alt="Binance"
                        className="img-fluid"
                      />
                    ) : notification.type_alert === "bitget" ? (
                      <img
                        src={BitgetLogo}
                        alt="Bitget"
                        className="img-fluid"
                      />
                    ) : (
                      <i
                        className="bx bx-bell"
                        style={{ fontSize: "1.5rem" }} // Adjusted for responsiveness
                      ></i>
                    )}
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{parsedNotification.header}</h6>
                  <div className="text-muted" style={{ fontSize: "0.875rem" }}>
                    <p className="mb-1">{parsedNotification.message}</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>
                      {getHumanReadableDifference(notification.time)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>

    // <React.Fragment>
    //   <div className="page-content">
    //     {notifications.map(notification => {
    //       const parsedNotification = JSON.parse(notification.message)

    //       return (
    //         <div className="notification-item text-reset" key={notification.id}>
    //           <div className="d-flex align-items-start">
    //             <div className="avatar-xs me-3">
    //               <span className="rounded-circle font-size-16">
    //                 {notification.type_alert === "bybit" ? (
    //                   <img
    //                     src={BybitLogo}
    //                     alt="Bybit"
    //                     style={{ width: "32px", height: "32px" }}
    //                   />
    //                 ) : notification.type_alert === "binance" ? (
    //                   <img
    //                     src={BinanceLogo}
    //                     alt="Binance"
    //                     style={{ width: "24px", height: "24px" }}
    //                   />
    //                 ) : notification.type_alert === "bitget" ? (
    //                   <img
    //                     src={BitgetLogo}
    //                     alt="Bitget"
    //                     style={{ width: "24px", height: "24px" }}
    //                   />
    //                 ) : (
    //                   <i
    //                     className="bx bx-bell"
    //                     style={{ fontSize: "24px" }}
    //                   ></i>
    //                 )}
    //               </span>
    //             </div>
    //             <div className="flex-grow-1">
    //               <h6 className="mt-0 mb-1">{parsedNotification.header}</h6>
    //               <div className="text-muted" style={{ fontSize: "0.875rem" }}>
    //                 <p className="mb-1">{parsedNotification.message}</p>
    //                 <p className="mb-0">
    //                   <i className="mdi mdi-clock-outline"></i>{" "}
    //                   {getHumanReadableDifference(notification.time)}
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       )
    //     })}
    //   </div>
    // </React.Fragment>
  )
}

export default Notifications
