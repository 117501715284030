// reducer.js
import { ActionTypes } from "./actionTypes"

const initialState = {
  loading: false,
  dashboardData: null,
  dashboardDataUserInfo: null,
  error: null,
  errorUserInfo: null,
  loadingUserInfo: false,
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ActionTypes.FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        error: null,
      }
    case ActionTypes.FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    // User Info
    case ActionTypes.FETCH_USER_INFO_DASHBOARD_REQUEST:
      return {
        ...state,
        loadingUserInfo: true,
        errorUserInfo: null,
      }
    case ActionTypes.FETCH_USER_INFO_DASHBOARD_SUCCESS:
      return {
        ...state,
        loadingUserInfo: false,
        dashboardDataUserInfo: action.payload,
        errorUserInfo: null,
      }
    case ActionTypes.FETCH_USER_INFO_DASHBOARD_FAILURE:
      return {
        ...state,
        loadingUserInfo: false,
        errorUserInfo: action.payload.error,
      }
    default:
      return state
  }
}

export default DashboardReducer
