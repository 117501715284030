// Bitget

// Constants for submitting Bitget API data
export const RESET_SUBMIT_BITGET_API_DATA = "RESET_SUBMIT_BITGET_API_DATA"
export const SUBMIT_BITGET_API_DATA = "SUBMIT_BITGET_API_DATA"
export const SUBMIT_BITGET_API_DATA_SUCCESS = "SUBMIT_BITGET_API_DATA_SUCCESS"
export const SUBMIT_BITGET_API_DATA_FAIL = "SUBMIT_BITGET_API_DATA_FAIL"

// Constants for adding new Bitget API data
export const ADD_NEW_BITGET_API_DATA = "ADD_NEW_BITGET_API_DATA"
export const ADD_BITGET_API_DATA_SUCCESS = "ADD_BITGET_API_DATA_SUCCESS"
export const ADD_BITGET_API_DATA_FAIL = "ADD_BITGET_API_DATA_FAIL"

// API DATAS
export const FETCH_API_DATA_BITGET = "FETCH_API_DATA_BITGET"
export const FETCH_API_DATA_SUCCESS_BITGET = "FETCH_API_DATA_SUCCESS_BITGET"
export const FETCH_API_DATA_FAIL_BITGET = "FETCH_API_DATA_FAIL_BITGET"

// DELETE API DATAS
export const DELETE_API_DATA_BITGET = "DELETE_API_DATA_BITGET"
export const DELETE_API_DATA_SUCCESS_BITGET = "DELETE_API_DATA_SUCCESS_BITGET"
export const DELETE_API_DATA_FAIL_BITGET = "DELETE_API_DATA_FAIL_BITGET"
