import {
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_ALL_NOTIFICATIONS_SUCCESS,
  UPDATE_ALL_NOTIFICATIONS_FAIL,
} from "./actionTypes"

const initialState = {
  notifications: [], // This should always be an array
  error: null,
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload, // Ensure that action.payload is an array
      }
    case FETCH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTIFICATION_SUCCESS:
      // Assuming action.payload is the full notification object with an updated 'read' status.
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.id === action.payload.id
            ? { ...notification, ...action.payload }
            : notification
        ),
      }

    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ALL_NOTIFICATIONS_SUCCESS:
      // Set all notifications to read.
      return {
        ...state,
        notifications: state.notifications.map(notification => ({
          ...notification,
          read: true,
        })),
      }
    case UPDATE_ALL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default notificationsReducer
