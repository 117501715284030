import PropTypes from "prop-types";
import { Button } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { size, map } from "lodash";

const getExchangeUrls = (exchangeName) => {
  switch (exchangeName) {
    case "BloFin":
      return {
        connectionUrl: "/exchanges/blofin-connection",
        referralUrl: "https://partner.blofin.com/d/crodl",
      };
    case "Bybit":
      return {
        connectionUrl: "/exchanges/bybit-connection",
        referralUrl: "https://partner.bybit.com/b/12193",
      };
    case "Binance":
      return {
        connectionUrl: "/exchanges/binance-connection",
        referralUrl: "https://accounts.binance.com/register?ref=LEVBS20C",
      };
    case "Bitget":
      return {
        connectionUrl: "/exchanges/bitget-connection",
        referralUrl: "https://partner.bitget.com/bg/CYZ9V5",
      };
    default:
      return {
        connectionUrl: "#",
        referralUrl: "#",
      };
  }
};

const CardExchange = ({ exchange }) => {
  const { connectionUrl, referralUrl } = getExchangeUrls(exchange.name);

  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card className="text-center">
          <CardBody>
            {!exchange.logo ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-primary-subtle bg-" +
                    exchange.color +
                    " text-" +
                    exchange.color +
                    " font-size-16"
                  }
                ></span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-md"
                  src={exchange.logo}
                  alt={exchange.name}
                />
              </div>
            )}

            <h5 className="font-size-22 mb-1">
              <Link to="#" className="text-dark">
                {exchange.name}
              </Link>
            </h5>
            <p className="text-muted">{exchange.type}</p>

            <div>
              {map(
                exchange.features,
                (feature, index) =>
                  index < 2 && (
                    <span
                      className="badge bg-primary font-size-15 m-1"
                      key={"_feature_" + exchange.id + index}
                    >
                      {feature}
                    </span>
                  )
              )}
              {size(exchange.features) > 2 && (
                <span
                  className="badge bg-primary font-size-11 m-1"
                  key={"_feature_" + exchange.id}
                >
                  {size(exchange.features) - 1} + more
                </span>
              )}
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Link to={connectionUrl} id={"trading" + exchange.id}>
                  <i className="bx bx-link " />
                  <UncontrolledTooltip
                    placement="top"
                    target={"trading" + exchange.id}
                    className="external-event"
                  >
                    Connect Exchange
                  </UncontrolledTooltip>
                </Link>
              </div>
              <div className="flex-fill">
                <Button
                  color="light btn-md"
                  onClick={() => window.open(referralUrl, "_blank")}
                  id={"info" + exchange.id}
                  className="external-event"
                >
                  <i className="" /> Create Account
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardExchange.propTypes = {
  exchange: PropTypes.object,
};

export default CardExchange;
