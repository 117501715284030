import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { createSelector } from "reselect" // Make sure to import createSelector
import qrImage from "../../assets/images/usdtt.png" // Adjust the path as necessary
import { checkTxid, checkTxidSuccess } from "../../store/payments/actions" // Adjust the import path as necessary
import { use } from "i18next"
import "./subscriptions.css"
const Subscriptions = () => {
  const [txid, setTxid] = useState("")
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [selectedPlan, setSelectedPlan] = useState(null)
  const cryptoAddress = "TSguCwT2JPyKUESKwNgVqd4Px4ShBMxqk7"
  document.title = "Subscriptions | Crodl"

  // Define selectors directly if not reused outside this component
  const txidCheckResult = useSelector(
    state => state.paymentReducer.txidCheckResult
  )

  const txidSuccess = useSelector(state => state.paymentReducer.success)
  const error = useSelector(state => state.paymentReducer.error)
  const isLoading = useSelector(state => state.paymentReducer.isLoading)

  useEffect(() => {
    let timeoutId
    if (txidSuccess) {
      // Set a 2 second delay before navigating
      timeoutId = setTimeout(() => {
        navigate("/")
      }, 2000) // 2000 milliseconds = 2 seconds
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [txidSuccess, navigate])

  const onCopy = () => {
    toast.success("Address copied to clipboard!")
  }

  const handleSubmit = () => {
    if (txid) {
      dispatch(checkTxid(txid))
    } else {
      toast.error("Please enter a TXID.")
    }
  }

  const pricingOptions = [
    { id: 1, plan: "Monthly", price: "5 USDT", perk: "Bot Only" },
    {
      id: 2,
      plan: "Yearly",
      price: "100 USDT",
      perk: "Bot + Indicators + Full Discord",
    },
    {
      id: 3,
      plan: "Lifetime",
      price: "250 USDT",
      perk: "Bot + Indicators + Full Discord",
    },
  ]

  const selectPlan = id => {
    setSelectedPlan(id)
  }

  const selectedStyle = {
    border: "2px solid",
    borderColor: "#556ee6",
    animation: "borderAnim 2s infinite",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4 text-center">
                    Crypto Payment
                  </h4>
                  <div className="mt-4">
                    <input
                      value={txid}
                      onChange={e => setTxid(e.target.value)}
                      type="text"
                      placeholder="Enter TXID Here"
                      className="form-control"
                    />
                    <Button
                      color="primary"
                      className="mt-3 w-100"
                      onClick={handleSubmit}
                      disabled={isLoading} // Disable the button while loading
                    >
                      {isLoading ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card>
                <CardBody className="text-center">
                  <img
                    src={qrImage}
                    alt="Crypto QR"
                    className="img-fluid mb-4"
                  />
                  <p className="mb-0">Chain: TRC 20</p>

                  <h6>{cryptoAddress}</h6>
                  <CopyToClipboard text={cryptoAddress} onCopy={onCopy}>
                    <Button color="success" className="mb-4">
                      Copy Address
                    </Button>
                  </CopyToClipboard>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mb-5">
            {pricingOptions.map(({ id, plan, price, perk }) => (
              <Col key={id} lg={4} md={6} className="mb-4">
                <Card
                  className="text-center"
                  style={selectedPlan === id ? selectedStyle : {}}
                >
                  <CardBody onClick={() => selectPlan(id)}>
                    <h5 className="font-size-16">{plan}</h5>
                    <h2 className="gradient-text-pricing">{price}</h2>
                    <badge>{perk}</badge>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Subscriptions
