import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Reactstrap components
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  CardText,
  Badge,
  InputGroup,
  Button,
  Label,
  Input,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  UncontrolledTooltip,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

const FuturesForm = ({
  botDetails,
  botBuyName,
  botSellName,
  botCloseBuyName,
  botCloseSellName,
  botData,
  dispatch,
}) => {
  // Use the props in your component

  // Futures

  // Local state for tab management
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabTrade, setActiveTabTrade] = useState("1");
  const isLoading = useSelector((state) => state.BotSpecificReducer.loading);

  // Local state for trade form
  const [token, setToken] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [orderType, setOrderType] = useState("market");
  const [side, setSide] = useState("Select Side");
  const [leverageType, setLeverageType] = useState("isolated");
  const [leverageAmount, setLeverageAmount] = useState("");
  const [quantityType, setQuantityType] = useState("dollar");
  const [quantity, setQuantity] = useState("");
  const [oppositeClose, setOppositeClose] = useState(false);
  const [takeProfitType, setTakeProfitType] = useState("percentage");
  const [takeProfitAmount, setTakeProfitAmount] = useState("");
  const [stopLossType, setStopLossType] = useState("percentage");
  const [stopLossAmount, setStopLossAmount] = useState("");

  // Local state for closing trades
  const [closeTrade, setCloseTrade] = useState({
    tokenName: "",
    tokenSymbol: "",
    orderType: "market", // default to 'market'
    side: "Select Side", // default to 'close_long'
    quantityType: "dollar", // default to 'dollar'
    quantityValue: "",
  });

  // Refs
  const jsonRef = useRef(null);

  const [copiedUrl, setCopiedUrl] = useState("");

  const copyOpenOrderUrl = () => {
    const button = document.getElementById("copyOrderUrlButton");
    const openOrderUrl = button.getAttribute("data-url");

    navigator.clipboard.writeText(openOrderUrl).then(
      () => {
        // If the text was successfully copied
        toast.success("URL copied to clipboard", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      },
      (err) => {
        // If there was an error copying the text
        console.error("Failed to copy URL: ", err);
        toast.error("Failed to copy URL.", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      }
    );
  };

  const copyToClipboard = (ref) => {
    const text = ref.current ? ref.current.innerText : "";
    navigator.clipboard.writeText(text).then(
      () => {
        // If the text was successfully copied
        toast.success("Syntax copied to clipboard", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      },
      (err) => {
        // If there was an error copying the text
        toast.error("Failed to copy syntax.", {
          autoClose: 5000, // The toast will close automatically after 5 seconds
        });
      }
    );
  };

  // Handlers for trade form
  const toggleTabTrade = (tab) => {
    if (activeTabTrade !== tab) setActiveTabTrade(tab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCloseTrade({
      ...closeTrade,
      [name]: value,
    });
  };

  // Trade Modal State and Functions
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmitTrade = () => {
    toggleModal();
  };

  const handleConfirmTrade = () => {
    // Close the confirmation modal
    toggleModal();

    // Place the order logic goes here
    const tradeData = {
      bot_details: {
        bot_id: botData.fields.bot_id,
        exchange: botData.fields.bot_exchange,
        trading_type: "futures",
        action: "open",
      },
      details: {
        token: botData.fields.bot_exchange_token,
        name,
        symbol,
        orderType,
        side,
        leverage: {
          type: leverageType,
          amount: leverageAmount,
        },
        quantity: {
          type: quantityType,
          amount: quantity,
        },
        oppositeClose,
        takeProfit: {
          type: takeProfitType,
          amount: takeProfitAmount,
        },
        stopLoss: {
          type: stopLossType,
          amount: stopLossAmount,
        },
      },
    };

    // Dispatch an action to your saga with the tradeData
    dispatch({
      type: "POST_PLACE_ORDER_REQUEST", // Correct action type
      payload: tradeData,
    });
  };

  // Confirmation Modal State and Functions
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleSubmitCloseTrade = () => {
    // Prepare close trade data
    const closeTradeData = {
      bot_details: {
        bot_id: botData.fields.bot_id,
        exchange: botData.fields.bot_exchange,
        trading_type: "futures",
        action: "close",
      },
      details: {
        token: botData.fields.bot_exchange_token,
        name: closeTrade.tokenName,
        symbol: closeTrade.tokenSymbol,
        orderType: closeTrade.orderType,
        side: closeTrade.side,
        quantity: {
          type: closeTrade.quantityType,
          amount: closeTrade.quantityValue,
        },
      },
    };

    // Dispatch an action to your saga with the closeTradeData
    dispatch({
      type: "POST_CLOSE_ORDER_REQUEST", // Correct action type for closing trades
      payload: closeTradeData,
    });

    // Close the confirmation modal
    setConfirmationModalOpen(false);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModalOpen(!isConfirmationModalOpen);
  };

  // Generate JSON for trade form
  const generateJSON = () => {
    if (activeTabTrade === "1" && botDetails) {
      // For opening trades
      return JSON.stringify(
        {
          bot_details: {
            bot_id: botData.fields.bot_id,
            exchange: botData.fields.bot_exchange,
            trading_type: "futures",
            action: "open",
          },
          details: {
            token: botData.fields.bot_exchange_token,
            name,
            symbol,
            orderType,
            side,
            leverage: {
              type: leverageType,
              amount: leverageAmount,
            },
            quantity: {
              type: quantityType,
              amount: quantity,
            },
            oppositeClose,
            takeProfit: {
              type: takeProfitType,
              amount: takeProfitAmount,
            },
            stopLoss: {
              type: stopLossType,
              amount: stopLossAmount,
            },
          },
        },
        null,
        2
      );
    } else if (activeTabTrade === "2" && botDetails) {
      // For closing trades
      return JSON.stringify(
        {
          bot_details: {
            bot_id: botData.fields.bot_id,
            exchange: botData.fields.bot_exchange,
            trading_type: "futures",
            action: "close",
          },
          details: {
            token: botData.fields.bot_exchange_token,
            name: closeTrade.tokenName,
            symbol: closeTrade.tokenSymbol,
            orderType: closeTrade.orderType,
            side: closeTrade.side,
            quantity: {
              type: closeTrade.quantityType,
              amount: closeTrade.quantityValue,
            },
          },
        },
        null,
        2
      );
    }
    return "{}"; // Default empty JSON if neither tab is selected
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl="6">
          <Card>
            <CardBody>
              <Nav pills className="bg-light rounded" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTabTrade === "1",
                    })}
                    onClick={() => toggleTabTrade("1")}
                  >
                    Open Orders
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTabTrade === "2",
                    })}
                    onClick={() => toggleTabTrade("2")}
                  >
                    Close Orders
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTabTrade} className="mt-4">
                <TabPane tabId="1">
                  {botDetails ? (
                    <>
                      {botDetails.bot_exchange_token ? (
                        <FormGroup>
                          <Label for="token">Token</Label>
                          <Input
                            type="text"
                            id="token"
                            value={botDetails.bot_exchange_token}
                            readOnly
                          />
                        </FormGroup>
                      ) : null}
                      {/* Other bot details */}
                    </>
                  ) : (
                    <p>No details available for this bot.</p>
                  )}

                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="symbol">Symbol</Label>
                    <Input
                      type="text"
                      id="symbol"
                      value={symbol}
                      onChange={(e) => setSymbol(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="orderType">Order Type</Label>
                    <Input
                      type="select"
                      id="orderType"
                      value={orderType}
                      onChange={(e) => setOrderType(e.target.value)}
                    >
                      <option value="market">Market</option>
                      {/* Add other order types as needed */}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="me-2" for="side">
                      Side
                    </Label>
                    <span className="badge-soft-warning  badge bg-secondary">
                      This can be customised in advance settings
                    </span>
                    <Input
                      type="select"
                      id="side"
                      value={side}
                      onChange={(e) => setSide(e.target.value)}
                    >
                      <option value="Select Side">Select Side</option>
                      <option value={botBuyName}>{botBuyName}</option>
                      <option value={botSellName}>{botSellName}</option>
                    </Input>
                  </FormGroup>
                  <div>
                    <Label for="leverage">Leverage</Label>
                    <InputGroup className="mb-3">
                      <Input
                        type="select"
                        value={leverageType}
                        onChange={(e) => setLeverageType(e.target.value)}
                      >
                        <option value="isolated">Isolated</option>
                        <option value="cross">Cross</option>
                      </Input>
                      <Input
                        type="text"
                        value={leverageAmount}
                        onChange={(e) => setLeverageAmount(e.target.value)}
                        placeholder="Value"
                      />
                    </InputGroup>
                  </div>
                  <div>
                    <Label className="me-2" for="quantity">
                      Quantity
                    </Label>
                    <span className="badge-soft-warning  badge bg-secondary">
                      Quantity For Dollar/Percentage = Quantity * Leverage
                    </span>
                    <InputGroup className="mb-3">
                      <Input
                        type="select"
                        value={quantityType}
                        onChange={(e) => setQuantityType(e.target.value)}
                      >
                        <option value="dollar">Dollar</option>
                        <option value="asset">Asset</option>
                        <option value="percentage">Percentage</option>
                      </Input>
                      <Input
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        placeholder="Quantity"
                      />
                    </InputGroup>
                  </div>

                  {/* Opposite Position Close Switch */}
                  <div className="form-check form-switch form-switch-md mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="oppositeCloseSwitch"
                      checked={oppositeClose}
                      onClick={() => setOppositeClose((prev) => !prev)}
                      onChange={() => {}}
                    />

                    <label
                      className="form-check-label me-2"
                      htmlFor="oppositeCloseSwitch"
                    >
                      Opposite Position Close
                    </label>
                    <span className="badge-soft-warning  badge bg-secondary">
                      Wil close the opposite position before placing new order
                    </span>
                  </div>

                  <div>
                    <Label for="takeProfit">Take Profit</Label>
                    <InputGroup className="mb-3">
                      <Input
                        type="select"
                        value={takeProfitType}
                        onChange={(e) => setTakeProfitType(e.target.value)}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </Input>
                      <Input
                        type="text"
                        value={takeProfitAmount}
                        onChange={(e) => setTakeProfitAmount(e.target.value)}
                        placeholder="Value"
                      />
                    </InputGroup>
                  </div>
                  <div>
                    <Label for="stopLoss">Stop Loss</Label>
                    <InputGroup className="mb-3">
                      <Input
                        type="select"
                        value={stopLossType}
                        onChange={(e) => setStopLossType(e.target.value)}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </Input>
                      <Input
                        type="text"
                        value={stopLossAmount}
                        onChange={(e) => setStopLossAmount(e.target.value)}
                        placeholder="Value"
                      />
                    </InputGroup>
                  </div>
                  <div className="text-center">
                    {/* <Button color="success" onClick={handleSubmitTrade}>
                      Open Trade
                    </Button> */}
                    <Button
                      color="success"
                      onClick={handleSubmitTrade}
                      disabled={isLoading}
                    >
                      {isLoading ? "Opening..." : "Open Trade"}
                    </Button>

                    {/* Confirmation Modal */}
                    <Modal isOpen={isModalOpen} centered toggle={toggleModal}>
                      <ModalHeader>Confirm Trade</ModalHeader>
                      <ModalBody>
                        Are you sure you want to place an order?
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>
                          Cancel
                        </Button>{" "}
                        <Button color="primary" onClick={handleConfirmTrade}>
                          Open Order
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <form onSubmit={handleSubmitCloseTrade}>
                    {botDetails ? (
                      <>
                        {botDetails.bot_exchange_token ? (
                          <FormGroup>
                            <Label for="tokenName">Token</Label>
                            <Input
                              type="text"
                              id="tokenName"
                              value={botDetails.bot_exchange_token}
                              readOnly
                            />
                          </FormGroup>
                        ) : null}
                        {/* Other bot details */}
                      </>
                    ) : (
                      <p>No details available for this bot.</p>
                    )}
                    <FormGroup>
                      <Label for="tokenName">Name</Label>
                      <Input
                        type="text"
                        name="tokenName"
                        value={closeTrade.tokenName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="tokenSymbol">Symbol</Label>
                      <Input
                        type="text"
                        name="tokenSymbol"
                        value={closeTrade.tokenSymbol}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="orderType">Order Type</Label>
                      <Input
                        type="select"
                        name="orderType"
                        value={closeTrade.orderType}
                        onChange={handleInputChange}
                      >
                        <option value="market">Market</option>
                        {/* Add other order types as needed */}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className="me-2" for="side">
                        Side
                      </Label>
                      <span className="badge-soft-warning  badge bg-secondary">
                        This can be customised in advance settings
                      </span>
                      <Input
                        type="select"
                        name="side"
                        value={closeTrade.side}
                        onChange={handleInputChange}
                      >
                        <option value="Select Side">Select Side</option>
                        <option value={botCloseBuyName}>
                          {botCloseBuyName}
                        </option>
                        <option value={botCloseSellName}>
                          {botCloseSellName}
                        </option>
                      </Input>
                    </FormGroup>
                    <div>
                      <Label className="me-2" for="quantity">
                        Quantity
                      </Label>
                      <span className="badge-soft-warning  badge bg-secondary">
                        Quantity Based On Selection Dollar/Asset/Percentage
                      </span>
                      <InputGroup className="mb-3">
                        <Input
                          type="select"
                          name="quantityType"
                          value={closeTrade.quantityType}
                          onChange={handleInputChange}
                        >
                          <option value="dollar">Dollar</option>
                          <option value="asset">Asset</option>
                          <option value="percentage">Percentage</option>
                        </Input>
                        <Input
                          type="text"
                          name="quantityValue"
                          value={closeTrade.quantityValue}
                          onChange={handleInputChange}
                          placeholder="Quantity"
                        />
                      </InputGroup>
                    </div>
                    <div className="text-center">
                      {/* <Button color="danger" onClick={toggleConfirmationModal}>
                        Close Trade
                      </Button> */}
                      <Button
                        color="danger"
                        onClick={toggleConfirmationModal}
                        disabled={isLoading}
                      >
                        {isLoading ? "Closing..." : "Close Trade"}
                      </Button>

                      {/* Confirmation Modal */}
                      <Modal
                        isOpen={isConfirmationModalOpen}
                        toggle={toggleConfirmationModal}
                        centered
                      >
                        <ModalHeader toggle={toggleConfirmationModal}>
                          Confirm Close Trade
                        </ModalHeader>
                        <ModalBody>
                          Are you sure you want to close this trade?
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="secondary"
                            onClick={toggleConfirmationModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="danger"
                            onClick={handleSubmitCloseTrade}
                          >
                            Confirm Close
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </form>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <Card>
            <CardBody>
              {activeTabTrade === "1" && (
                <button
                  type="button"
                  className="btn btn-light btn-label mb-4"
                  id="copyOrderUrlButton"
                  data-url="https://api.crodl.com/bots/place-order"
                  onClick={copyOpenOrderUrl}
                >
                  <i className="bx bx-copy-alt label-icon"></i> Copy Open Order
                  URL
                </button>
              )}

              {activeTabTrade === "2" && (
                <button
                  type="button"
                  className="btn btn-light btn-label mb-4"
                  id="copyOrderUrlButton"
                  data-url="https://api.crodl.com/bots/place-order"
                  onClick={copyOpenOrderUrl}
                >
                  <i className="bx bx-copy-alt label-icon"></i> Copy Close Order
                  URL
                </button>
              )}

              <pre ref={jsonRef}>{generateJSON()}</pre>
            </CardBody>
            <button
              className="btn btn-primary waves-effect waves-light btn btn-primary external-event"
              onClick={() => copyToClipboard(jsonRef)}
            >
              Copy Syntax
            </button>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  );
};

export default FuturesForm;

// import React, { useEffect, useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Col,
//   Row,
//   Card,
//   CardBody,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   CardFooter,
//   CardText,
//   Badge,
//   InputGroup,
//   Button,
//   Label,
//   Input,
//   FormGroup,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   UncontrolledTooltip,
//   TabPane,
// } from "reactstrap";
// import classnames from "classnames";
// import { ToastContainer } from "react-toastify";
// import { toast } from "react-toastify";

// const FuturesForm = ({
//   botDetails,
//   botBuyName,
//   botSellName,
//   botCloseBuyName,
//   botCloseSellName,
//   botData,
//   dispatch,
// }) => {
//   // Use the props in your component

//   // Futures

//   // Local state for tab management
//   const [activeTab, setActiveTab] = useState("1");
//   const [activeTabTrade, setActiveTabTrade] = useState("1");
//   const isLoading = useSelector((state) => state.BotSpecificReducer.loading);

//   // Local state for trade form
//   const [token, setToken] = useState("");
//   const [name, setName] = useState("");
//   const [symbol, setSymbol] = useState("");
//   const [orderType, setOrderType] = useState("market");
//   const [side, setSide] = useState("Select Side");
//   const [leverageType, setLeverageType] = useState("isolated");
//   const [leverageAmount, setLeverageAmount] = useState("");
//   const [quantityType, setQuantityType] = useState("dollar");
//   const [quantity, setQuantity] = useState("");
//   const [oppositeClose, setOppositeClose] = useState(false);

//   // Local state for closing trades
//   const [closeTrade, setCloseTrade] = useState({
//     tokenName: "",
//     tokenSymbol: "",
//     orderType: "market", // default to 'market'
//     side: "Select Side", // default to 'close_long'
//     quantityType: "dollar", // default to 'dollar'
//     quantityValue: "",
//   });

//   // Refs
//   const jsonRef = useRef(null);

//   const [copiedUrl, setCopiedUrl] = useState("");

//   const copyOpenOrderUrl = () => {
//     const button = document.getElementById("copyOrderUrlButton");
//     const openOrderUrl = button.getAttribute("data-url");

//     navigator.clipboard.writeText(openOrderUrl).then(
//       () => {
//         // If the text was successfully copied
//         toast.success("URL copied to clipboard", {
//           autoClose: 5000, // The toast will close automatically after 5 seconds
//         });
//       },
//       (err) => {
//         // If there was an error copying the text
//         console.error("Failed to copy URL: ", err);
//         toast.error("Failed to copy URL.", {
//           autoClose: 5000, // The toast will close automatically after 5 seconds
//         });
//       }
//     );
//   };

//   const copyToClipboard = (ref) => {
//     const text = ref.current ? ref.current.innerText : "";
//     navigator.clipboard.writeText(text).then(
//       () => {
//         // If the text was successfully copied
//         toast.success("Syntax copied to clipboard", {
//           autoClose: 5000, // The toast will close automatically after 5 seconds
//         });
//       },
//       (err) => {
//         // If there was an error copying the text
//         toast.error("Failed to copy syntax.", {
//           autoClose: 5000, // The toast will close automatically after 5 seconds
//         });
//       }
//     );
//   };

//   // Handlers for trade form
//   const toggleTabTrade = (tab) => {
//     if (activeTabTrade !== tab) setActiveTabTrade(tab);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setCloseTrade({
//       ...closeTrade,
//       [name]: value,
//     });
//   };

//   // Trade Modal State and Functions
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleSubmitTrade = () => {
//     toggleModal();
//   };

//   const handleConfirmTrade = () => {
//     // Close the confirmation modal
//     toggleModal();

//     // Place the order logic goes here
//     const tradeData = {
//       bot_details: {
//         bot_id: botData.fields.bot_id,
//         exchange: botData.fields.bot_exchange,
//         trading_type: "futures",
//         action: "open",
//       },
//       details: {
//         token: botData.fields.bot_exchange_token,
//         name,
//         symbol,
//         orderType,
//         side,
//         leverage: {
//           type: leverageType,
//           amount: leverageAmount,
//         },
//         quantity: {
//           type: quantityType,
//           amount: quantity,
//         },
//         oppositeClose,
//         takeProfit: {
//           type: takeProfitType,
//           amount: takeProfitAmount,
//         },
//         stopLoss: {
//           type: stopLossType,
//           amount: stopLossAmount,
//         },
//       },
//     };

//     // Dispatch an action to your saga with the tradeData
//     dispatch({
//       type: "POST_PLACE_ORDER_REQUEST", // Correct action type
//       payload: tradeData,
//     });
//   };

//   // Confirmation Modal State and Functions
//   const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

//   const handleSubmitCloseTrade = () => {
//     // Prepare close trade data
//     const closeTradeData = {
//       bot_details: {
//         bot_id: botData.fields.bot_id,
//         exchange: botData.fields.bot_exchange,
//         trading_type: "futures",
//         action: "close",
//       },
//       details: {
//         token: botData.fields.bot_exchange_token,
//         name: closeTrade.tokenName,
//         symbol: closeTrade.tokenSymbol,
//         orderType: closeTrade.orderType,
//         side: closeTrade.side,
//         quantity: {
//           type: closeTrade.quantityType,
//           amount: closeTrade.quantityValue,
//         },
//       },
//     };

//     // Dispatch an action to your saga with the closeTradeData
//     dispatch({
//       type: "POST_CLOSE_ORDER_REQUEST", // Correct action type for closing trades
//       payload: closeTradeData,
//     });

//     // Close the confirmation modal
//     setConfirmationModalOpen(false);
//   };

//   const toggleConfirmationModal = () => {
//     setConfirmationModalOpen(!isConfirmationModalOpen);
//   };

//   // Generate JSON for trade form
//   const generateJSON = () => {
//     if (activeTabTrade === "1" && botDetails) {
//       // For opening trades
//       return JSON.stringify(
//         {
//           bot_details: {
//             bot_id: botData.fields.bot_id,
//             exchange: botData.fields.bot_exchange,
//             trading_type: "futures",
//             action: "open",
//           },
//           details: {
//             token: botData.fields.bot_exchange_token,
//             name,
//             symbol,
//             orderType,
//             side,
//             leverage: {
//               type: leverageType,
//               amount: leverageAmount,
//             },
//             quantity: {
//               type: quantityType,
//               amount: quantity,
//             },
//             oppositeClose,
//             takeProfit: {
//               type: takeProfitType,
//               amount: takeProfitAmount,
//             },
//             stopLoss: {
//               type: stopLossType,
//               amount: stopLossAmount,
//             },
//           },
//         },
//         null,
//         2
//       );
//     } else if (activeTabTrade === "2" && botDetails) {
//       // For closing trades
//       return JSON.stringify(
//         {
//           bot_details: {
//             bot_id: botData.fields.bot_id,
//             exchange: botData.fields.bot_exchange,
//             trading_type: "futures",
//             action: "close",
//           },
//           details: {
//             token: botData.fields.bot_exchange_token,
//             name: closeTrade.tokenName,
//             symbol: closeTrade.tokenSymbol,
//             orderType: closeTrade.orderType,
//             side: closeTrade.side,
//             quantity: {
//               type: closeTrade.quantityType,
//               amount: closeTrade.quantityValue,
//             },
//           },
//         },
//         null,
//         2
//       );
//     }
//     return "{}"; // Default empty JSON if neither tab is selected
//   };

//   // Local state for position close type and partial positions
//   const [positionType, setPositionType] = useState("full");
//   const [takeProfitType, setTakeProfitType] = useState("percentage");
//   const [takeProfitAmount, setTakeProfitAmount] = useState("");
//   const [stopLossType, setStopLossType] = useState("percentage");
//   const [stopLossAmount, setStopLossAmount] = useState("");
//   const [partialPositions, setPartialPositions] = useState([
//     { type: "percentage", value: "", qty: "" },
//   ]);
//   const [partialTakeProfits, setPartialTakeProfits] = useState([
//     { type: "percentage", value: "", qty: "" },
//   ]);
//   const [partialStopLosses, setPartialStopLosses] = useState([
//     { type: "percentage", value: "", qty: "" },
//   ]);

//   const handleAddPartialPosition = () => {
//     setPartialPositions([
//       ...partialPositions,
//       { type: "percentage", value: "", qty: "" },
//     ]);
//   };

//   const handleRemovePartialPosition = (index) => {
//     setPartialPositions(partialPositions.filter((_, i) => i !== index));
//   };

//   // const handlePartialPositionChange = (index, field, value) => {
//   //   const newPartialPositions = partialPositions.map((position, i) => {
//   //     if (i === index) {
//   //       return { ...position, [field]: value };
//   //     }
//   //     return position;
//   //   });
//   //   setPartialPositions(newPartialPositions);
//   // };

//   const handleAddPartialTakeProfit = () => {
//     setPartialTakeProfits([
//       ...partialTakeProfits,
//       { type: "percentage", value: "", qty: "" },
//     ]);
//   };

//   const handleRemovePartialTakeProfit = (index) => {
//     setPartialTakeProfits(partialTakeProfits.filter((_, i) => i !== index));
//   };

//   const handlePartialTakeProfitChange = (index, field, value) => {
//     const newPartialTakeProfits = partialTakeProfits.map((position, i) => {
//       if (i === index) {
//         return { ...position, [field]: value };
//       }
//       return position;
//     });
//     setPartialTakeProfits(newPartialTakeProfits);
//   };

//   const handleAddPartialStopLoss = () => {
//     setPartialStopLosses([
//       ...partialStopLosses,
//       { type: "percentage", value: "", qty: "" },
//     ]);
//   };

//   const handleRemovePartialStopLoss = (index) => {
//     setPartialStopLosses(partialStopLosses.filter((_, i) => i !== index));
//   };

//   const handlePartialStopLossChange = (index, field, value) => {
//     const newPartialStopLosses = partialStopLosses.map((position, i) => {
//       if (i === index) {
//         return { ...position, [field]: value };
//       }
//       return position;
//     });
//     setPartialStopLosses(newPartialStopLosses);
//   };

//   // const generatePartialPositionsJSON = () => {
//   //   return partialPositions.map((position) => ({
//   //     type: position.type,
//   //     amount: position.value,
//   //     qty: position.qty,
//   //   }));
//   // };

//   const generatePartialTakeProfitsJSON = () => {
//     return partialTakeProfits.map((takeProfit) => ({
//       type: takeProfit.type,
//       amount: takeProfit.value,
//       qty: takeProfit.qty,
//     }));
//   };

//   const generatePartialStopLossesJSON = () => {
//     return partialStopLosses.map((stopLoss) => ({
//       type: stopLoss.type,
//       amount: stopLoss.value,
//       qty: stopLoss.qty,
//     }));
//   };

//   const generateJSONWithPartialPositions = () => {
//     if (positionType === "partial" && botDetails) {
//       return JSON.stringify(
//         {
//           bot_details: {
//             bot_id: botData.fields.bot_id,
//             exchange: botData.fields.bot_exchange,
//             trading_type: "futures",
//             action: "open",
//           },
//           details: {
//             token: botData.fields.bot_exchange_token,
//             name,
//             symbol,
//             orderType,
//             side,
//             leverage: {
//               type: leverageType,
//               amount: leverageAmount,
//             },
//             quantity: {
//               type: quantityType,
//               amount: quantity,
//             },
//             oppositeClose,
//             // partialPositions: generatePartialPositionsJSON(),
//             partialTakeProfits: generatePartialTakeProfitsJSON(),
//             partialStopLosses: generatePartialStopLossesJSON(),
//           },
//         },
//         null,
//         2
//       );
//     } else if (positionType === "full" && botDetails) {
//       return JSON.stringify(
//         {
//           bot_details: {
//             bot_id: botData.fields.bot_id,
//             exchange: botData.fields.bot_exchange,
//             trading_type: "futures",
//             action: "open",
//           },
//           details: {
//             token: botData.fields.bot_exchange_token,
//             name,
//             symbol,
//             orderType,
//             side,
//             leverage: {
//               type: leverageType,
//               amount: leverageAmount,
//             },
//             quantity: {
//               type: quantityType,
//               amount: quantity,
//             },
//             oppositeClose,
//             takeProfit: {
//               type: takeProfitType,
//               amount: takeProfitAmount,
//             },
//             stopLoss: {
//               type: stopLossType,
//               amount: stopLossAmount,
//             },
//           },
//         },
//         null,
//         2
//       );
//     }
//     return "{}"; // Default empty JSON if neither tab is selected
//   };

//   return (
//     <React.Fragment>
//       <Row>
//         <Col xl="6">
//           <Card>
//             <CardBody>
//               <Nav pills className="bg-light rounded" role="tablist">
//                 <NavItem>
//                   <NavLink
//                     className={classnames({
//                       active: activeTabTrade === "1",
//                     })}
//                     onClick={() => toggleTabTrade("1")}
//                   >
//                     Open Orders
//                   </NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink
//                     className={classnames({
//                       active: activeTabTrade === "2",
//                     })}
//                     onClick={() => toggleTabTrade("2")}
//                   >
//                     Close Orders
//                   </NavLink>
//                 </NavItem>
//               </Nav>

//               <TabContent activeTab={activeTabTrade} className="mt-4">
//                 <TabPane tabId="1">
//                   {botDetails ? (
//                     <>
//                       {botDetails.bot_exchange_token ? (
//                         <FormGroup>
//                           <Label for="token">Token</Label>
//                           <Input
//                             type="text"
//                             id="token"
//                             value={botDetails.bot_exchange_token}
//                             readOnly
//                           />
//                         </FormGroup>
//                       ) : null}
//                       {/* Other bot details */}
//                     </>
//                   ) : (
//                     <p>No details available for this bot.</p>
//                   )}
//                   <FormGroup>
//                     <Label for="name">Name</Label>
//                     <Input
//                       type="text"
//                       id="name"
//                       value={name}
//                       onChange={(e) => setName(e.target.value)}
//                     />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="symbol">Symbol</Label>
//                     <Input
//                       type="text"
//                       id="symbol"
//                       value={symbol}
//                       onChange={(e) => setSymbol(e.target.value)}
//                     />
//                   </FormGroup>
//                   <FormGroup>
//                     <Label for="orderType">Order Type</Label>
//                     <Input
//                       type="select"
//                       id="orderType"
//                       value={orderType}
//                       onChange={(e) => setOrderType(e.target.value)}
//                     >
//                       <option value="market">Market</option>
//                       {/* Add other order types as needed */}
//                     </Input>
//                   </FormGroup>
//                   <FormGroup>
//                     <Label className="me-2" for="side">
//                       Side
//                     </Label>
//                     <span className="badge-soft-warning badge bg-secondary">
//                       This can be customised in advance settings
//                     </span>
//                     <Input
//                       type="select"
//                       id="side"
//                       value={side}
//                       onChange={(e) => setSide(e.target.value)}
//                     >
//                       <option value="Select Side">Select Side</option>
//                       <option value={botBuyName}>{botBuyName}</option>
//                       <option value={botSellName}>{botSellName}</option>
//                     </Input>
//                   </FormGroup>
//                   <div>
//                     <Label for="leverage">Leverage</Label>
//                     <InputGroup className="mb-3">
//                       <Input
//                         type="select"
//                         value={leverageType}
//                         onChange={(e) => setLeverageType(e.target.value)}
//                       >
//                         <option value="isolated">Isolated</option>
//                         <option value="cross">Cross</option>
//                       </Input>
//                       <Input
//                         type="text"
//                         value={leverageAmount}
//                         onChange={(e) => setLeverageAmount(e.target.value)}
//                         placeholder="Value"
//                       />
//                     </InputGroup>
//                   </div>
//                   <div>
//                     <Label className="me-2" for="quantity">
//                       Quantity
//                     </Label>
//                     <span className="badge-soft-warning badge bg-secondary">
//                       Quantity For Dollar/Percentage = Quantity * Leverage
//                     </span>
//                     <InputGroup className="mb-3">
//                       <Input
//                         type="select"
//                         value={quantityType}
//                         onChange={(e) => setQuantityType(e.target.value)}
//                       >
//                         <option value="dollar">Dollar</option>
//                         <option value="asset">Asset</option>
//                         <option value="percentage">Percentage</option>
//                       </Input>
//                       <Input
//                         type="text"
//                         value={quantity}
//                         onChange={(e) => setQuantity(e.target.value)}
//                         placeholder="Quantity"
//                       />
//                     </InputGroup>
//                   </div>
//                   {/* Opposite Position Close Switch */}
//                   <div className="form-check form-switch form-switch-md mb-3">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="oppositeCloseSwitch"
//                       checked={oppositeClose}
//                       onClick={() => setOppositeClose((prev) => !prev)}
//                       onChange={() => {}}
//                     />
//                     <label
//                       className="form-check-label me-2"
//                       htmlFor="oppositeCloseSwitch"
//                     >
//                       Opposite Position Close
//                     </label>
//                     <span className="badge-soft-warning badge bg-secondary">
//                       Will close the opposite position before placing new order
//                     </span>
//                   </div>
//                   <div>
//                     <Label for="positionType">Position Close Type</Label>
//                     <Input
//                       type="select"
//                       value={positionType}
//                       onChange={(e) => setPositionType(e.target.value)}
//                     >
//                       <option value="full">Full Position Close</option>
//                       <option value="partial">Partial Position Close</option>
//                     </Input>

//                     {positionType === "full" && (
//                       <>
//                         <div>
//                           <Label for="takeProfit">Take Profit</Label>
//                           <InputGroup className="mb-3">
//                             <Input
//                               type="select"
//                               value={takeProfitType}
//                               onChange={(e) =>
//                                 setTakeProfitType(e.target.value)
//                               }
//                             >
//                               <option value="percentage">Percentage</option>
//                               <option value="dollar">Dollar</option>
//                             </Input>
//                             <Input
//                               type="text"
//                               value={takeProfitAmount}
//                               onChange={(e) =>
//                                 setTakeProfitAmount(e.target.value)
//                               }
//                               placeholder="Value"
//                             />
//                           </InputGroup>
//                         </div>
//                         <div>
//                           <Label for="stopLoss">Stop Loss</Label>
//                           <InputGroup className="mb-3">
//                             <Input
//                               type="select"
//                               value={stopLossType}
//                               onChange={(e) => setStopLossType(e.target.value)}
//                             >
//                               <option value="percentage">Percentage</option>
//                               <option value="dollar">Dollar</option>
//                             </Input>
//                             <Input
//                               type="text"
//                               value={stopLossAmount}
//                               onChange={(e) =>
//                                 setStopLossAmount(e.target.value)
//                               }
//                               placeholder="Value"
//                             />
//                           </InputGroup>
//                         </div>
//                       </>
//                     )}

//                     {positionType === "partial" && (
//                       <>
//                         {/* {partialPositions.map((position, index) => (
//                           <div key={index} className="mb-3">
//                             <Label for={`partialPositionType${index}`}>
//                               Partial Position {index + 1}
//                             </Label>
//                             <InputGroup className="mb-2">
//                               <Input
//                                 type="select"
//                                 value={position.type}
//                                 onChange={(e) =>
//                                   handlePartialPositionChange(
//                                     index,
//                                     "type",
//                                     e.target.value
//                                   )
//                                 }
//                               >
//                                 <option value="percentage">Percentage</option>
//                                 <option value="dollar">Dollar</option>
//                               </Input>
//                               <Input
//                                 type="text"
//                                 value={position.value}
//                                 onChange={(e) =>
//                                   handlePartialPositionChange(
//                                     index,
//                                     "value",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Value"
//                               />
//                               <Input
//                                 type="text"
//                                 value={position.qty}
//                                 onChange={(e) =>
//                                   handlePartialPositionChange(
//                                     index,
//                                     "qty",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Qty"
//                               />
//                               <Button
//                                 color="danger"
//                                 onClick={() =>
//                                   handleRemovePartialPosition(index)
//                                 }
//                               >
//                                 -
//                               </Button>
//                             </InputGroup>
//                           </div>
//                         ))}
//                         <Button
//                           color="primary"
//                           onClick={handleAddPartialPosition}
//                         >
//                           + Add Partial Position
//                         </Button>

//                         <hr /> */}
//                         <hr />

//                         <h5>Partial Take Profits</h5>
//                         {partialTakeProfits.map((takeProfit, index) => (
//                           <div key={index} className="mb-3">
//                             <Label for={`partialTakeProfitType${index}`}>
//                               Partial Take Profit {index + 1}
//                             </Label>
//                             <InputGroup className="mb-2">
//                               <Input
//                                 type="select"
//                                 value={takeProfit.type}
//                                 onChange={(e) =>
//                                   handlePartialTakeProfitChange(
//                                     index,
//                                     "type",
//                                     e.target.value
//                                   )
//                                 }
//                               >
//                                 <option value="percentage">Percentage</option>
//                                 <option value="dollar">Dollar</option>
//                               </Input>
//                               <Input
//                                 type="text"
//                                 value={takeProfit.value}
//                                 onChange={(e) =>
//                                   handlePartialTakeProfitChange(
//                                     index,
//                                     "value",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Value"
//                               />
//                               <Input
//                                 type="text"
//                                 value={takeProfit.qty}
//                                 onChange={(e) =>
//                                   handlePartialTakeProfitChange(
//                                     index,
//                                     "qty",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Qty"
//                               />
//                               <Button
//                                 color="danger"
//                                 onClick={() =>
//                                   handleRemovePartialTakeProfit(index)
//                                 }
//                               >
//                                 -
//                               </Button>
//                             </InputGroup>
//                           </div>
//                         ))}
//                         <Button
//                           color="primary"
//                           onClick={handleAddPartialTakeProfit}
//                         >
//                           + Add Partial Take Profit
//                         </Button>

//                         <hr />

//                         <h5>Partial Stop Losses</h5>
//                         {partialStopLosses.map((stopLoss, index) => (
//                           <div key={index} className="mb-3">
//                             <Label for={`partialStopLossType${index}`}>
//                               Partial Stop Loss {index + 1}
//                             </Label>
//                             <InputGroup className="mb-2">
//                               <Input
//                                 type="select"
//                                 value={stopLoss.type}
//                                 onChange={(e) =>
//                                   handlePartialStopLossChange(
//                                     index,
//                                     "type",
//                                     e.target.value
//                                   )
//                                 }
//                               >
//                                 <option value="percentage">Percentage</option>
//                                 <option value="dollar">Dollar</option>
//                               </Input>
//                               <Input
//                                 type="text"
//                                 value={stopLoss.value}
//                                 onChange={(e) =>
//                                   handlePartialStopLossChange(
//                                     index,
//                                     "value",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Value"
//                               />
//                               <Input
//                                 type="text"
//                                 value={stopLoss.qty}
//                                 onChange={(e) =>
//                                   handlePartialStopLossChange(
//                                     index,
//                                     "qty",
//                                     e.target.value
//                                   )
//                                 }
//                                 placeholder="Qty"
//                               />
//                               <Button
//                                 color="danger"
//                                 onClick={() =>
//                                   handleRemovePartialStopLoss(index)
//                                 }
//                               >
//                                 -
//                               </Button>
//                             </InputGroup>
//                           </div>
//                         ))}
//                         <Button
//                           color="primary"
//                           onClick={handleAddPartialStopLoss}
//                         >
//                           + Add Partial Stop Loss
//                         </Button>
//                       </>
//                     )}
//                   </div>
//                   <div className="text-center">
//                     <Button
//                       color="success"
//                       onClick={handleSubmitTrade}
//                       disabled={isLoading}
//                     >
//                       {isLoading ? "Opening..." : "Open Trade"}
//                     </Button>

//                     {/* Confirmation Modal */}
//                     <Modal isOpen={isModalOpen} centered toggle={toggleModal}>
//                       <ModalHeader>Confirm Trade</ModalHeader>
//                       <ModalBody>
//                         Are you sure you want to place an order?
//                       </ModalBody>
//                       <ModalFooter>
//                         <Button color="secondary" onClick={toggleModal}>
//                           Cancel
//                         </Button>{" "}
//                         <Button color="primary" onClick={handleConfirmTrade}>
//                           Open Order
//                         </Button>
//                       </ModalFooter>
//                     </Modal>
//                   </div>
//                 </TabPane>
//                 <TabPane tabId="2">
//                   <form onSubmit={handleSubmitCloseTrade}>
//                     {botDetails ? (
//                       <>
//                         {botDetails.bot_exchange_token ? (
//                           <FormGroup>
//                             <Label for="tokenName">Token</Label>
//                             <Input
//                               type="text"
//                               id="tokenName"
//                               value={botDetails.bot_exchange_token}
//                               readOnly
//                             />
//                           </FormGroup>
//                         ) : null}
//                         {/* Other bot details */}
//                       </>
//                     ) : (
//                       <p>No details available for this bot.</p>
//                     )}
//                     <FormGroup>
//                       <Label for="tokenName">Name</Label>
//                       <Input
//                         type="text"
//                         name="tokenName"
//                         value={closeTrade.tokenName}
//                         onChange={handleInputChange}
//                       />
//                     </FormGroup>
//                     <FormGroup>
//                       <Label for="tokenSymbol">Symbol</Label>
//                       <Input
//                         type="text"
//                         name="tokenSymbol"
//                         value={closeTrade.tokenSymbol}
//                         onChange={handleInputChange}
//                       />
//                     </FormGroup>
//                     <FormGroup>
//                       <Label for="orderType">Order Type</Label>
//                       <Input
//                         type="select"
//                         name="orderType"
//                         value={closeTrade.orderType}
//                         onChange={handleInputChange}
//                       >
//                         <option value="market">Market</option>
//                         {/* Add other order types as needed */}
//                       </Input>
//                     </FormGroup>
//                     <FormGroup>
//                       <Label className="me-2" for="side">
//                         Side
//                       </Label>
//                       <span className="badge-soft-warning badge bg-secondary">
//                         This can be customised in advance settings
//                       </span>
//                       <Input
//                         type="select"
//                         name="side"
//                         value={closeTrade.side}
//                         onChange={handleInputChange}
//                       >
//                         <option value="Select Side">Select Side</option>
//                         <option value={botCloseBuyName}>
//                           {botCloseBuyName}
//                         </option>
//                         <option value={botCloseSellName}>
//                           {botCloseSellName}
//                         </option>
//                       </Input>
//                     </FormGroup>
//                     <div>
//                       <Label className="me-2" for="quantity">
//                         Quantity
//                       </Label>
//                       <span className="badge-soft-warning badge bg-secondary">
//                         Quantity Based On Selection Dollar/Asset/Percentage
//                       </span>
//                       <InputGroup className="mb-3">
//                         <Input
//                           type="select"
//                           name="quantityType"
//                           value={closeTrade.quantityType}
//                           onChange={handleInputChange}
//                         >
//                           <option value="dollar">Dollar</option>
//                           <option value="asset">Asset</option>
//                           <option value="percentage">Percentage</option>
//                         </Input>
//                         <Input
//                           type="text"
//                           name="quantityValue"
//                           value={closeTrade.quantityValue}
//                           onChange={handleInputChange}
//                           placeholder="Quantity"
//                         />
//                       </InputGroup>
//                     </div>
//                     <div className="text-center">
//                       <Button
//                         color="danger"
//                         onClick={toggleConfirmationModal}
//                         disabled={isLoading}
//                       >
//                         {isLoading ? "Closing..." : "Close Trade"}
//                       </Button>

//                       {/* Confirmation Modal */}
//                       <Modal
//                         isOpen={isConfirmationModalOpen}
//                         toggle={toggleConfirmationModal}
//                         centered
//                       >
//                         <ModalHeader toggle={toggleConfirmationModal}>
//                           Confirm Close Trade
//                         </ModalHeader>
//                         <ModalBody>
//                           Are you sure you want to close this trade?
//                         </ModalBody>
//                         <ModalFooter>
//                           <Button
//                             color="secondary"
//                             onClick={toggleConfirmationModal}
//                           >
//                             Cancel
//                           </Button>
//                           <Button
//                             color="danger"
//                             onClick={handleSubmitCloseTrade}
//                           >
//                             Confirm Close
//                           </Button>
//                         </ModalFooter>
//                       </Modal>
//                     </div>
//                   </form>
//                 </TabPane>
//               </TabContent>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col xl="6">
//           <Card>
//             <CardBody>
//               {activeTabTrade === "1" && (
//                 <button
//                   type="button"
//                   className="btn btn-light btn-label mb-4"
//                   id="copyOrderUrlButton"
//                   data-url="https://api.crodl.com/bots/place-order"
//                   onClick={copyOpenOrderUrl}
//                 >
//                   <i className="bx bx-copy-alt label-icon"></i> Copy Open Order
//                   URL
//                 </button>
//               )}

//               {activeTabTrade === "2" && (
//                 <button
//                   type="button"
//                   className="btn btn-light btn-label mb-4"
//                   id="copyOrderUrlButton"
//                   data-url="https://api.crodl.com/bots/place-order"
//                   onClick={copyOpenOrderUrl}
//                 >
//                   <i className="bx bx-copy-alt label-icon"></i> Copy Close Order
//                   URL
//                 </button>
//               )}

//               <pre ref={jsonRef}>{generateJSONWithPartialPositions()}</pre>
//             </CardBody>
//             <button
//               className="btn btn-primary waves-effect waves-light btn btn-primary external-event"
//               onClick={() => copyToClipboard(jsonRef)}
//             >
//               Copy Syntax
//             </button>
//           </Card>
//         </Col>
//       </Row>
//       <ToastContainer />
//     </React.Fragment>
//   );
// };

// export default FuturesForm;
