export const ActionTypes = {
  FETCH_SPECIFIC_BOT_REQUEST: "FETCH_SPECIFIC_BOT_REQUEST",
  FETCH_SPECIFIC_BOT_SUCCESS: "FETCH_SPECIFIC_BOT_SUCCESS",
  FETCH_SPECIFIC_BOT_FAILURE: "FETCH_SPECIFIC_BOT_FAILURE",
  POST_PLACE_ORDER_REQUEST: "POST_PLACE_ORDER_REQUEST",
  POST_PLACE_ORDER_SUCCESS: "POST_PLACE_ORDER_SUCCESS",
  POST_PLACE_ORDER_FAILURE: "POST_PLACE_ORDER_FAILURE",
  POST_CLOSE_ORDER_REQUEST: "POST_CLOSE_ORDER_REQUEST", // Added close action type
  POST_CLOSE_ORDER_SUCCESS: "POST_CLOSE_ORDER_SUCCESS", // Add the close success action type if needed
  POST_CLOSE_ORDER_FAILURE: "POST_CLOSE_ORDER_FAILURE", // Add the close failure action type if needed
}

// Action Creators

/**
 * Initiates the process of fetching specific bot data.
 * @param {string} botId - The unique identifier for the bot.
 * @returns {object} - Action object for requesting bot data.
 */
export const fetchSpecificBotRequest = botId => ({
  type: ActionTypes.FETCH_SPECIFIC_BOT_REQUEST,
  payload: botId,
})

/**
 * Handles successful fetching of specific bot data.
 * @param {object} botData - The detailed data of the specific bot.
 * @returns {object} - Action object for successful bot data fetching.
 */
export const fetchSpecificBotSuccess = botData => ({
  type: ActionTypes.FETCH_SPECIFIC_BOT_SUCCESS,
  payload: botData,
})

/**
 * Handles failure in fetching specific bot data.
 * @param {string} error - Error message or object indicating the failure reason.
 * @returns {object} - Action object for failed bot data fetching.
 */
export const fetchSpecificBotFailure = error => ({
  type: ActionTypes.FETCH_SPECIFIC_BOT_FAILURE,
  payload: { error },
})

/**
 * Initiates the process of posting a place order request.
 * @param {object} orderData - The data for placing the order.
 * @returns {object} - Action object for requesting to place an order.
 */
export const postPlaceOrderRequest = orderData => ({
  type: ActionTypes.POST_PLACE_ORDER_REQUEST,
  payload: orderData,
})

/**
 * Handles successful placement of an order.
 * @param {object} orderResponse - The response data after placing the order.
 * @returns {object} - Action object for successful order placement.
 */
export const postPlaceOrderSuccess = orderResponse => ({
  type: ActionTypes.POST_PLACE_ORDER_SUCCESS,
  payload: orderResponse,
})

/**
 * Handles failure in placing an order.
 * @param {string} error - Error message or object indicating the failure reason.
 * @returns {object} - Action object for failed order placement.
 */
export const postPlaceOrderFailure = error => ({
  type: ActionTypes.POST_PLACE_ORDER_FAILURE,
  payload: { error },
})

/**
 * Initiates the process of posting a close order request.
 * @param {object} closeOrderData - The data for closing the order.
 * @returns {object} - Action object for requesting to close an order.
 */
export const postCloseOrderRequest = closeOrderData => ({
  type: ActionTypes.POST_CLOSE_ORDER_REQUEST,
  payload: closeOrderData,
})

/**
 * Handles successful closing of an order.
 * @param {object} closeOrderResponse - The response data after closing the order.
 * @returns {object} - Action object for successful order closure.
 */
export const postCloseOrderSuccess = closeOrderResponse => ({
  type: ActionTypes.POST_CLOSE_ORDER_SUCCESS,
  payload: closeOrderResponse,
})

/**
 * Handles failure in closing an order.
 * @param {string} error - Error message or object indicating the failure reason.
 * @returns {object} - Action object for failed order closure.
 */
export const postCloseOrderFailure = error => ({
  type: ActionTypes.POST_CLOSE_ORDER_FAILURE,
  payload: { error },
})
