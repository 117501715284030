import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { addNewNfts, getNfts, deleteNfts } from "../../store/nfts/actions";
import { get } from "lodash";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Carousel,
  CarouselItem,
  CarouselControl,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

const WalletBalance = ({ walletBalanceData, loading }) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const animating = useRef(false);

  let blofin = 0,
    bybit = 0,
    bitget = 0,
    binance = 0;
  const calculateTotalBalance = (balanceData) => {
    return (
      balanceData.blofin +
      balanceData.bybit +
      balanceData.bitget +
      balanceData.binance
    );
  };

  const totalBalance =
    walletBalanceData && walletBalanceData.balance
      ? calculateTotalBalance(walletBalanceData.balance)
      : 0;

  const series =
    walletBalanceData && walletBalanceData.balance
      ? [
          walletBalanceData.balance.blofin,
          walletBalanceData.balance.bybit,
          walletBalanceData.balance.bitget,
          walletBalanceData.balance.binance,
        ].map((balance) => (balance / totalBalance) * 100)
      : [];

  // Check if the data is loaded
  if (!loading && walletBalanceData && walletBalanceData.balance) {
    blofin = walletBalanceData.balance.blofin;
    bybit = walletBalanceData.balance.bybit;
    bitget = walletBalanceData.balance.bitget;
    binance = walletBalanceData.balance.binance;
  }

  const walletOptions = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "35%",
          background: "transparent",
        },
        track: {
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 12,
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (val) {
              return val + "%";
            },
          },
          total: {
            show: true,
            label: "Total",
            color: "#373d3f",
            fontSize: "16px",
            fontWeight: 600,
            formatter: function (w) {
              const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              return total.toFixed(2) + "%"; // Formats the number to two decimal places
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["#F2A900", "#556ee6", "#f1b44c", "#34c38f"], // Example colors
    labels: ["BloFin", "Bybit", "Bitget", "Binance"],
    legend: { show: false },
  };

  const connectMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length > 0) {
          console.log("Connected to MetaMask!");
          console.log("Wallet Address:", accounts[0]);

          // Dispatch action to add new NFT with the wallet address
          dispatch(addNewNfts({ walletAddress: accounts[0] }));
        } else {
          console.error("No accounts found");
        }
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      alert(
        "MetaMask is not installed. Please consider installing it: https://metamask.io/"
      );
    }
  };

  const handleCarouselControl = (direction) => {
    if (animating.current) return;
    const newIndex =
      direction === "next"
        ? activeIndex === items.length - 1
          ? 0
          : activeIndex + 1
        : activeIndex === 0
        ? items.length - 1
        : activeIndex - 1;
    setActiveIndex(newIndex);
  };

  const nftsData = useSelector((state) => state.nftsReducer.nfts);

  useEffect(() => {
    dispatch(getNfts()); // Fetch NFTs on component mount
  }, [dispatch]);

  // Prepare carousel items from NFT data
  let items = [];
  if (nftsData && Array.isArray(nftsData)) {
    nftsData.forEach((nft) => {
      if (nft && nft.fields && nft.fields.nfts) {
        const ids = nft.fields.nfts.split(",");
        ids.forEach((id) => {
          items.push({
            src: `https://crodl.com/static/crodlnfts/${id}.png`,
            altText: `NFT ${id}`,
            caption: `NFT ${id}`,
          });
        });
      }
    });
  }

  const slides = items.map((item) => (
    <CarouselItem
      onExiting={() => (animating.current = true)}
      onExited={() => (animating.current = false)}
      key={item.src}
    >
      <img src={item.src} className="d-block img-fluid" alt={item.altText} />
    </CarouselItem>
  ));

  if (loading) {
    return (
      <Col xl="8">
        <Card>
          <CardBody className="text-center">
            <Spinner color="primary" />
            <h5 className="mt-2">Fetching data from exchanges...</h5>
          </CardBody>
        </Card>
      </Col>
    );
  }

  return (
    <React.Fragment>
      <Row>
        {" "}
        <Col xl="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-3">Wallet Balance</h4>

              <Row>
                <Col lg="4">
                  <div className="mt-4">
                    <p>Available Balance</p>
                    <h4>${totalBalance.toFixed(2)}</h4>
                  </div>
                </Col>

                <Col lg="4" sm="6">
                  <div id="wallet-balance-chart">
                    <ReactApexChart
                      options={walletOptions}
                      series={series}
                      type="radialBar"
                      height={300}
                      className="apex-charts"
                    />
                  </div>
                </Col>
                <Col
                  lg="4"
                  sm="6"
                  className="d-flex flex-column justify-content-between align-self-center"
                >
                  <div className="mb-4">
                    <p className="mb-2">
                      <i
                        className="mdi mdi-circle align-middle font-size-10 me-2 "
                        style={{ color: "#F2A900" }}
                      />
                      BloFin
                    </p>
                    <h5>${blofin.toFixed(2)}</h5>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />
                      Bybit
                    </p>
                    <h5>${bybit.toFixed(2)}</h5>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning" />
                      Bitget
                    </p>
                    <h5>${bitget.toFixed(2)}</h5>
                  </div>

                  <div>
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info" />
                      Binance
                    </p>
                    <h5>${binance.toFixed(2)}</h5>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl="4">
          <Card>
            <CardBody>
              {nftsData.length === 0 ? (
                <div className="d-grid gap-2">
                  <button
                    type="button"
                    className="btn-lg btn btn-outline-primary"
                    onClick={connectMetamask}
                  >
                    Connect MetaMask
                  </button>
                </div>
              ) : (
                <Carousel
                  activeIndex={activeIndex}
                  next={() => handleCarouselControl("next")}
                  previous={() => handleCarouselControl("prev")}
                >
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={() => handleCarouselControl("prev")}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={() => handleCarouselControl("next")}
                  />
                </Carousel>
              )}
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default WalletBalance;
