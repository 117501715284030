import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import SetNewPassword from "./auth/setnewpwd/reducer"; // Renamed for consistency
import Profile from "./auth/profile/reducer";

import BybitConnection from "./exchange/reducer";
import BitgetConnection from "./exchangeBitget/reducer";
import BinanceConnection from "./exchangeBinance/reducer";
import BloFinConnection from "./exchangeBloFin/reducer";
import BotReducer from "./bots/reducer";
import BotSpecificReducer from "./bot/reducer";
import DashboardReducer from "./dashboard/reducer";
import notificationsReducer from "./notifications/reducer";
import nftsReducer from "./nfts/reducer";

import paymentReducer from "./payments/reducer";

import botAdvanceSettingsReducer from "./botsAdvanceSettings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  SetNewPassword,
  BybitConnection,
  BitgetConnection,
  BotReducer,
  BotSpecificReducer,
  DashboardReducer,
  BinanceConnection,
  BloFinConnection,
  notificationsReducer,
  nftsReducer,
  paymentReducer,
  botAdvanceSettingsReducer,
});

export default rootReducer;
