// actions.js
import { ActionTypes } from "./actionTypes"

export const fetchDashboardRequest = () => ({
  type: ActionTypes.FETCH_DASHBOARD_REQUEST,
})

export const fetchDashboardSuccess = dashboardData => ({
  type: ActionTypes.FETCH_DASHBOARD_SUCCESS,
  payload: dashboardData,
})

export const fetchDashboardFailure = error => ({
  type: ActionTypes.FETCH_DASHBOARD_FAILURE,
  payload: { error },
})

export const fetchUserInfoDashboardRequest = () => ({
  type: ActionTypes.FETCH_USER_INFO_DASHBOARD_REQUEST,
})

export const fetchUserInfoDashboardSuccess = dashboardData => ({
  type: ActionTypes.FETCH_USER_INFO_DASHBOARD_SUCCESS,
  payload: dashboardData,
})

export const fetchUserInfoDashboardFailure = error => ({
  type: ActionTypes.FETCH_USER_INFO_DASHBOARD_FAILURE,
  payload: { error },
})
