// import React, { useState, useEffect } from "react";
// import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { get, post } from "../../helpers/api_helper";

// const TelegramConnect = () => {
//   const [enabled, setEnabled] = useState(false);
//   const [telegramChatId, setTelegramChatId] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const fetchSettings = async () => {
//       setIsLoading(true);
//       try {
//         const response = await get(`/telegram/settings`);
//         const { enabled, telegram_chat_id } = response.data; // Adjust based on your data structure
//         setEnabled(enabled);
//         setTelegramChatId(telegram_chat_id);
//       } catch (error) {
//         toast.error("Failed to fetch settings");
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchSettings();
//   }, []);

//   const handleSubmit = async () => {
//     setIsLoading(true);
//     try {
//       await post(`/telegram/settings`, {
//         enabled,
//         telegram_chat_id: telegramChatId,
//       });
//       toast.success("Settings updated successfully");
//     } catch (error) {
//       toast.error("Failed to update settings");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const authUserData = localStorage.getItem("authUserData");
//   const id = authUserData ? JSON.parse(authUserData).id : null;
//   const email = authUserData ? JSON.parse(authUserData).email : null;

//   return (
//     <div className="page-content">
//       <Form>
//         <FormGroup>
//           <Label for="telegramChatId">Telegram Chat ID</Label>
//           <Input
//             type="text"
//             id="telegramChatId"
//             value={telegramChatId}
//             onChange={(e) => setTelegramChatId(e.target.value)}
//             placeholder="Enter your Telegram Chat ID"
//           />
//         </FormGroup>
//         <FormGroup check>
//           <Label check>
//             <Input
//               type="checkbox"
//               checked={enabled}
//               onChange={(e) => setEnabled(e.target.checked)}
//             />{" "}
//             Enable Telegram Notifications
//           </Label>
//         </FormGroup>
//         <Button
//           color="primary"
//           className="mt-3 w-100"
//           onClick={handleSubmit}
//           disabled={isLoading}
//         >
//           {isLoading ? <Spinner size="sm" /> : "Save Settings"}
//         </Button>
//         <a
//           href={`https://t.me/CrodlNotificationsBot?start=${id}`}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="btn btn-primary mt-3 w-100"
//         >
//           Connect Telegram automatically
//         </a>

//         <ToastContainer />
//       </Form>
//     </div>
//   );
// };

// export default TelegramConnect;
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get, post } from "../../helpers/api_helper";
import "./TelegramConnect.css"; // Make sure to create a CSS file for custom styles

const TelegramConnect = () => {
  const [enabled, setEnabled] = useState(false);
  const [telegramChatId, setTelegramChatId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await get(`/telegram/settings`);
        const { enabled, telegram_chat_id } = response.data;
        setEnabled(enabled);
        setTelegramChatId(telegram_chat_id);
      } catch (error) {
        toast.error("Failed to fetch settings");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await post(`/telegram/settings`, {
        enabled,
        telegram_chat_id: telegramChatId,
      });
      toast.success("Settings updated successfully");
    } catch (error) {
      toast.error("Failed to update settings");
    } finally {
      setIsLoading(false);
    }
  };

  const authUserData = localStorage.getItem("authUserData");
  const id = authUserData ? JSON.parse(authUserData).id : null;
  const email = authUserData ? JSON.parse(authUserData).email : null;

  return (
    <div className="page-content">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mt-5">
              <CardBody>
                <CardTitle tag="h5" className="text-center">
                  Telegram Notifications
                </CardTitle>
                <Form>
                  <FormGroup>
                    <Label for="telegramChatId">Telegram Chat ID</Label>
                    <Input
                      type="text"
                      id="telegramChatId"
                      value={telegramChatId}
                      onChange={(e) => setTelegramChatId(e.target.value)}
                      placeholder="Enter your Telegram Chat ID"
                    />
                  </FormGroup>
                  <FormGroup check className="mb-3">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={enabled}
                        onChange={(e) => setEnabled(e.target.checked)}
                      />{" "}
                      Enable Telegram Notifications
                    </Label>
                  </FormGroup>
                  <Button
                    color="primary"
                    className="mt-3 w-100"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner size="sm" /> : "Save Settings"}
                  </Button>
                  <a
                    href={`https://t.me/CrodlNotificationsBot?start=${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-secondary mt-3 w-100"
                  >
                    Connect Telegram Automatically
                  </a>

                  <ToastContainer />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TelegramConnect;
