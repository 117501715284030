// import React from "react"
// import { Card, CardBody, Col, Container, Row } from "reactstrap"

// //import images
// import Comp from "../../assets/images/banner/banner.png"

// const Banner = () => {
//   document.title = "Dashboard | Crodl "
//   return (
//     <React.Fragment>
//       <Row>
//         <Col lg={12} className="mt-4">
//           <Card className="mx-n4 mt-n4 bg-primary bg-gradient">
//             <CardBody>
//               <div className="d-flex justify-content-between align-items-center">
//                 {/* Left: Image */}
//                 <img
//                   src={Comp} // Replace with path to image
//                   alt="Avatar"
//                   className="avatar-xxl rounded-circle"
//                 />

//                 {/* Center: Text */}
//                 <div className="text-left">
//                   <h2 className="mb-1">$5000+ in Deposit Bonuses</h2>
//                   <h5 className="mb-1">
//                     Elevate your trading experience with Bitget or Bybit!
//                     Whether you choose Bybit's dynamic platform or Bitget's
//                     innovative solutions, just sign up, make your initial
//                     deposit, and follow the straightforward guidelines to unlock
//                     your exclusive bonus. Dive into a world of possibilities and
//                     trading excellence — terms and conditions apply.
//                   </h5>
//                   <a
//                     href="https://partner.bybit.com/b/12193" // Add your Bybit account creation link here
//                     className="btn btn-success mt-2 me-2"
//                     role="button" // This makes it clear that the anchor is serving as a button
//                   >
//                     Create Bybit Account{" "}
//                     <i className="bx bx-chart align-baseline ms-1"></i>
//                   </a>

//                   <a
//                     href="https://partner.bitget.com/bg/CYZ9V5" // Add your Bitget account creation link here
//                     className="btn btn-success mt-2 me-2"
//                     role="button"
//                   >
//                     {" "}
//                     Create Bitget Account
//                     <i className="bx bx-chart align-baseline ms-1"></i>
//                   </a>

//                   <a
//                     href="https://accounts.binance.com/register?ref=LEVBS20C" // Add your Bitget account creation link here
//                     className="btn btn-success mt-2"
//                     role="button"
//                   >
//                     Create Binance Account{" "}
//                     <i className="bx bx-chart align-baseline ms-1"></i>
//                   </a>
//                 </div>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </React.Fragment>
//   )
// }

// export default Banner
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Comp from "../../assets/images/banner/banner.png";

const customStyles = {
  background: "linear-gradient(140deg, black, #6366f1)",
};

const Banner = () => {
  document.title = "Dashboard | Crodl";

  const bannerImgStyles = {
    height: "250px",
    width: "auto",
    objectFit: "cover",
  };

  const mobileBannerImgStyles = {
    height: "150px",
  };

  return (
    <React.Fragment>
      <style>
        {`
          @media (max-width: 767px) {
            .banner-img {
              height: 180px !important;
            }
          }
        `}
      </style>
      <Row className="justify-content-center">
        <Col lg={12} className="mt-4">
          <a
            href="https://partner.blofin.com/d/crodl"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card className="mx-n4 mt-n4">
              <CardBody
                className="p-0 d-flex justify-content-center"
                style={customStyles}
              >
                <img
                  src={Comp}
                  alt="Banner"
                  className="img-fluid banner-img"
                  style={bannerImgStyles}
                />
              </CardBody>
            </Card>
          </a>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Banner;
