import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

// Include Both Helper File with needed methods
import { postforgetPassword } from "../../../helpers/backend_helper";

/**
 * Saga for handling user forget password request with a delay.
 * 
 * @param {object} payload The payload containing user data and history.
 */
function* forgetUser({ payload: { user, history } }) {
  try {
    // Introduce a 3-second delay
    yield delay(3000);

    const response = yield call(postforgetPassword, {
      email: user.email,
    });

    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your mailbox"
        )
      );
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

/**
 * Watcher saga that triggers the forgetUser saga on FORGET_PASSWORD action.
 */
export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

/**
 * Root saga for the forget password flow.
 */
function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
