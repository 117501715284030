// paymentSaga.js
import { call, put, takeEvery } from "redux-saga/effects"
import { CHECK_TXID } from "./actionTypes"
import { checkTxidSuccess, checkTxidFail } from "./actions"
import { postPayment } from "../../helpers/backend_helper"
import { toast } from "react-toastify"

function* checkTxidSaga({ payload: txid }) {
  try {
    const response = yield call(postPayment, txid)
    if (response && response.success) {
      yield put(checkTxidSuccess(response.data))
      toast.success(response.message)
    } else {
      yield put(checkTxidFail("TXID check failed. Please try again."))
      toast.error(response.message)
    }
  } catch (error) {
    yield put(checkTxidFail(error.message))
  }
}

export default function* paymentSaga() {
  yield takeEvery(CHECK_TXID, checkTxidSaga)
}
