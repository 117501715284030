// paymentReducer.js
import { CHECK_TXID, CHECK_TXID_SUCCESS, CHECK_TXID_FAIL } from "./actionTypes"

const initialState = {
  txidCheckResult: null,
  isLoading: false,
  success: false,
  error: null,
}
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TXID:
      // When a TXID check is initiated, reset previous results and errors
      return {
        ...state,
        txidCheckResult: null,
        isLoading: true,
        error: null,
      }
    case CHECK_TXID_SUCCESS:
      // When a TXID check succeeds, store the result and clear any error
      return {
        ...state,
        txidCheckResult: action.payload, // Assuming payload contains the check result
        isLoading: false,
        success: true,
        error: null, // Clear any existing error
      }
    case CHECK_TXID_FAIL:
      // When a TXID check fails, store the error message
      return {
        ...state,
        error: action.payload, // Assuming payload contains the error message
        success: false,
        isLoading: false,
      }
    default:
      // Return the current state for any other action types
      return state
  }
}

export default paymentReducer
