import React from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import "./section2.css" // Ensure this CSS file is created and linked
import discordIcon from "../../../assets/images/crypto/discordLogo.svg" // Update path as needed

const Section2 = () => {
  return (
    <Container className="section2 my-5">
      <Row className="align-items-center">
        <Col md="12">
          <Row>
            <Col xs="12" className="text-center">
              <h2 className="discord-title">Community</h2>
              <p className="discord-subtitle">
                Join thousands of traders in our Discord community.
              </p>
            </Col>
          </Row>
          <Card className="feature-card">
            <Row className="no-gutters align-items-center">
              <Col md="8">
                <CardBody>
                  <h2>Join Our Discord Community</h2>
                  <p>
                    Elevate your trading game with our dynamic Discord community
                    focused on automated trading bots, savvy indicators, and
                    real-time support. Connect with fellow traders, share
                    strategies, and access exclusive tools designed to give you
                    an edge. Dive into a hub of innovation and expertise that
                    will empower your trading decisions. Join us to unlock your
                    trading potential today!
                  </p>
                  <Button
                    className="btn btn-outline-primary"
                    href="https://discord.gg/bwEHKx3Jkt"
                  >
                    {" "}
                    Join Discord
                  </Button>
                </CardBody>
              </Col>
              <Col md="4" className="text-center">
                <img src={discordIcon} alt="Discord" className="discord-icon" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Section2
