// import React, { useEffect } from "react"
// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Input,
//   Label,
//   Form,
//   FormFeedback,
// } from "reactstrap"

// // Formik Validation
// import * as Yup from "yup"
// import { useFormik } from "formik"

// // action
// import { registerUser, apiError } from "../../store/actions"

// //redux
// import { useSelector, useDispatch } from "react-redux"
// import { createSelector } from "reselect"

// import { Link, useNavigate } from "react-router-dom"

// // import images
// import profileImg from "../../assets/images/profile-img.png"
// import logo from "../../assets/images/logo.svg"

// const Register = props => {
//   // Example validation functions for password criteria
//   function validatePassword(password) {
//     let errorMessage = ""
//     if (password.length < 8) {
//       errorMessage += "Password must be at least 8 characters long. "
//     }
//     if (!/[A-Z]/.test(password)) {
//       errorMessage += "Password must contain at least one uppercase letter. "
//     }
//     if (!/[a-z]/.test(password)) {
//       errorMessage += "Password must contain at least one lowercase letter. "
//     }
//     if (!/[0-9]/.test(password)) {
//       errorMessage += "Password must contain at least one digit. "
//     }
//     if (!/[!@#$%^&*]/.test(password)) {
//       errorMessage +=
//         "Password must contain at least one special character (!@#$%^&*). "
//     }
//     return errorMessage
//   }

//   //meta title
//   document.title = "Register | Crodl"

//   const dispatch = useDispatch()
//   const navigate = useNavigate()

//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       email: "",
//       name: "",
//       password: "",
//     },
//     // In your validationSchema for Formik
//     validationSchema: Yup.object({
//       email: Yup.string().required("Please Enter Your Email"),
//       name: Yup.string().required("Please Enter Your Username"),
//       password: Yup.string()
//         .required("Please Enter Your Password")
//         .test("password-validation", value => {
//           const errorMessage = validatePassword(value)
//           // If there's no error message, the password is valid
//           if (!errorMessage) return true
//           // Return the error message to Formik to display
//           return new Yup.ValidationError(errorMessage, null, "password")
//         }),
//     }),
//     onSubmit: values => {
//       dispatch(registerUser(values))
//     },
//   })

//   const selectAccountState = state => state.Account
//   const AccountProperties = createSelector(selectAccountState, account => ({
//     user: account.user,
//     registrationError: account.registrationError,
//     success: account.success,
//     // loading: account.loading,
//   }))

//   const {
//     user,
//     registrationError,
//     success,
//     // loading
//   } = useSelector(AccountProperties)

//   useEffect(() => {
//     dispatch(apiError(""))
//   }, [])

//   useEffect(() => {
//     success && setTimeout(() => navigate("/login"), 2000)
//   }, [success])

//   return (
//     <React.Fragment>
//       <div className="home-btn d-none d-sm-block">
//         <Link to="/" className="text-dark">
//           <i className="bx bx-home h2" />
//         </Link>
//       </div>
//       <div className="account-pages my-5 pt-sm-5">
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={8} lg={6} xl={5}>
//               <Card className="overflow-hidden">
//                 <div className="bg-primary-subtle">
//                   <Row>
//                     <Col className="col-7">
//                       <div className="text-warning p-4">
//                         <h5 className="text-warning">Register</h5>
//                         <p>Welcome To Crodl.</p>
//                       </div>
//                     </Col>
//                     <Col className="col-5 align-self-end">
//                       <img src={profileImg} alt="" className="img-fluid" />
//                     </Col>
//                   </Row>
//                 </div>
//                 <CardBody className="pt-0">
//                   <div>
//                     <Link to="/">
//                       <div className="avatar-md profile-user-wid mb-4">
//                         <span className="avatar-title rounded-circle bg-light">
//                           <img src={logo} alt="" className="rounded-circle" />
//                         </span>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="p-2">
//                     <Form
//                       className="form-horizontal"
//                       onSubmit={e => {
//                         e.preventDefault()
//                         validation.handleSubmit()
//                         return false
//                       }}
//                     >
//                       {success ? (
//                         <Alert color="success">
//                           User Registered Successfully
//                         </Alert>
//                       ) : null}

//                       {registrationError ? (
//                         <Alert color="danger">{registrationError}</Alert>
//                       ) : null}

//                       <div className="mb-3">
//                         <Label className="form-label">Email</Label>
//                         <Input
//                           id="email"
//                           name="email"
//                           className="form-control"
//                           placeholder="Enter email"
//                           type="email"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.email || ""}
//                           invalid={
//                             validation.touched.email && validation.errors.email
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.email && validation.errors.email ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.email}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mb-3">
//                         <Label className="form-label">Name</Label>
//                         <Input
//                           name="name"
//                           type="text"
//                           placeholder="Enter Name"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.name || ""}
//                           invalid={
//                             validation.touched.name && validation.errors.name
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.name && validation.errors.name ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.name}
//                           </FormFeedback>
//                         ) : null}
//                       </div>
//                       <div className="mb-3">
//                         <Label className="form-label">Password</Label>
//                         <Input
//                           name="password"
//                           type="password"
//                           placeholder="Enter Password"
//                           onChange={validation.handleChange}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.password || ""}
//                           invalid={
//                             validation.touched.password &&
//                             validation.errors.password
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.password &&
//                         validation.errors.password ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.password}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mt-4">
//                         <button
//                           className="btn btn-primary btn-block "
//                           type="submit"
//                         >
//                           Register
//                         </button>
//                       </div>

//                       <div className="mt-4 text-center">
//                         <p className="mb-0">
//                           By registering you agree to Crodls{" "}
//                           <Link to="/disclaimer" className="text-primary">
//                             Terms of Use
//                           </Link>
//                         </p>
//                       </div>
//                     </Form>
//                   </div>
//                 </CardBody>
//               </Card>
//               <div className="mt-5 text-center">
//                 <p>
//                   Already have an account ?{" "}
//                   <Link to="/login" className="font-weight-medium text-primary">
//                     {" "}
//                     Login
//                   </Link>{" "}
//                 </p>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// export default Register

import React from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const Register = () => {
  //meta title
  document.title = "We have upgraded our bot | Crodl";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-warning p-4">
                        <h5 className="text-warning">We Have Upgraded!</h5>
                        <p>Visit our new bot and get a 30-day free trial.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2 text-center">
                    <a
                      href="https://crodltrading.com"
                      className="btn btn-primary"
                    >
                      Visit New Bot
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
