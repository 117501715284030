import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { AuthProvider } from "./AuthContext" // Import the AuthProvider
import { layoutTypes } from "./constants/layout"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import i18n from "./i18n"

const getLayout = layoutType => {
  switch (layoutType) {
    case layoutTypes.HORIZONTAL:
      return HorizontalLayout
    default:
      return VerticalLayout
  }
}

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)
  const Layout = getLayout(layoutType)

  return (
    <AuthProvider>
      {" "}
      {/* Wrap your application in the AuthProvider */}
      <React.Fragment>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
            />
          ))}
        </Routes>
      </React.Fragment>
    </AuthProvider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
