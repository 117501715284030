import React from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import "./pricing.css" // Make sure this CSS file is created and linked

const plans = [
  {
    title: "Monthly",
    price: "$5",
    description: "For Newbies Starting With Automated Trading",
    features: ["Full Bot Access", "Discord Support"],
    buttonText: "Get Started",
    buttonVariant: "outline-primary",
  },
  {
    title: "Yearly",
    price: "$100",
    description:
      "Discover Our Comprehensive Suite of Indicators Plus One-Year Access to Our Trading Bots",
    features: [
      "Full Bot Access",
      "Full Discord",
      "20+ Indicators",
      "Bot Updates",
      "New Indicator Updates",
      "Trading Signals",
    ],
    buttonText: "Get Started",
    buttonVariant: "outline-primary",
  },
  {
    title: "Lifetime",
    price: "$250",
    description: "Support Us By Joining Our Lifetime Membership",
    features: [
      "Full Bot Access",
      "Full Discord",
      "20+ Indicators",
      "Bot Updates",
      "New Indicator Updates",
      "Trading Signals",
    ],
    buttonText: "Get Started",
    buttonVariant: "outline-primary",
  },
]

const Pricing = () => {
  return (
    <Container className="pricing-container my-5">
      <Row>
        <Col>
          <h2 className="pricing-header">Membership</h2>
          <p className="pricing-subtitle">
            Choose the plan that fits your needs.
          </p>
        </Col>
      </Row>
      <Row>
        {plans.map((plan, index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <Card className={`pricing-card ${plan.title.toLowerCase()}`}>
              <CardBody>
                <CardTitle tag="h3">{plan.title}</CardTitle>
                <h2>{plan.price}</h2>
                <CardText>{plan.description}</CardText>
                <ul className="features-list">
                  {plan.features.map((feature, index) => (
                    <li key={index}>
                      <i className="fas fa-check" /> {feature}
                    </li>
                  ))}
                </ul>
                <a href="/subscriptions" className="btn btn-outline-primary">
                  {plan.buttonText}
                </a>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Pricing
