import { BotActionTypes } from "./actionTypes"

// Initial state for the bot data and order placement
const initialState = {
  loading: false,
  botData: null,
  orderResponse: null, // Add order response state
  closeOrderResponse: null, // Add close order response state
  error: null,
}

const botSpecificReducer = (state = initialState, action) => {
  switch (action.type) {
    case BotActionTypes.FETCH_SPECIFIC_BOT_REQUEST:
      // Initiating the fetching of specific bot data; set loading to true
      return {
        ...state,
        loading: true,
        error: null,
      }

    case BotActionTypes.FETCH_SPECIFIC_BOT_SUCCESS:
      return {
        ...state,
        loading: false,
        botData: action.payload, // Confirm this is the correct data structure
        error: null,
      }

    case BotActionTypes.FETCH_SPECIFIC_BOT_FAILURE:
      // Encountered an error fetching specific bot data; update error and reset loading
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case BotActionTypes.POST_PLACE_ORDER_REQUEST:
      // Initiating the placing of an order; set loading to true
      return {
        ...state,
        loading: true,
        error: null,
      }

    case BotActionTypes.POST_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderResponse: action.payload, // Set order response
        error: null,
      }

    case BotActionTypes.POST_PLACE_ORDER_FAILURE:
      // Encountered an error placing an order; update error and reset loading
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case BotActionTypes.POST_CLOSE_ORDER_REQUEST:
      // Initiating the closing of an order; set loading to true
      return {
        ...state,
        loading: true,
        error: null,
      }

    case BotActionTypes.POST_CLOSE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        closeOrderResponse: action.payload, // Set close order response
        error: null,
      }

    case BotActionTypes.POST_CLOSE_ORDER_FAILURE:
      // Encountered an error closing an order; update error and reset loading
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      // No matching action type; return the existing state
      return state
  }
}

export default botSpecificReducer
