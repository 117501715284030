// Binance
// Action Types
export const RESET_SUBMIT_BINANCE_API_DATA = "RESET_SUBMIT_BINANCE_API_DATA"
export const SUBMIT_BINANCE_API_DATA = "SUBMIT_BINANCE_API_DATA"
export const SUBMIT_BINANCE_API_DATA_SUCCESS = "SUBMIT_BINANCE_API_DATA_SUCCESS"
export const SUBMIT_BINANCE_API_DATA_FAIL = "SUBMIT_BINANCE_API_DATA_FAIL"
export const FETCH_API_DATA_BINANCE = "FETCH_API_DATA_BINANCE"
export const FETCH_API_DATA_SUCCESS_BINANCE = "FETCH_API_DATA_SUCCESS_BINANCE"
export const FETCH_API_DATA_FAIL_BINANCE = "FETCH_API_DATA_FAIL_BINANCE"
export const DELETE_API_DATA_BINANCE = "DELETE_API_DATA_BINANCE"
export const DELETE_API_DATA_SUCCESS_BINANCE = "DELETE_API_DATA_SUCCESS_BINANCE"
export const DELETE_API_DATA_FAIL_BINANCE = "DELETE_API_DATA_FAIL_BINANCE"

// Binance

/**
 * Action to submit Bybit API data.
 * @param {Object} data - The API data to be submitted.
 */
export const submitBinanceApiData = data => ({
  type: SUBMIT_BINANCE_API_DATA,
  payload: data,
})

/**
 * Action to be dispatched when Bybit API data submission succeeds.
 * @param {Object} response - The response data from the submission success.
 */
export const submitBinanceApiDataSuccess = response => ({
  type: SUBMIT_BINANCE_API_DATA_SUCCESS,
  payload: response,
})

export const resetSubmitBinanceApiDataSuccess = () => ({
  type: RESET_SUBMIT_BINANCE_API_DATA,
})

/**
 * Action to be dispatched when Bybit API data submission fails.
 * @param {string} error - The error message or object from the submission failure.
 */
export const submitBinanceApiDataFail = error => ({
  type: SUBMIT_BINANCE_API_DATA_FAIL,
  payload: error,
})

// actions.js
export const fetchApiDataBinance = () => ({
  type: FETCH_API_DATA_BINANCE,
})

export const fetchApiDataSuccessBinance = data => ({
  type: FETCH_API_DATA_SUCCESS_BINANCE,
  payload: data,
})

export const fetchApiDataFailBinance = error => ({
  type: FETCH_API_DATA_FAIL_BINANCE,
  payload: error,
})

// Handles successful deletion of the API data
export const deleteApiDataBinance = secToken => ({
  type: DELETE_API_DATA_BINANCE,
  payload: secToken,
})

// Handles successful deletion of the API data
export const deleteApiDataSuccessBinance = apiData => ({
  type: DELETE_API_DATA_SUCCESS_BINANCE,
  payload: apiData,
})

// Handles failure in deleting the API data with an error message or object
export const deleteApiDataFailBinance = error => ({
  type: DELETE_API_DATA_FAIL_BINANCE,
  payload: error,
})
