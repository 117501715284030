// Constants for submitting Bybit API data
export const RESET_SUBMIT_BYBIT_API_DATA = "RESET_SUBMIT_BYBIT_API_DATA"
export const SUBMIT_BYBIT_API_DATA = "SUBMIT_BYBIT_API_DATA"
export const SUBMIT_BYBIT_API_DATA_SUCCESS = "SUBMIT_BYBIT_API_DATA_SUCCESS"
export const SUBMIT_BYBIT_API_DATA_FAIL = "SUBMIT_BYBIT_API_DATA_FAIL"

// Constants for adding new Bybit API data
export const ADD_NEW_BYBIT_API_DATA = "ADD_NEW_BYBIT_API_DATA"
export const ADD_BYBIT_API_DATA_SUCCESS = "ADD_BYBIT_API_DATA_SUCCESS"
export const ADD_BYBIT_API_DATA_FAIL = "ADD_BYBIT_API_DATA_FAIL"

// API DATAS
export const FETCH_API_DATA = "FETCH_API_DATA"
export const FETCH_API_DATA_SUCCESS = "FETCH_API_DATA_SUCCESS"
export const FETCH_API_DATA_FAIL = "FETCH_API_DATA_FAIL"

// DELETE API DATAS
export const DELETE_API_DATA = "DELETE_API_DATA"
export const DELETE_API_DATA_SUCCESS = "DELETE_API_DATA_SUCCESS"
export const DELETE_API_DATA_FAIL = "DELETE_API_DATA_FAIL"
