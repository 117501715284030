// // AuthContext.js
// import React, { createContext, useContext, useState, useEffect } from "react"
// import { useNavigate } from "react-router-dom" // Import useNavigate
// import jwtDecode from "jwt-decode" // Import jwt-decode

// const AuthContext = createContext(null)

// export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false)
//   const navigate = useNavigate() // Initialize navigate function

//   const logout = () => {
//     localStorage.removeItem("authUser")
//     setIsAuthenticated(false)
//     navigate("/login") // Redirect to login page
//   }

//   const checkAuthentication = () => {
//     const authUserData = localStorage.getItem("authUser")
//     if (authUserData) {
//       const decodedToken = jwtDecode(authUserData) // Decode the token
//       const currentTime = Date.now() / 1000 // Get current time in seconds

//       if (decodedToken.exp < currentTime) {
//         // Token is expired
//         logout() // Logout user
//       } else {
//         // Token is valid
//         setIsAuthenticated(true)
//       }
//     } else {
//       // No token found
//       setIsAuthenticated(false)
//     }
//   }

//   useEffect(() => {
//     checkAuthentication()
//   }, [])

//   return (
//     <AuthContext.Provider
//       value={{ isAuthenticated, logout, checkAuthentication }}
//     >
//       {children}
//     </AuthContext.Provider>
//   )
// }

// export const useAuth = () => useContext(AuthContext)

// export const useAuth = () => useContext(AuthContext)
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import { useNavigate } from "react-router-dom"
import jwtDecode from "jwt-decode"

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const navigate = useNavigate()

  const logout = useCallback(() => {
    localStorage.removeItem("authUser")
    setIsAuthenticated(false)
    navigate("/login", { replace: true }) // Replace the current entry in the history stack
  }, [navigate])

  const checkAuthentication = useCallback(() => {
    const authUserData = localStorage.getItem("authUser")
    if (authUserData) {
      try {
        // Check if the token format is correct
        if (authUserData.split(".").length === 3) {
          const decodedToken = jwtDecode(authUserData)
          const currentTime = Date.now() / 1000
          if (decodedToken.exp < currentTime) {
            logout()
          } else {
            setIsAuthenticated(true)
          }
        } else {
          logout()
        }
      } catch (error) {
        logout()
      }
    } else {
      setIsAuthenticated(false)
    }
  }, [logout])

  useEffect(() => {
    checkAuthentication() // Check immediately on mount
    const interval = setInterval(checkAuthentication, 60 * 1000) // Check every minute
    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [checkAuthentication])

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, logout, checkAuthentication }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
4
