import React from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import "./nfts.css" // New CSS file for NFTs
import nfthome from "../../../assets/images/crypto/nfthome.svg" // Update the image path as needed

const NftsHomepage = () => {
  return (
    <Container className="nfts-container my-5">
      <Row className="align-items-center">
        <Col md="12">
          <Row>
            <Col xs="12" className="text-center">
              <h2 className="nfts-title">Explore Our NFTs</h2>
              <p className="nfts-subtitle">Trading Pass</p>
            </Col>
          </Row>
          <Card className="feature-card">
            <Row className="no-gutters align-items-center">
              <Col md="8">
                <CardBody>
                  <h2>Benefits of Owning Our NFTs</h2>
                  <p>
                    Ownership of our NFTs unlocks exclusive access to our
                    advanced trading bot, enabling you to automate your trades
                    seamlessly. Additionally, owning an NFT grants you the
                    privilege to access our extensive range of custom-built
                    trading indicators. Simply create a ticket in our Discord
                    community after your NFT acquisition to begin leveraging
                    these trading tools.
                  </p>

                  <Button
                    className="btn btn-outline-primary"
                    href="https://opensea.io/collection/crodl-trading-pass"
                  >
                    Explore NFTs
                  </Button>
                </CardBody>
              </Col>
              <Col md="4" className="text-center">
                <img src={nfthome} alt="NFT Home" className="nfts-icon" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default NftsHomepage
