import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  UPDATE_ALL_NOTIFICATIONS,
} from "./actionTypes"
import {
  fetchNotificationsSuccess,
  fetchNotificationsFail,
  updateNotificationSuccess,
  updateNotificationFail,
  updateAllNotificationsSuccess,
  updateAllNotificationsFail,
} from "./actions"

// Replace with your actual API methods
import {
  getNotifications,
  updateNotificationReadStatus,
  updateAllNotificationReadStatus,
} from "../../helpers/backend_helper"

function* fetchNotificationsSaga() {
  try {
    const response = yield call(getNotifications)

    // Check if the response is an object with a 'success' property
    if (response && response.success) {
      // Put the single notification into an array
      yield put(fetchNotificationsSuccess([response]))
    } else {
      // Handle unexpected response format
    }
  } catch (error) {
    yield put(fetchNotificationsFail(error.message))
  }
}

function* updateNotificationSaga({ payload: notificationId }) {
  try {
    const response = yield call(updateNotificationReadStatus, notificationId)

    // Assuming the API call returns the updated notification object.
    if (response && response.notification) {
      yield put(updateNotificationSuccess(response.notification))
    } else {
      // Handle case where the response format is not as expected
      yield put(updateNotificationFail("Unexpected response format"))
    }
  } catch (error) {
    yield put(updateNotificationFail(error.message))
  }
}

function* updateAllNotificationsSaga() {
  try {
    // Call to update all notifications as read
    const updateResponse = yield call(updateAllNotificationReadStatus)
    if (updateResponse && updateResponse.success) {
      yield put(updateAllNotificationsSuccess()) // Dispatch success action
      // Fetch the updated notifications from the backend
      const fetchResponse = yield call(getNotifications)

      if (fetchResponse && fetchResponse.success) {
        // Assuming the response structure is correct and consistent with your original approach
        yield put(fetchNotificationsSuccess([fetchResponse]))
      } else {
        yield put(
          fetchNotificationsFail("Failed to fetch updated notifications")
        )
      }
    } else {
      console.error("Failed to update all notifications:", updateResponse)
      yield put(
        updateAllNotificationsFail("Failed to update all notifications")
      )
    }
  } catch (error) {
    yield put(updateAllNotificationsFail(error.message))
  }
}

export default function* notificationsSaga() {
  yield takeEvery(FETCH_NOTIFICATIONS, fetchNotificationsSaga)
  yield takeEvery(UPDATE_NOTIFICATION, updateNotificationSaga)
  yield takeEvery(UPDATE_ALL_NOTIFICATIONS, updateAllNotificationsSaga) // Add this line
}
