import { call, put, takeEvery } from "redux-saga/effects"
import {
  saveSettingsSuccess,
  saveSettingsFailure,
  fetchSettingsRequest,
  fetchSettingsSuccess,
  fetchSettingsFailure,
} from "./actions"
import { SAVE_SETTINGS_REQUEST, FETCH_SETTINGS_REQUEST } from "./actionTypes"
import {
  postBotAdvanceSettings,
  getSpecificBotAdvanceSettingsData,
} from "../../helpers/backend_helper" // Adjust the import path as needed
import { toast } from "react-toastify"

function* fetchBotAdvanceSettingsSaga({ payload }) {
  const { botId } = payload
  try {
    const response = yield call(getSpecificBotAdvanceSettingsData, botId)
    // console.log("settings", response)

    // Assuming the response structure includes a success flag and bots array
    if (response?.success && Array.isArray(response.bots)) {
      const settings = response.bots.map(bot => bot.fields) // Example transformation
      yield put(fetchSettingsSuccess(botId, settings))
    } else {
      // If the response does not include a success flag or expected data structure
      throw new Error(
        "Failed to fetch settings due to unexpected response format"
      )
    }
  } catch (error) {
    console.error("Fetch settings failed:", error) // Log error for debugging
    yield put(fetchSettingsFailure(botId, error.toString()))
    toast.error(`Fetch settings failed: ${error.toString()}`, {
      autoClose: 2000,
    })
  }
}

function* saveBotAdvanceSettingsSaga({ payload }) {
  try {
    const { botId, settings } = payload
    // Make sure the API call is structured correctly for your backend expectations
    const response = yield call(postBotAdvanceSettings, { botId, settings })

    if (response?.success) {
      // Dispatch the success action with the necessary payload
      yield put(saveSettingsSuccess(botId, settings))
      toast.success("Settings saved successfully", { autoClose: 2000 })
    } else {
      // Handle any error messages or status indicating failure
      throw new Error(response?.message || "Failed to save settings")
    }
  } catch (error) {
    // Dispatch the failure action if an exception is caught
    yield put(saveSettingsFailure(error.message))
    toast.error("Save settings failed: " + error.message, { autoClose: 2000 })
  }
}

export default function* BotsAdvanceSettingsSaga() {
  yield takeEvery(SAVE_SETTINGS_REQUEST, saveBotAdvanceSettingsSaga)
  yield takeEvery(FETCH_SETTINGS_REQUEST, fetchBotAdvanceSettingsSaga)
}
