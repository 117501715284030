import { call, put, takeEvery } from "redux-saga/effects"
import {
  SUBMIT_BYBIT_API_DATA,
  FETCH_API_DATA,
  DELETE_API_DATA,
} from "./actionTypes"
import {
  submitBybitApiDataSuccess,
  submitBybitApiDataFail,
  fetchApiDataSuccess,
  fetchApiDataFail,
  deleteApiDataSuccess,
  deleteApiDataFail,
} from "./actions" // Update this path if the actions are defined elsewhere
import {
  postbybitapidata,
  fetchApiData,
  deleteApiData,
} from "../../helpers/backend_helper" // Ensure these functions are exported from backend_helper
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
/**
 * Worker saga to handle the submission of Bybit API data.
 * @param {Object} action - The action object containing payload data.
 */
function* submitBybitApiDataSaga({ payload: data }) {
  try {
    const response = yield call(postbybitapidata, data)

    if (response && response.success) {
      yield put(submitBybitApiDataSuccess(response.data))
    } else {
      const errorMessage = response.message
      yield put(submitBybitApiDataFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(submitBybitApiDataFail(errorText))
    toast.error(errorText, { autoClose: 2000 })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* fetchApiDataSaga() {
  try {
    const response = yield call(fetchApiData) // Makes the API request
    if (response && response.success) {
      // Use response.api_data instead of response.data
      yield put(fetchApiDataSuccess(response.api_data))
    } else {
      const errorMessage = response.message || "Failed to fetch API data"
      yield put(fetchApiDataFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(fetchApiDataFail(errorText))
    toast.error(errorText || "Failed to fetch API data", { autoClose: 2000 })
  }
}

/**
 * Worker saga to handle fetching API data.
 */
function* deleteApiDataSaga({ payload: secToken }) {
  try {
    const response = yield call(deleteApiData, secToken)
    if (response && response.success) {
      yield put(deleteApiDataSuccess(secToken))
      const response = yield call(fetchApiData)
    } else {
      const errorMessage = response.message || "Bot deletion failed"
      yield put(deleteApiDataFail(errorMessage))
      toast.error(errorMessage, { autoClose: 2000 })
    }
  } catch (error) {
    const errorText = error.toString()
    yield put(deleteApiDataFail(errorText))
    toast.error(errorText || "Bot deletion failed", { autoClose: 2000 })
  }
}

/**
 * Watcher saga that spawns new worker sagas for each action type.
 */
function* bybitApiDataSaga() {
  yield takeEvery(SUBMIT_BYBIT_API_DATA, submitBybitApiDataSaga)
  yield takeEvery(FETCH_API_DATA, fetchApiDataSaga)
  yield takeEvery(DELETE_API_DATA, deleteApiDataSaga)
}

export default bybitApiDataSaga
