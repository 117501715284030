import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./footer.css" // Ensure this CSS file is created and linked
import discordIcon from "../../../assets/images/crypto/discordLogo.svg" // Update path as needed

const Footer_hero = () => {
  return (
    <footer className="footer-hero">
      <Container>
        <Row>
          <Col md="4">
            <h5>Reminder</h5>
            <p>
              Trading carries inherent risks. We encourage you to conduct
              thorough research and utilize Crodl at your own risk.
            </p>
          </Col>
          <Col md="4">
            <h5>Quick Links</h5>
            <ul>
              <li>
                <a
                  href="https://crodl.com/bybit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bybit
                </a>
              </li>
              <li>
                <a
                  href="https://partner.bitget.com/bg/CYZ9V5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bitget
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC_xSIQUivjCX3o1VW2BtGLw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://bingx.com/invite/HCGOD0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bingx
                </a>
              </li>
              <li>
                <a
                  href="https://www.mexc.com/en-US/register?inviteCode=mexc-1ciUT"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MEXC
                </a>
              </li>
              <li>
                <a
                  href="https://accounts.binance.com/register?ref=LEVBS20C"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Binance
                </a>
              </li>
            </ul>
          </Col>
          <Col md="4">
            <h5>Join Our Community</h5>
            <p>
              Connect with us on Discord for the latest updates and support.
            </p>
            <a href="https://discord.gg/bwEHKx3Jkt">
              <img src={discordIcon} alt="Join our Discord" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="footer-bottom text-center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0 }}>© {new Date().getFullYear()} Crodl</p>
              <a href="/disclaimer" style={{ marginLeft: "10px" }}>
                Disclaimer
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer_hero
