import { call, put, takeLatest } from "redux-saga/effects"
import {
  fetchSpecificBotSuccess,
  fetchSpecificBotFailure,
  postPlaceOrderSuccess,
  postPlaceOrderFailure,
  postCloseOrderSuccess,
  postCloseOrderFailure, // Import the new action creators
} from "./actions"
import {
  getSpecificBotData,
  postPlaceOrder,
  postCloseOrder, // Import the function for closing orders
} from "../../helpers/backend_helper"
import { BotActionTypes } from "./actionTypes"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

/**
 * Worker saga: fetches specific bot data upon request action.
 * @param {object} action - The action object containing the payload with bot ID.
 */
function* fetchSpecificBotSaga(action) {
  try {
    // Call the API to fetch specific bot data
    const response = yield call(getSpecificBotData, action.payload)

    // Check the response for success status and structure
    if (
      response?.success &&
      Array.isArray(response.bots) &&
      response.bots.length > 0
    ) {
      // Assuming the bot data is in the first element of the 'bots' array
      const botData = response.bots[0]
      // Dispatch a success action with the new bot data
      yield put(fetchSpecificBotSuccess(botData))
    } else {
      // Handle no data, failed response, or unexpected structure
      yield put(fetchSpecificBotFailure("Failed to fetch specific bot data"))
    }
  } catch (error) {
    // Log the error
    console.error("Error in fetchSpecificBotSaga:", error)
    // Dispatch a failure action with the error message
    yield put(fetchSpecificBotFailure(error.message || "Unknown error"))
  }
}

/**
 * Worker saga: places an order upon request action.
 * @param {object} action - The action object containing order data.
 */
function* postPlaceOrderSaga(action) {
  try {
    // Call the function to place the order and pass the data
    const response = yield call(postPlaceOrder, action.payload)

    // Check the response for success status
    if (response?.success) {
      // Dispatch a success action with the order response
      yield put(postPlaceOrderSuccess(response))
      toast.success("Open Order Request Received", { autoClose: 2000 })
    } else {
      // Handle order failure or unexpected response
      yield put(postPlaceOrderFailure("Failed to place the order"))
      toast.error("Failed to place the order", { autoClose: 2000 })
    }
  } catch (error) {
    // Log the error
    console.error("Error in postPlaceOrderSaga:", error)
    // Dispatch a failure action with the error message
    yield put(postPlaceOrderFailure(error.message || "Unknown error"))
  }
}

/**
 * Worker saga: closes an order upon request action.
 * @param {object} action - The action object containing the order data to close.
 */
function* postCloseOrderSaga(action) {
  try {
    // Call the function to close the order and pass the data
    const response = yield call(postCloseOrder, action.payload)

    // Check the response for success status
    if (response?.success) {
      // Dispatch a success action with the close order response
      yield put(postCloseOrderSuccess(response))
      toast.success("Close Order Request Received", { autoClose: 2000 })
    } else {
      // Handle close order failure or unexpected response
      yield put(postCloseOrderFailure("Failed to close the order"))
      toast.error("Failed to close the order", { autoClose: 2000 })
    }
  } catch (error) {
    // Log the error
    // Dispatch a failure action with the error message
    yield put(postCloseOrderFailure(error.message || "Unknown error"))
  }
}

/**
 * Watches for FETCH_SPECIFIC_BOT_REQUEST, POST_PLACE_ORDER_REQUEST,
 * and POST_CLOSE_ORDER_REQUEST actions and calls the respective sagas.
 * If a request is already pending, the previous one is canceled, and the new one starts.
 */
function* botSpecificSaga() {
  yield takeLatest(
    BotActionTypes.FETCH_SPECIFIC_BOT_REQUEST,
    fetchSpecificBotSaga
  )
  yield takeLatest(BotActionTypes.POST_PLACE_ORDER_REQUEST, postPlaceOrderSaga)
  yield takeLatest(BotActionTypes.POST_CLOSE_ORDER_REQUEST, postCloseOrderSaga)
}

export default botSpecificSaga
