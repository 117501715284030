// actionTypes.js

// Grouping and commenting make it clear what each action type is for

// Bot creation action types
export const RESET_CREATE_BOT = "RESET_CREATE_BOT" // Action to reset the bot creation process
export const CREATE_BOT = "CREATE_BOT" // Action to initiate bot creation
export const CREATE_BOT_SUCCESS = "CREATE_BOT_SUCCESS"
export const CREATE_BOT_FAIL = "CREATE_BOT_FAIL"

// Bot fetching action types
export const FETCH_BOT = "FETCH_BOT" // Action to initiate bot fetching
export const FETCH_BOT_SUCCESS = "FETCH_BOT_SUCCESS" // Action for successful bot fetching
export const FETCH_BOT_FAIL = "FETCH_BOT_FAIL" // Action for failed bot fetching

// Bot deletion action types
export const DELETE_BOT = "DELETE_BOT" // Action to initiate bot deletion
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS" // Action for successful bot deletion
export const DELETE_BOT_FAIL = "DELETE_BOT_FAIL" // Action for failed bot deletion
export const RESET_DELETE_SUCCESS = "RESET_DELETE_SUCCESS"

// Bot fetching action types
export const FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST =
  "FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST" // Action to initiate fetching available exchange data
export const FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS =
  "FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS" // Action for successful fetching of available exchange data
export const FETCH_AVAILABLE_EXCHANGE_DATA_FAIL =
  "FETCH_AVAILABLE_EXCHANGE_DATA_FAIL" // Action for failure in fetching available exchange data
