// import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// // Login Redux States
// import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
// import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// //Include Both Helper File with needed methods
// import { postloginUser } from "../../../helpers/backend_helper";

// // function* loginUser({ payload: { user } }) {
// //   try {
// //     const response = yield call(postloginUser, {
// //       email: user.email,
// //       password: user.password,
// //     })
// localStorage.setItem("authUser", JSON.stringify(response))
// //     yield put(loginSuccess(response))
// //   } catch (error) {
// //     toast.error(errorMessage, { autoClose: 2000 })
// //     yield put(apiError(error))
// //   }
// // }

// function* loginUser({ payload: { user } }) {
//   try {
//     // Attempt to log in the user
//     const response = yield call(postloginUser, {
//       email: user.email,
//       password: user.password,
//     });

//     // Check if the login was successful based on the response's success property
//     if (response.success) {
//       // If login is successful, store the JWT token in localStorage
//       localStorage.setItem("authUser", JSON.stringify(response)); // Ensure you're saving what's necessary

//       // Dispatch a login success action with the response (or possibly just the token)
//       yield put(loginSuccess(response)); // Consider sending only necessary data
//     } else {
//       // If login is unsuccessful, show an error message and dispatch a failure action
//       const errorMessage = response.message || "Login failed";
//       toast.error(errorMessage, { autoClose: 2000 });
//     }
//   } catch (error) {
//     // Handle any errors from the API call, like network or server errors
//     const errorMessage = error.message || "An unexpected error occurred";
//     toast.error(errorMessage, { autoClose: 2000 });

//     // Dispatch an API error action with the error message
//     yield put(apiError(errorMessage));
//   }
// }

// function* logoutUser({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");
//     history("/login");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

// function* authSaga() {
//   yield takeEvery(LOGIN_USER, loginUser);
//   yield takeEvery(LOGOUT_USER, logoutUser);
// }

// export default authSaga;

import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postloginUser } from "../../../helpers/backend_helper";

// Helper function to parse JWT
function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Invalid JWT token", e);
    return null;
  }
}

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postloginUser, {
      email: user.email,
      password: user.password,
    });

    if (response.success) {
      // Decode the JWT
      localStorage.setItem("authUser", JSON.stringify(response));

      const decodedToken = parseJwt(response.jwt);
      if (decodedToken) {
        // Store the necessary information in localStorage
        localStorage.setItem(
          "authUserData",
          JSON.stringify({
            id: decodedToken.id,
            email: decodedToken.email,
            exp: decodedToken.exp,
            iat: decodedToken.iat,
          })
        );
        yield put(loginSuccess(response));
      } else {
        const errorMessage = "Failed to decode JWT token";
        toast.error(errorMessage, { autoClose: 2000 });
        yield put(apiError(errorMessage));
      }
    } else {
      const errorMessage = response.message || "Login failed";
      toast.error(errorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    const errorMessage = error.message || "An unexpected error occurred";
    toast.error(errorMessage, { autoClose: 2000 });
    yield put(apiError(errorMessage));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
