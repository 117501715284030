import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, Link, useLocation } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { setUserNewPassword } from "../../store/actions"

import profile from "../../assets/images/profile-img.png"

const ResetPasswordPage = () => {
  document.title = "Reset Password | Crodl"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const setPasswordState = useSelector(state => state.SetNewPassword)
  const setPasswordError = setPasswordState
    ? setPasswordState.setPasswordError
    : null
  const setPasswordSuccessMsg = setPasswordState
    ? setPasswordState.setPasswordSuccessMsg
    : null

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const uid = queryParams.get("uid")
  const token = queryParams.get("token")

  const validation = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter your new password"),
    }),
    onSubmit: values => {
      dispatch(setUserNewPassword({ uid, token, password: values.password }))
    },
  })

  // Redirect on successful password reset
  useEffect(() => {
    if (setPasswordSuccessMsg) {
      navigate("/login")
    }
  }, [setPasswordSuccessMsg, navigate])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-warning p-4">
                        <h5 className="text-warning">Reset Your Password</h5>
                        <p>Enter your new password below.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {setPasswordError && (
                      <Alert color="danger">{setPasswordError}</Alert>
                    )}
                    {setPasswordSuccessMsg && (
                      <Alert color="success">{setPasswordSuccessMsg}</Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          New Password
                        </Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter new password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            !!(
                              validation.touched.password &&
                              validation.errors.password
                            )
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password && (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          )}
                      </div>
                      <button className="btn btn-primary w-md" type="submit">
                        Reset Password
                      </button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordPage
