// Binance

// Constants for submitting Binance API data
export const RESET_SUBMIT_BINANCE_API_DATA = "RESET_SUBMIT_BINANCE_API_DATA"
export const SUBMIT_BINANCE_API_DATA = "SUBMIT_BINANCE_API_DATA"
export const SUBMIT_BINANCE_API_DATA_SUCCESS = "SUBMIT_BINANCE_API_DATA_SUCCESS"
export const SUBMIT_BINANCE_API_DATA_FAIL = "SUBMIT_BINANCE_API_DATA_FAIL"

// Constants for adding new Binance API data
export const ADD_NEW_BINANCE_API_DATA = "ADD_NEW_BINANCE_API_DATA"
export const ADD_BINANCE_API_DATA_SUCCESS = "ADD_BINANCE_API_DATA_SUCCESS"
export const ADD_BINANCE_API_DATA_FAIL = "ADD_BINANCE_API_DATA_FAIL"

// API DATAS
export const FETCH_API_DATA_BINANCE = "FETCH_API_DATA_BINANCE"
export const FETCH_API_DATA_SUCCESS_BINANCE = "FETCH_API_DATA_SUCCESS_BINANCE"
export const FETCH_API_DATA_FAIL_BINANCE = "FETCH_API_DATA_FAIL_BINANCE"

// DELETE API DATAS
export const DELETE_API_DATA_BINANCE = "DELETE_API_DATA_BINANCE"
export const DELETE_API_DATA_SUCCESS_BINANCE = "DELETE_API_DATA_SUCCESS_BINANCE"
export const DELETE_API_DATA_FAIL_BINANCE = "DELETE_API_DATA_FAIL_BINANCE"
