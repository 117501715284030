export const GET_NFTS = "GET_NFTS"
export const GET_NFTS_SUCCESS = "GET_NFTS_SUCCESS"
export const GET_NFTS_FAIL = "GET_NFTS_FAIL"
export const ADD_NEW_NFTS = "ADD_NEW_NFTS"
export const ADD_NFTS_SUCCESS = "ADD_NFTS_SUCCESS"
export const ADD_NFTS_FAIL = "ADD_NFTS_FAIL"
export const DELETE_NFTS = "DELETE_NFTS"
export const DELETE_NFTS_SUCCESS = "DELETE_NFTS_SUCCESS"
export const DELETE_NFTS_FAIL = "DELETE_NFTS_FAIL"

export const getNfts = () => ({
  type: GET_NFTS,
})

export const getNftsSuccess = nfts => ({
  type: GET_NFTS_SUCCESS,
  payload: nfts,
})

export const getNftsFail = error => ({
  type: GET_NFTS_FAIL,
  payload: error,
})

export const addNewNfts = nfts => ({
  type: ADD_NEW_NFTS,
  payload: nfts,
})

export const addNftsSuccess = nfts => ({
  type: ADD_NFTS_SUCCESS,
  payload: nfts,
})

export const addNftsFail = error => ({
  type: ADD_NFTS_FAIL,
  payload: error,
})

export const deleteNfts = nfts => ({
  type: DELETE_NFTS,
  payload: nfts,
})

export const deleteNftsSuccess = nfts => ({
  type: DELETE_NFTS_SUCCESS,
  payload: nfts,
})

export const deleteNftsFail = error => ({
  type: DELETE_NFTS_FAIL,
  payload: error,
})
