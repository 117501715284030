import {
  RESET_CREATE_BOT,
  CREATE_BOT,
  CREATE_BOT_SUCCESS,
  CREATE_BOT_FAIL,
  FETCH_BOT,
  FETCH_BOT_SUCCESS,
  FETCH_BOT_FAIL,
  DELETE_BOT,
  DELETE_BOT_SUCCESS,
  DELETE_BOT_FAIL,
  RESET_DELETE_SUCCESS,
  FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST,
  FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS,
  FETCH_AVAILABLE_EXCHANGE_DATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  botData: [],
  error: null,
  loading: false,
  success: false,
  deleteSuccess: false,
  availableExchanges: [],
}

const BotReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case RESET_CREATE_BOT:
      return { ...state, loading: false, error: null, success: false }

    case CREATE_BOT:
    case FETCH_BOT:
      return { ...state, loading: true, error: null, success: false }

    case CREATE_BOT_SUCCESS:
      if (
        !payload ||
        typeof payload !== "object" ||
        !payload.bot_id ||
        !payload.bot_name
      ) {
        console.error(
          "Invalid payload for CREATE_BOT_SUCCESS:",
          JSON.stringify(payload)
        )
        return { ...state, error: "Invalid bot data", loading: false }
      }
      return {
        ...state,
        botData: [...state.botData, payload],
        loading: false,
        success: true,
      }

    case FETCH_BOT_SUCCESS:
      return { ...state, botData: payload, loading: false, success: true }

    case CREATE_BOT_FAIL:
      return { ...state, error: payload, loading: false, success: false }

    case FETCH_BOT_FAIL:
      return { ...state, error: payload, loading: false, success: false }

    case DELETE_BOT:
      // Initiating the deletion process
      return { ...state, loading: true, error: null, success: false }

    // In your reducer, use a more specific success flag
    case DELETE_BOT_SUCCESS:
      const updatedBotData = state.botData.filter(
        bot => bot.fields.bot_id !== payload
      )
      return {
        ...state,
        botData: updatedBotData,
        loading: false,
        deleteSuccess: true, // Set the specific delete success flag
      }

    case RESET_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: false, // Reset the delete success flag
      }

    case DELETE_BOT_FAIL:
      // Handling deletion failure
      return { ...state, error: payload, loading: false, deleteSuccess: false }

    case FETCH_AVAILABLE_EXCHANGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      }

    case FETCH_AVAILABLE_EXCHANGE_DATA_SUCCESS:
      return {
        ...state,
        availableExchanges: payload, // Use payload directly
        loading: false,
        success: true,
      }

    case FETCH_AVAILABLE_EXCHANGE_DATA_FAIL:
      return {
        ...state,
        error: payload.error, // Use payload.error directly
        loading: false,
        success: false,
      }

    default:
      return state
  }
}

export default BotReducer
